import React from 'react'
import clsx from 'clsx'
import { Typography } from '@mui/material'

const CandidateInfo = (props) => {
  const { candidateInfo } = props
  return (
    <>
      <Typography
        className="w-full grid grid-cols-3 bg-[#FAFAFA] p-5 gap-5 border border-[#D4D4D8] rounded"
        component="div"
      >
        {candidateInfo
          ? candidateInfo.map((info) => {
              return (
                <>
                  <Typography className="w-full text-lg font-medium">
                    <span className="text-lg font-medium text-black">
                      {info.label + ': '}
                    </span>
                    <span
                      className={clsx('text-gray-500', {
                        '!text-primary-500': info.label == 'Total Marks',
                        '!text-red-500':
                          info.label == 'Result' && info.value == 'Fail',
                        '!text-success-500':
                          info.label == 'Result' && info.value == 'Pass'
                      })}
                    >
                      {info.value}
                    </span>
                  </Typography>
                </>
              )
            })
          : ''}
      </Typography>
    </>
  )
}

export default CandidateInfo
