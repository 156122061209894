import { Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { accessorKey, header } from '../../constants/columns'
import {
  useGetQuestionByFilterQuery,
  useLazyGetQuestionByFilterQuery
} from '../../app/services/question'
import { useGetDomainsQuery } from '../../app/services/domain'
import { useLazyGetTopicByDomainQuery } from '../../app/services/topic'
import FileUpload from '../../components/feature/FileUpload/FileUpload'
import FileDownload from '../../components/feature/FileDownload/FileDownload'
import CustomTable from '../../components/feature/Table/Table'
import Style from './Questyle.module.css'
import Select from '../../components/core/Select/Select'
import Chip from '../../components/core/Chip/Chip'
import Loader from '../../components/layout/Loader/Loader'
import PageNumber from '../../components/feature/PageNumber/PageNum'
import { pagination } from '../../constants/constants'
import Alert from '../../components/feature/AlertMessage/AlertMessage'

const columns = [
  {
    value: accessorKey.NO,
    label: header.NO,
    width: '5%',
    render: ({ row, rowIndex }) => (
      <>
        <Typography>{rowIndex + 1}</Typography>
      </>
    )
  },
  {
    value: accessorKey.QUESTION,
    label: header.QUESTION,
    render: ({ row }) => (
      <div className={Style.listing_tablecell}>
        <div dangerouslySetInnerHTML={{ __html: row.question }}></div>
        <div className={Style.chip__wrapper}>
          <Chip label={row.domain.name} color={'primary'} />
          <Chip label={row.topic.name} color={'warning'} />
        </div>
      </div>
    )
  },
  {
    value: accessorKey.COMPLEXITY,
    label: header.COMPLEXITY,
    width: '10%'
  }
]

const QuestionList = () => {
  const { data: domains } = useGetDomainsQuery()
  const [getTopicsByDomain] = useLazyGetTopicByDomainQuery()
  const { data: questionList, isFetching } = useGetQuestionByFilterQuery()
  const [getQuestionsByFilter] = useLazyGetQuestionByFilterQuery()
  const [questions, setQuestions] = useState([])
  const navigate = useNavigate()
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertSeverity, setAlertSeverity] = useState()

  const [topics, setTopics] = useState([])
  const [filterDepartment, setFilterDepartment] = useState()
  const [filterTopic, setFilterTopic] = useState()
  const [totalPages, setTotalPages] = useState(0)

  let defaultPageSize = pagination.DEFAULT_PAGE_SIZE

  useEffect(() => {
    if (questionList !== undefined) {
      setQuestions(questionList.data)
      setTotalPages(questionList.totalPages)
    }
  }, [questionList])

  const handleQuestionSearch = (body) => {
    getQuestionsByFilter(body).then(({ data: questionListByFilter }) => {
      if (questionListByFilter === undefined) setQuestions([])
      else {
        window.scrollTo({ top: 0 })
        setQuestions(questionListByFilter.data)
        setTotalPages(questionListByFilter.totalPages)
      }
    })
  }
  const handleDepartmentChange = ({ value }) => {
    setFilterDepartment(value)

    handleQuestionSearch({
      domain: value,
      page: 0,
      size: defaultPageSize
    })

    if (value !== '') {
      getTopicsByDomain({
        domainId: value
      }).then(({ data }) => {
        setTopics(data)
      })
      setFilterTopic('')
    } else {
      setTopics([])
    }
  }

  const handleTopicChange = ({ value }) => {
    setFilterTopic(value)
    handleQuestionSearch({
      domain: filterDepartment,
      topic: value,
      page: 0,
      size: defaultPageSize
    })
  }

  const onRowClick = (row) => {
    navigate(row.uid)
  }

  const handlePageChange = (body) => {
    let payload = { ...body, size: defaultPageSize }

    if (
      filterDepartment !== null &&
      filterDepartment !== undefined &&
      filterDepartment !== ''
    )
      payload = { ...payload, domain: filterDepartment }

    if (filterTopic !== null && filterTopic !== undefined && filterTopic !== '')
      payload = { ...payload, topic: filterTopic }

    handleQuestionSearch(payload)
  }

  let disappearMsgTimout
  const handleFileUploadProcess = (message, severity) => {
    setShowAlert(true)
    setAlertMessage(message)
    setAlertSeverity(severity)

    disappearMsgTimout = setTimeout(disappearMsgHandler, 4000)
  }

  const disappearMsgHandler = () => {
    setShowAlert(false)
    clearTimeout(disappearMsgTimout)
  }

  return (
    <div className="m-0 pr-4 w-full">
      <Grid item xs={12} md={12}>
        {showAlert && (
          <div className="flex items-center fixed">
            <Alert
              severity={alertSeverity}
              message={alertMessage}
              className="absolute top-[-23.5px] left-[340px] z-[10000]"
            />
          </div>
        )}
        <Typography className={Style.upload__title}>
          Import / Upload Question Bank
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <FileDownload />
      </Grid>
      <Grid item xs={12} md={12}>
        <FileUpload handleFileUploadProcess={handleFileUploadProcess} />
      </Grid>
      <Grid item xs={12} md={12} className={Style.qlist__wrapper}>
        <Typography className={Style.quelisting__title}>
          Question Listing
        </Typography>
        <div className={Style.qlist__select}>
          <div className="w-[198px]">
            <Select
              placeholder="Select Department"
              options={[
                { value: '', label: 'All' },
                ...(domains !== undefined && domains.length > 0
                  ? domains.map((domain) => ({
                      value: domain.uid,
                      label: domain.name
                    }))
                  : '')
              ]}
              value={filterDepartment}
              onChange={handleDepartmentChange}
            />
          </div>
          <div className="w-[198px]">
            <Select
              placeholder="Select Topic"
              options={[
                { value: '', label: 'All' },
                ...(topics !== undefined
                  ? topics.map((topic) => ({
                      value: topic.uid,
                      label: topic.name
                    }))
                  : [])
              ]}
              value={filterTopic}
              onChange={handleTopicChange}
            />
          </div>
        </div>
      </Grid>
      {isFetching ? (
        <Loader />
      ) : (
        <>
          <div className={Style.table__wrapper}>
            <CustomTable
              columns={columns}
              data={questions}
              onRowClick={onRowClick}
            />
          </div>
          <PageNumber count={totalPages} onChange={handlePageChange} />
        </>
      )}
    </div>
  )
}

export default QuestionList
