import { Typography } from '@mui/material'
import React from 'react'
import TextField from '../../core/TextField/TextField'

const EnterMarks = ({ onChange, totalMarks = 10, value }) => {
  return (
    <>
      <Typography className="flex rounded" component="div">
        <Typography
          className="w-[165px] h-[40px] rounded-r-none"
          component="div"
        >
          <TextField
            placeholder="Enter Your Mark"
            inputClassName="text-sm"
            type="number"
            className="h-10"
            onChange={onChange}
            maxValue={totalMarks}
            minValue={0}
            value={value}
          />
        </Typography>
        <Typography
          className="w-[94px] h-[40px] text-white rounded-r  bg-gray-500 flex items-center justify-center relative"
          component="div"
        >
          <span>{totalMarks}</span>
        </Typography>
      </Typography>
    </>
  )
}

export default EnterMarks
