import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useLazyGetExaminationQuestionByUidQuery,
  useLazyGetNavigationQuery
} from '../../../app/services/exam-question'
import { navigationActions } from '../../../app/store/navigation'
import { notificationActions } from '../../../app/store/notification'
import { questionActions } from '../../../app/store/question'
import sidebarLogo from '../../../assets/image/sidebar-logo.svg'
import Style from './questionpapermcq.module.css'

function QuestionPaperSidebar() {
  const dispatch = useDispatch()
  const navigationData = useSelector((state) => state.navigation.navigationData)
  const [getNavigation] = useLazyGetNavigationQuery()
  const [getQuestionByUid] = useLazyGetExaminationQuestionByUidQuery()
  const examinationUid = localStorage.getItem('examinationUid')
  const [statusCount, setSetstatusCount] = useState({
    skipped: 0,
    answered: 0,
    review: 0,
    not_visited: 0
  })

  const handleQuestionByUid = async (qUid, count) => {
    if (!qUid) return
    try {
      const response = await getQuestionByUid({
        id: qUid
      })
      dispatch(
        questionActions.addQuestion({
          questionData: { uid: qUid, ...response.data, question_label: count }
        })
      )
      dispatch(
        questionActions.setShowResumeButton({
          isShowResumeButton: true
        })
      )
    } catch (error) {
      dispatch(
        notificationActions.openSnackbar({
          type: 'error',
          message: error
        })
      )
    }
  }

  const getNavigationData = async () => {
    try {
      const response = await getNavigation({
        id: examinationUid
      })
      dispatch(
        navigationActions.addNavigationData({
          navigationData: response.data
        })
      )
    } catch (error) {
      dispatch(
        notificationActions.openSnackbar({
          type: 'error',
          message: error
        })
      )
    }
  }

  useEffect(() => {
    getNavigationData()
  }, [])

  useEffect(() => {
    if (!navigationData) return

    const questionStatus = {
      skipped: 0,
      answered: 0,
      review: 0,
      not_visited: 0
    }
    navigationData?.forEach((type) => {
      type.examinationTopics?.forEach((topic) => {
        for (let i = 0; i < topic.total; i++) {
          const q = topic.questions[i]
          if (q?.skipped) questionStatus.skipped++
          else if (q?.markedForReview) questionStatus.review++
          else if (q?.uid) questionStatus.answered++
          else questionStatus.not_visited++
        }
      })
    })

    setSetstatusCount(questionStatus)

    dispatch(
      questionActions.addQuestionStatus({
        questionStatus: questionStatus
      })
    )
  }, [navigationData])

  return (
    <>
      <div>
        <div className="w-[265px] h-[80px] flex justify-center bg-[#FAFAFA] items-center sticky top-0 z-[99]">
          <div>
            <img src={sidebarLogo} alt="logo" />
          </div>
        </div>

        {/* Part A - MCQ/Single choice and Part B - Descriptive */}
        <div className={Style.paper__sidebar}>
          {navigationData?.map((item, index) => {
            return (
              <div
                sx={{
                  width: '265px',
                  background: '#CBE9FE',
                  borderRadius: '0px !important',
                  margin: '0 !important'
                }}
                key={index}
                defaultExpanded
              >
                <div
                  className="px-6 py-4 bg-grey-100 border-t border-b border-grey-300 "
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    sx={{
                      color: '#52525B)',
                      fontSize: '14px',
                      fontWeight: '700'
                    }}
                  >
                    {item.type === 'NON_DESCRIPTIVE'
                      ? 'Part A - Single/Multiple choice'
                      : item.type === 'DESCRIPTIVE'
                      ? 'Part B - Descriptive'
                      : 'Questions'}
                  </Typography>
                </div>
                <div sx={{ padding: 0 }}>
                  {item.examinationTopics.map((topic, index) => {
                    return (
                      <div
                        sx={{
                          background: '#E0F5FE',
                          borderRadius: '0px !important',
                          marginBottom: '0 !important',
                          marginTop: '0 !important'
                        }}
                        key={index}
                        defaultExpanded
                      >
                        <div sx={{ overflow: 'hidden', padding: '0' }}>
                          <div className="w-full">
                            <Typography className="text-grey-600 text-sm font-medium bg-grey-100 px-6 py-4">
                              {topic.name}
                            </Typography>
                            <Typography
                              component="div"
                              sx={{
                                display: 'flex',
                                bgcolor: 'white',
                                padding: '24px 12px',
                                justifyContent: 'flex-start',
                                flexWrap: 'wrap'
                              }}
                            >
                              {Array.from({ length: topic.total }, (_, i) => (
                                <div
                                  className={clsx(
                                    'h-[42px] w-[42px] cursor-pointer rounded-full flex items-center justify-center m-2 ',
                                    {
                                      'bg-[#F0FDF4] text-[#22C55E]':
                                        topic.questions[i],
                                      'bg-[#FAFAFA] text-[#71717A]':
                                        !topic.questions[i],
                                      'bg-[#FEF2F2] !text-[#EF4444]':
                                        topic.questions[i]?.skipped,
                                      '!bg-[#EDE7F6] !text-[#673AB7]':
                                        topic.questions[i]?.markedForReview
                                    }
                                  )}
                                  key={i}
                                  onClick={() =>
                                    handleQuestionByUid(
                                      topic.questions[i]?.uid,
                                      i + 1
                                    )
                                  }
                                >
                                  {i + 1}
                                </div>
                              ))}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>

        {/* Instructions section */}
        <div className="fixed bottom-0 z-50 bg-white">
          <Box
            sx={{
              display: 'inline-flex',
              width: '265px',
              bgcolor: '#F4F4F5',
              color: '#52525B',
              fontSize: '14px',
              fontWeight: '700',
              padding: '16px 24px',
              borderTop: '1px solid #D4D4D8',
              borderBottom: '1px solid #D4D4D8'
            }}
          >
            Instructions
          </Box>
          <div className="pl-4 pt-6 pr-4 font-medium pb-6">
            <div className="flex items-center mb-4">
              <div className="h-[42px] w-[42px] mr-4 flex justify-center items-center bg-[#F0FDF4] text-[#22C55E] rounded-full">
                {statusCount.answered}
              </div>
              <span className="text-[#18181B]">Answered(Save & Next)</span>
            </div>
            <div className="flex items-center mb-4">
              <div className="h-[42px] w-[42px] items-center mr-4 flex justify-center bg-danger-50 text-danger-500 rounded-full">
                {statusCount.skipped}
              </div>
              <span className="text-[#18181B]">Skipped(Skip)</span>
            </div>
            <div className="flex items-center mb-4">
              <div className="h-[42px] w-[42px] items-center mr-4 flex justify-center bg-extra-50 text-extra-500 rounded-full">
                {statusCount.review}
              </div>
              <span className="text-[#18181B]">Marked For Review</span>
            </div>
            <div className="flex items-center">
              <div className="h-[42px] w-[42px] items-center mr-4 flex justify-center bg-[#FAFAFA] text-grey-500 rounded-full">
                {statusCount.not_visited}
              </div>
              <span className="text-[#18181B]">Not Visited</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuestionPaperSidebar
