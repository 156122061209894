import { api } from './api'

// const resultEndpoint = 'result'

const examResultApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPageCheckerResultByFilter: build.query({
      query: (params) => {
        return {
          url: `examination/descriptive`,
          method: 'GET',
          params: params
        }
      },
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.error,
      providesTags: ['PaperChecker']
    }),
    getExaminationDiscriptiveQuestion: build.query({
      query: ({ examinationUid }) =>
        `examination/descriptive/question?examinationUid=${examinationUid}`,
      transformResponse: (response) => response.data,
      providesTags: ['PaperChecker']
    }),
    setExaminationDiscriptiveQuestionMarks: build.mutation({
      query: (body) => ({
        url: `examination/question/descriptive`,
        method: 'PUT',
        body
      }),
      providesTags: ['PaperChecker']
    }),
    getPageCheckerExaminationDetails: build.query({
      query: ({ examinationUid }) => `examination/details/${examinationUid}`,
      transformResponse: (response) => response.data,
      providesTags: ['PaperChecker']
    })
  })
})

export const {
  useGetPageCheckerResultByFilterQuery,
  useLazyGetPageCheckerResultByFilterQuery,
  useLazyGetExaminationDiscriptiveQuestionQuery,
  useSetExaminationDiscriptiveQuestionMarksMutation,
  useGetPageCheckerExaminationDetailsQuery
} = examResultApi
