import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    validateStatus: (response, result) => {
      if (response.status === 401) {
        localStorage.removeItem('accessToken')
      }
      return response.status === 200 && result.resultCode === 1001
    },
    prepareHeaders: (headers) => {
      // add token to authentication header
      const token = localStorage.getItem('accessToken')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    }
  }),
  tagTypes: [
    'Domain',
    'Topic',
    'Question',
    'Exam',
    'Exam-Question',
    'Result',
    'Exam-Summary',
    'Question-Summary',
    'SuccessRatio-Summery',
    'Month-Wise-Data',
    'Exam-concluded',
    'Answer-Sheet',
    'view-answer',
    'Exam-Complexity',
    'Exam-Codes',
    'Export-Result',
    'Exam-Names'
  ],

  endpoints: () => ({})
})
