import * as React from 'react'
import Pagination from '@mui/material/Pagination'
import PaginationItem from '@mui/material/PaginationItem'
import Stack from '@mui/material/Stack'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Style from './pagenum.module.css'

export default function PageNumber(props) {
  const { count, onChange } = props

  return (
    <Stack spacing={2}>
      <div className={Style.pagenum}>
        <Pagination
          count={count}
          onChange={(event, page) => {
            onChange({ page: page - 1 })
          }}
          renderItem={(item) => (
            <PaginationItem
              slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              {...item}
            />
          )}
        />
      </div>
    </Stack>
  )
}
