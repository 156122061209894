import { createSlice } from '@reduxjs/toolkit'

const initialNavigationState = {
  navigationData: null
}

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: initialNavigationState,
  reducers: {
    addNavigationData: (state, action) => ({
      ...initialNavigationState,
      ...action.payload
    })
  }
})

export const navigationActions = navigationSlice.actions

export default navigationSlice
