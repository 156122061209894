import React from 'react'
import { Typography } from '@mui/material'
import ViewAnwser from '../../components/core/ViewAnswer'
import CandidateInfo from '../../components/core/CandidateInfo/Candidateinfo'
import { useGetViewAnswerQuery } from '../../app/services/exam-result'
import Loader from '../../components/layout/Loader/Loader'
import { useParams } from 'react-router-dom'
import { useGetExaminationDetailsQuery } from '../../app/services/exam-question'
import parse from 'html-react-parser';

const ViewAnswer = () => {
  const { examinationUid, questionUid } = useParams()

  const { data: examinationDetails } = useGetExaminationDetailsQuery({
    id: examinationUid
  })
  const { data: questionData, isFetching } = useGetViewAnswerQuery({
    id: questionUid
  })

  const initialInfo = [
    {
      label: 'Name',
      value: examinationDetails?.examineeName
    },
    {
      label: 'Department',
      value: examinationDetails?.department
    },
    {
      label: 'Email Id',
      value: examinationDetails?.email
    },
    {
      label: 'Exam Date',
      value: formatDate(examinationDetails?.startTime)
    },
    {
      label: 'Exam Code',
      value: examinationDetails?.examCode
    },
    {
      label: 'Result',
      value: examinationDetails?.result
    },
    {
      label: 'MCQ & SCQ Marks',
      value:
        examinationDetails?.obtainedNonDescriptiveMarks +
        '/' +
        examinationDetails?.totalNonDescriptiveMarks
    },
    {
      label: 'Descriptive Marks',
      value:
        examinationDetails?.obtainedDescriptiveMarks +
        '/' +
        examinationDetails?.totalDescriptiveMarks
    },
    {
      label: 'Total Marks',
      value: examinationDetails?.obtainMarks + '/' + examinationDetails?.marks
    }
  ]

  function formatDate(inputDate) {
    if (!inputDate) {
      return ''
    }
    const date = new Date(inputDate)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${month}/${day}/${year}`
  }

  return (
    <>
      {isFetching ? (
        <Loader />
      ) : (
        <>
          <Typography component="div">
            <CandidateInfo candidateInfo={initialInfo} />
          </Typography>
          <Typography component="div" className="mt-6">
            <Typography component="div">
              <ViewAnwser
                height="500px"
                text={questionData !== undefined ? parse(questionData.answer) : ''}
              />
            </Typography>
          </Typography>
        </>
      )}
    </>
  )
}

export default ViewAnswer
