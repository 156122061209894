import { Button, Typography } from '@mui/material'

const QuestionGrid = ({ totalQuestions, remainingQuestions }) => {
  const maxElementsPerArray = 13

  const questions = Array.from(
    { length: Math.ceil(totalQuestions / maxElementsPerArray) },
    (_, index) => {
      const startIndex = index * maxElementsPerArray
      const endIndex = Math.min(
        startIndex + maxElementsPerArray,
        totalQuestions
      )

      return Array.from({ length: endIndex - startIndex }, (_, i) => ({
        label: startIndex + i + 1,
        isSelected:
          startIndex + i + 1 < totalQuestions - remainingQuestions
            ? true
            : false
      }))
    }
  )

  const selectButton = (que) => {
    questions.map((questionRow) =>
      questionRow.map((q) => {
        if (q.label === que.label) {
          q.isSelected = !q.isSelected
        }
        return q
      })
    )
  }

  return (
    <>
      <Typography
        component="div"
        sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
        className="divide-y divide-gray-300 border border-grey-300 rounded bg-gray-50 "
      >
        {questions.map((question, ind) => {
          return (
            <Typography
              component="div"
              sx={{ display: 'flex', width: '100%' }}
              key={ind}
            >
              {question.map((qtn, qtnInd) => {
                return (
                  <Button
                    onClick={() => selectButton(qtn)}
                    variant="text"
                    sx={{
                      width: '100%',
                      color: 'black',
                      fontWeight: '600',
                      bgcolor: qtn.isSelected && '#DCFCE7',
                      border: qtn.isSelected && '1px solid #22C55E'
                    }}
                    key={qtnInd}
                  >
                    {qtn.label}
                  </Button>
                )
              })}
            </Typography>
          )
        })}
      </Typography>
    </>
  )
}

export default QuestionGrid
