import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import CopyAllIcon from '@mui/icons-material/CopyAll'

const ViewAnwser = ({ text }) => {
  const text_div = document.createElement('div')
  text_div.innerHTML = text
  const textWithoutHTML = text_div.innerText

  return (
    <>
      <Typography component="div" className="border border-grey-300  rounded">
        <Typography
          component="div"
          className="flex justify-between items-center h-14 pl-5 pr-2 border-b bg-grey-100 rounded-t-lg"
        >
          <Typography
            component="h5"
            className="text-lg leading-[56px] font-semibold"
          >
            Candidate Answer
          </Typography>
          <Button
            className="px-3 bg-transparent shadow-none text-black text-[18px] font-semibold"
            onClick={() => navigator.clipboard.writeText(textWithoutHTML)}
            startIcon={<CopyAllIcon />}
          >
            Copy Code
          </Button>
        </Typography>
        <Typography
          component="p"
          className="flex flex-col p-5 h-[300px] overflow-y-auto"
        >
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </Typography>
      </Typography>
    </>
  )
}
export default ViewAnwser
