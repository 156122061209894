import styled from '@emotion/styled'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import KeyboardTabOutlinedIcon from '@mui/icons-material/KeyboardTabOutlined'
import LaptopMacIcon from '@mui/icons-material/LaptopMac'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import PaperCheckerLogo from '../../../assets/icon/Main (1).svg'
import PaperCheckerLogoSelected from '../../../assets/icon/Main.svg'
import resultImageSelected from '../../../assets/icon/Result_bw.svg'
import resultImage from '../../../assets/icon/Result_gray.svg'
import BrandLogo from '../../../assets/icon/branding-icon.svg'
import BrandText from '../../../assets/icon/branding-text.svg'
import collapseImage from '../../../assets/image/collapse.svg'
import dashboardImageSelected from '../../../assets/image/dashboard-selected.svg'
import dashboardImage from '../../../assets/image/dashboard.svg'
import sidebarLogo from '../../../assets/image/responsive-logo.svg'
import sidebarImage from '../../../assets/image/sidebar-logo.svg'
import topicImage from '../../../assets/image/topic.svg'
import topicSelectedImage from '../../../assets/image/topic_selected.svg'
import './sidebar.module.css'
import styles from './sidebar.module.css'
import { useState } from 'react'

const drawerWidth = 226

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(0)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
    paddingLeft: '7px',
    paddingRight: '7px',
    marginLeft: '0px',
    marignRight: '0px'
  }
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

function Sidebar(props) {
  const navigate = useNavigate()
  const { open, toggleDrawer } = props
  const location = useLocation()
  const [activeId, handleSelect] = useState(
    location.pathname === '/'
      ? 'dashboard'
      : location.pathname.split('/').filter((item) => item !== '')[0]
  )

  const handleLogout = () => {
    localStorage.removeItem('accessToken')
    navigate('/login', { replace: true })
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant="permanent"
        open={open}
        className={open ? styles.drawer_open : styles.drawer_closed}
        sx={{ marginBottom: '15px' }}
      >
        <div className={styles.sidebar__logo}>
          {open ? (
            <img
              className={styles.logo__full}
              src={sidebarImage}
              alt="logo"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigate('/', { replace: true })
                handleSelect('dashboard')
              }}
            />
          ) : (
            <img
              src={sidebarLogo}
              className="!py-6"
              alt="logo"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigate('/', { replace: true })
                handleSelect('dashboard')
              }}
            />
          )}
        </div>
        {open ? <Divider sx={{ position: 'sticky', top: '76px' }} /> : ''}
        <div className={styles.sidebar__wrapper}>
          <List sx={{ width: '100%' }}>
            <ListItem
              key={'dashboard'}
              disablePadding
              sx={{ marginTop: '16px' }}
              component={RouterLink}
              to="/"
            >
              <ListItemButton
                aria-label="open drawer"
                sx={{
                  borderRadius: '8px',
                  minHeight: 48,
                  px: 1.5,
                  py: 1.5,
                  marginBottom: '8px',
                  justifyContent: open ? 'start' : 'left',
                  '&:hover': {
                    borderRadius: '8px',
                    background: '#E4E4E7'
                  }
                }}
                className={
                  location.pathname === '/' ? styles.active_button : ''
                }
                onClick={() => handleSelect('dashboard')}
                selected={location.pathname === '/'}
              >
                <img
                  src={
                    location.pathname === '/'
                      ? dashboardImageSelected
                      : dashboardImage
                  }
                  alt="dashboard-icon"
                />

                <Typography
                  className={
                    (styles.sidebar__text,
                    location.pathname === '/' ? styles.active_button : '')
                  }
                  sx={{
                    opacity: open ? 1 : 0,
                    fontFamily: 'Figtree',
                    color: '#71717A',
                    paddingLeft: '12px'
                  }}
                >
                  Dashboard
                </Typography>
              </ListItemButton>
            </ListItem>

            <ListItem
              key={'exam'}
              disablePadding
              sx={{ mb: 1 }}
              component={RouterLink}
              to="/exam"
            >
              <ListItemButton
                aria-label="open drawer"
                sx={{
                  borderRadius: '8px',
                  minHeight: 48,
                  px: 1.5,
                  py: 1.5,
                  justifyContent: open ? 'initial' : 'start',
                  '&:hover': {
                    background: '#E4E4E7',
                    borderRadius: '8px'
                  }
                }}
                className={
                  location.pathname === '/exam' ? styles.active_button : ''
                }
                onClick={() => handleSelect('exam')}
                selected={location.pathname === '/exam'}
              >
                <ArticleOutlinedIcon
                  style={{
                    fill: location.pathname === '/exam' ? '#FFF' : '#71717A'
                  }}
                />

                <Typography
                  className={
                    location.pathname === '/exam'
                      ? styles.active_button
                      : styles.sidebar__text
                  }
                  sx={{
                    opacity: open ? 1 : 0,
                    paddingLeft: '12px',
                    fontFamily: 'Figtree'
                  }}
                >
                  Exam
                </Typography>
              </ListItemButton>
            </ListItem>
            <ListItem
              key={'question'}
              disablePadding
              sx={{ mb: 1 }}
              component={RouterLink}
              to="/question"
            >
              <ListItemButton
                aria-label="open drawer"
                sx={{
                  borderRadius: '8px',
                  minHeight: 48,
                  px: 1.5,
                  py: 1.5,
                  justifyContent: open ? 'initial' : 'start',
                  '&:hover': {
                    background: '#E4E4E7',
                    borderRadius: '8px'
                  }
                }}
                className={
                  location.pathname === '/question' ? styles.active_button : ''
                }
                onClick={() => handleSelect('question')}
                selected={location.pathname === '/question'}
              >
                <QuizOutlinedIcon
                  style={{
                    fill: location.pathname === '/question' ? '#fff' : '#71717A'
                  }}
                />

                <Typography
                  className={
                    location.pathname === '/question'
                      ? styles.active_button
                      : styles.sidebar__text
                  }
                  sx={{
                    opacity: open ? 1 : 0,
                    paddingLeft: '12px',
                    fontFamily: 'Figtree'
                  }}
                >
                  Question
                </Typography>
              </ListItemButton>
            </ListItem>

            <ListItem
              key={'result'}
              disablePadding
              sx={{ mb: 1 }}
              component={RouterLink}
              to="/result"
            >
              <ListItemButton
                aria-label="open drawer"
                sx={{
                  borderRadius: '8px',
                  minHeight: 48,
                  px: 1.5,
                  py: 1.5,
                  justifyContent: open ? 'initial' : 'start',
                  '&:hover': {
                    background: '#E4E4E7',
                    borderRadius: '8px'
                  }
                }}
                className={
                  location.pathname === '/result' ? styles.active_button : ''
                }
                onClick={() => handleSelect('result')}
                selected={location.pathname === '/result'}
              >
                <img
                  src={
                    location.pathname === '/result'
                      ? resultImageSelected
                      : resultImage
                  }
                  alt="result-icon"
                />

                <Typography
                  className={
                    location.pathname === '/result'
                      ? styles.active_button
                      : styles.sidebar__text
                  }
                  sx={{
                    opacity: open ? 1 : 0,
                    paddingLeft: '12px',
                    fontFamily: 'Figtree'
                  }}
                >
                  Result
                </Typography>
              </ListItemButton>
            </ListItem>
            <ListItem
              key={'paper-checker'}
              disablePadding
              sx={{ mb: 1 }}
              component={RouterLink}
              to="/paper-checker"
            >
              <ListItemButton
                aria-label="open drawer"
                sx={{
                  minHeight: 48,
                  px: 1.5,
                  py: 1.5,
                  justifyContent: open ? 'initial' : 'start',
                  '&:hover': {
                    background: '#E4E4E7',
                    borderRadius: '8px'
                  }
                }}
                className={
                  location.pathname === '/paper-checker'
                    ? styles.active_button
                    : ''
                }
                onClick={() => handleSelect('paper-checker')}
                selected={location.pathname === '/paper-checker'}
              >
                <img
                  src={
                    location.pathname === '/paper-checker'
                      ? PaperCheckerLogoSelected
                      : PaperCheckerLogo
                  }
                  alt="paper-checker-icon"
                />

                <Typography
                  className={
                    location.pathname === '/paper-checker'
                      ? styles.active_button
                      : styles.sidebar__text
                  }
                  sx={{
                    opacity: open ? 1 : 0,
                    paddingLeft: '12px',
                    fontFamily: 'Figtree'
                  }}
                >
                  Paper Checker
                </Typography>
              </ListItemButton>
            </ListItem>
            <ListItem>
              {open ? (
                <Typography
                  className={styles.sidebar__text}
                  sx={{
                    fontFamily: 'Figtree'
                  }}
                >
                  Settings
                </Typography>
              ) : (
                ''
              )}
            </ListItem>
            <Divider />
            <ListItem
              key={'Department'}
              disablePadding
              sx={{ mt: 2, mb: 1 }}
              component={RouterLink}
              to="/department"
            >
              <ListItemButton
                aria-label="open drawer"
                sx={{
                  borderRadius: '8px',
                  minHeight: 48,
                  color: '#71717A',
                  px: 1.5,
                  py: 1.5,
                  justifyContent: open ? 'initial' : 'start',

                  '&:hover': {
                    borderRadius: '8px',
                    background: '#E4E4E7'
                  }
                }}
                className={
                  location.pathname === '/department'
                    ? styles.active_button
                    : ''
                }
                onClick={() => handleSelect('department')}
                selected={location.pathname === '/department'}
              >
                <LaptopMacIcon
                  style={{
                    fill:
                      location.pathname === '/department' ? '#fff' : '#71717A'
                  }}
                />

                <Typography
                  className={
                    (styles.sidebar__text,
                    location.pathname === '/department'
                      ? styles.active_button
                      : '')
                  }
                  sx={{
                    opacity: open ? 1 : 0,
                    fontFamily: 'Figtree',
                    paddingLeft: '12px'
                  }}
                >
                  Department
                </Typography>
              </ListItemButton>
            </ListItem>
            <ListItem
              key={'topic'}
              disablePadding
              component={RouterLink}
              to="topic"
            >
              <ListItemButton
                aria-label="open drawer"
                sx={{
                  borderRadius: '8px',
                  minHeight: 48,
                  color: '#71717A',
                  px: 1.5,
                  py: 1.5,
                  marginBottom: '8px',
                  justifyContent: open ? 'initial' : 'start',
                  '&:hover': {
                    borderRadius: '8px',
                    background: '#E4E4E7'
                  }
                }}
                className={
                  location.pathname === '/topic' ? styles.active_button : ''
                }
                onClick={() => handleSelect('topic')}
                selected={location.pathname === '/topic'}
              >
                <img
                  src={
                    location.pathname === '/topic'
                      ? topicSelectedImage
                      : topicImage
                  }
                  alt="topic-icon"
                />
                <Typography
                  className={
                    (styles.sidebar__text,
                    location.pathname === '/topic' ? styles.active_button : '')
                  }
                  sx={{
                    opacity: open ? 1 : 0,
                    paddingLeft: '12px',
                    fontFamily: 'Figtree'
                  }}
                >
                  Topic
                </Typography>
              </ListItemButton>
            </ListItem>
          </List>

          <List sx={{ width: '100%', marginBottom: '26px' }}>
            {/* <ListItem disablePadding component={RouterLink} to="login"> */}
            <ListItemButton
              aria-label="open drawer"
              sx={{
                borderRadius: '8px',
                minHeight: 48,
                color: '#71717A',
                px: 1.5,
                py: 1.5,
                marginBottom: '8px',
                justifyContent: open ? 'initial' : 'start',
                '&:hover': {
                  borderRadius: '8px',
                  background: '#E4E4E7'
                }
              }}
              className={
                location.pathname === '/logout' ? styles.active_button : ''
              }
              onClick={() => handleLogout()}
              selected={location.pathname === '/logout'}
            >
              <LogoutOutlinedIcon style={{ fill: '#71717A' }} />

              <Typography
                className={
                  (styles.sidebar__text,
                  location.pathname === '/logout' ? styles.active_button : '')
                }
                sx={{
                  opacity: open ? 1 : 0,
                  paddingLeft: '12px',
                  fontFamily: 'Figtree'
                }}
              >
                Logout
              </Typography>
            </ListItemButton>
            {/* </ListItem> */}
            <ListItem disablePadding>
              <ListItemButton
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  borderRadius: '8px',
                  minHeight: 48,
                  color: '#71717A',
                  px: 1.5,
                  py: 1.5,
                  marginBottom: '26px',
                  justifyContent: open ? 'initial' : 'start',
                  '&:hover': {
                    borderRadius: '8px',
                    background: '#E4E4E7'
                  }
                }}
              >
                {open ? (
                  <img
                    src={collapseImage}
                    alt="collapse-icon"
                    style={{ fill: '#71717A' }}
                  />
                ) : (
                  <KeyboardTabOutlinedIcon style={{ fill: '#71717A' }} />
                )}
                <Typography
                  className={styles.sidebar__text}
                  sx={{
                    opacity: open ? 1 : 0,
                    fontFamily: 'Figtree',
                    paddingLeft: '12px'
                  }}
                >
                  Collapse
                </Typography>
              </ListItemButton>
            </ListItem>
          </List>
        </div>
        <Typography
          component="div"
          sx={{
            display: open ? '' : 'none',
            position: 'fixed',
            bottom: '0',
            width: '225.75px',
            zIndex: '10'
          }}
        >
          <div className="flex justify-center bg-grey-600 gap-4 items-center bottom-0 py-2">
            <span className="text-white text-[12px]">Powered By</span>
            <div className="flex">
              <img src={BrandLogo} alt="7" className="h-[17px]"></img>
              <img
                src={BrandText}
                alt="7Span"
                className="h-[11.5px] relative bottom-[-5px]"
              ></img>
            </div>
          </div>
        </Typography>
        <Typography
          component="div"
          sx={{
            display: open ? 'none' : '',
            position: 'fixed',
            bottom: '0',
            zIndex: '10',
            width: '64.5px',
            left: '0'
          }}
        >
          <div className="flex justify-center h-[55px] bg-grey-600 gap-4 items-center bottom-0 py-2">
            <div className="flex">
              <img src={BrandLogo} alt="7" className="h-[30px]"></img>
            </div>
          </div>
        </Typography>
      </Drawer>
    </Box>
  )
}

export default Sidebar
