import SearchIcon from '@mui/icons-material/Search'
import { InputAdornment, Typography } from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput'
import clsx from 'clsx'

const TextField = (props) => {
  const {
    id = 'custom-input',
    label,
    placeholder,
    size = 'small',
    searchIcon = false,
    onChange = () => {},
    inputClassName,
    className,
    value,
    type = 'text',
    maxValue,
    disabled = false,
    minValue = 0,
    validate = {},
    isError = false,
    onKeyUp
  } = props

  return (
    <>
      <Typography
        component="div"
        sx={{ display: 'flex', flexDirection: 'column' }}
        className={className}
      >
        {label && <Typography component="label">{label}</Typography>}
        <OutlinedInput
          id={id}
          variant="outlined"
          size={size}
          type={type}
          sx={{ padding: '0 !important' }}
          placeholder={placeholder}
          disabled={disabled}
          onKeyUp={onKeyUp}
          onChange={(e) => {
            let value = e.target.value
            if (type === 'number') {
              if (value) {
                if (maxValue && +value > maxValue) {
                  value = maxValue
                }
                if (+value < minValue) {
                  value = minValue
                }
              } else {
                value = null
              }
            }
            onChange(value)
          }}
          inputProps={{ min: minValue }}
          defaultValue={value}
          value={value}
          className={clsx('h-full', inputClassName)}
          endAdornment={
            searchIcon ? (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ) : (
              <></>
            )
          }
          error={isError}
          {...validate}
        />
      </Typography>
    </>
  )
}

export default TextField
