import { useSelector, useDispatch } from 'react-redux'
import { Alert, Snackbar, Slide } from '@mui/material'
import { notificationActions } from '../../app/store/notification'

const TransitionTop = (props) => {
  return <Slide {...props} direction="down" />
}

const Notification = () => {
  const dispatch = useDispatch()
  const notification = useSelector((state) => state.notification)

  const handleClose = () => {
    dispatch(notificationActions.closeSnackbar())
  }

  return (
    <Snackbar
      className="top-20 right-4"
      anchorOrigin={notification.anchor}
      open={notification.open}
      autoHideDuration={notification.timeout}
      TransitionComponent={TransitionTop}
      onClose={handleClose}
    >
      <Alert
        elevation={6}
        variant="filled"
        severity={notification.type}
        onClose={handleClose}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  )
}

export default Notification
