import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { FormControlLabel, Radio, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  useLazyGetQuestoinQuery,
  useSubmitAnswerMutation,
  useSubmitExamByExaminationUidMutation
} from '../../../app/services/exam-question'
import { navigationActions } from '../../../app/store/navigation'
import { notificationActions } from '../../../app/store/notification'
import { questionActions } from '../../../app/store/question'
import Button from '../../core/Button/Button'
import Tiptap from '../../core/TipTapTextEditor/TipTap'
import Loader from '../../layout/Loader/Loader'
import Modal from '../../layout/Modal/Modal'
import ProgressBar from '../ProgressBar/ProgressBar'
import Style from './questionpapermcq.module.css'

let type = null
let typeIndex = null
let topic = null
let topicIndex = null
let wakeLock = null
let keyboardLock = false

function QuestionPaperMCQ(props) {
  const { examinationDetails, isExaminationFetching, examTimeLeft } = props

  const examinationUid = localStorage.getItem('examinationUid')
  const navigationData = useSelector((state) => state.navigation.navigationData)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const question = useSelector((state) => state.question.questionData)
  const lastQuestionData = useSelector(
    (state) => state.question.lastQuestionData
  )
  const isShowResumeButton = useSelector(
    (state) => state.question.isShowResumeButton
  )
  const questionStatus = useSelector((state) => state.question.questionStatus)
  const isRemainingQuestion = useSelector(
    (state) => state.question.isRemainingQuestion
  )

  const [getQuestionData] = useLazyGetQuestoinQuery()
  const [submitAnswer] = useSubmitAnswerMutation()
  const [submitExam] = useSubmitExamByExaminationUidMutation()

  const [multiChoiceChecked, setMultiChoiceChecked] = useState([])
  const [singleChoiceChecked, setSingleChoiceChecked] = useState('')
  const [questionCount, setQuestionCount] = useState(1)
  const [descriptiveAns, setDescriptiveAns] = useState('')
  const [questionUid, setQuestionUid] = useState('')
  const [isSkipButtonLoading, setIsSkipButtonLoading] = useState(false)
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const [isMarkLoading, setIsMarkLoading] = useState(false)
  const [showSubmitWarningModal, setShowSubmitWarningModal] = useState(false)
  const [lastQuestionuid, setLastQuestionuid] = useState(null)

  const answeredPercentage =
    (questionStatus?.answered / examinationDetails?.questions) * 100 || 0
  let isSkip = false
  let markForReview = false

  const getNewTopicAndType = () => {
    if (!navigationData) return false
    if (typeIndex && topicIndex && type && topicIndex) {
      let cur_topic = navigationData[typeIndex]?.examinationTopics[topicIndex]
      if (cur_topic.questions.length !== cur_topic.total) return true
    }

    typeIndex = navigationData.findIndex((type) => {
      topicIndex = type.examinationTopics?.findIndex(
        (t) => t.questions.length !== t.total
      )
      return topicIndex !== -1
    })

    type = navigationData[typeIndex]
    topic = type.examinationTopics[topicIndex]

    return !(typeIndex === -1 || topicIndex === -1)
  }

  const getQuestion = async () => {
    if (isShowResumeButton) return
    if (!getNewTopicAndType()) return

    try {
      const params = {
        examCode: localStorage.getItem('examCode'),
        examinationUid: examinationUid,
        topicId: topic.uid,
        questionType: type.type
      }
      const response = await getQuestionData({ params })
      const question_label =
        navigationData[typeIndex]?.examinationTopics[topicIndex]?.questions
          .length + 1
      dispatch(
        questionActions.addQuestion({
          questionData: { ...response.data, question_label }
        })
      )
      dispatch(
        questionActions.addLastQuestionData({
          lastQuestionData: { ...response.data, question_label }
        })
      )
      if (response.data === null) {
        dispatch(
          notificationActions.openSnackbar({
            type: 'error',
            message: 'No data found for Exam Question'
          })
        )
      }
    } catch (error) {
      dispatch(
        notificationActions.openSnackbar({
          type: 'error',
          message: error
        })
      )
    }
  }

  useEffect(() => {
    question?.remainingQuestion !== 0 && getQuestion()
  }, [navigationData])

  useEffect(() => {
    if (question?.type === 'DESCRIPTIVE') {
      setDescriptiveAns(question?.questionOptions[0]?.option ?? '')
      setQuestionUid(question?.questionUid ?? '')
    } else {
      setSingleChoiceChecked(
        question?.questionUid
          ? question?.questionOptions?.find((item) => item.optionSelected)
            ?.option ?? ''
          : ''
      )
      setMultiChoiceChecked(
        question?.questionOptions?.reduce((item, o) => {
          if (o.optionSelected) item.push(o.option)
          return item
        }, [])
      )
    }
  }, [question])

  useEffect(() => {
    function preventExitFullScreen() {
      setShowSubmitWarningModal(!document.fullscreenElement)
    }
    function blockRightClick(e) {
      if (e.button === 2) {
        e.preventDefault()
      }
    }

    function handleVisibilityChange() {
      if (document.hidden) {
        setTimeout(() => {
          submit()
        }, 3000)
      }
    }

    wakeLockScreen()
    lockKeyboard()

    document.addEventListener('visibilitychange', handleVisibilityChange)
    document.addEventListener('fullscreenchange', preventExitFullScreen)
    document.addEventListener('contextmenu', blockRightClick, false)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      document.removeEventListener('contextmenu', blockRightClick)
      document.removeEventListener('fullscreenchange', preventExitFullScreen)
    }
  }, [])

  const wakeLockScreen = () => {
    if ('wakeLock' in navigator && !wakeLock) {
      navigator.wakeLock
        .request()
        .then((lock) => {
          wakeLock = lock
          console.log('Screen wakeLock added: ', lock)

          lock.addEventListener('release', () => {
            console.log('Screen wakeLock released')
            wakeLock = false
          })
        })
        .catch((error) => {
          console.error('Failed to wakeLock screen: ', error)
        })
      wakeLock = true
    }
  }

  const removeWakeLockScreen = () => {
    if (wakeLock) {
      wakeLock.release()
      wakeLock = null
    }
  }

  const lockKeyboard = () => {
    if (
      'keyboard' in navigator &&
      'lock' in navigator.keyboard &&
      !keyboardLock
    ) {
      navigator.keyboard.lock().catch((error) => {
        console.error('Failed to lock Keyboard: ', error)
      })
      keyboardLock = true
    }
  }

  const unlockKeyboard = () => {
    navigator.keyboard.unlock()
    keyboardLock = false
  }

  const exitFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen()
    }
  }

  function getQuestionById(_id, data) {
    for (let i = 0; i < data.length; i++) {
      let topics = data[i]?.examinationTopics
      for (let j = 0; j < topics.length; j++) {
        let find_question = topics[j].questions.find((q) => q.uid === _id)
        if (find_question) return find_question
      }
    }
  }

  // Handle multi-choice option click
  const handleMultiChoiceOptionClick = (option) => {
    if (multiChoiceChecked.includes(option)) {
      setMultiChoiceChecked((prevState) =>
        prevState.filter((item) => item !== option)
      )
    } else {
      setMultiChoiceChecked((prevState) => [...prevState, option])
    }
  }

  // Handle single-choice option click
  const handleSingleChoiceOptionClick = (item) => {
    setSingleChoiceChecked(item?.option)
  }

  const handleSaveNext = async () => {
    const payload = {
      uid: examinationUid,
      question: [
        {
          questionUid: question?.questionUid,
          uid: question?.uid ?? lastQuestionuid ?? null,
          questionNo: questionCount,
          skipped: isSkip,
          markedForReview: markForReview,
          ...(question?.type !== 'DESCRIPTIVE' && {
            examinationAnswers: question?.questionOptions.map((item, index) => {
              return {
                option: item.option,
                answer: item.uid,
                selected: isSkip
                  ? false
                  : question?.type === 'SINGLE_CHOICE'
                    ? item.option === singleChoiceChecked
                    : question?.type === 'MULTIPLE_CHOICE'
                      ? multiChoiceChecked.includes(item.option)
                      : false,
                sequence: index + 1
              }
            })
          }),
          ...(question?.type === 'DESCRIPTIVE' && {
            examinationAnswers: [
              {
                option: descriptiveAns
              }
            ]
          })
        }
      ]
    }
    try {
      if (
        !isSkip &&
        (singleChoiceChecked ||
          multiChoiceChecked?.length > 0 ||
          descriptiveAns)
      ) {
        if (markForReview) setIsMarkLoading(true)
        else setIsSaveLoading(true)
        setTimeout(async function () {
          const res = await submitAnswer(payload)
          const dummyNavigationData = JSON.parse(
            JSON.stringify(navigationData ?? null)
          )
          if (question?.uid || lastQuestionuid) {
            const filterQuestion = getQuestionById(
              question?.uid ?? lastQuestionuid,
              dummyNavigationData
            )
            filterQuestion.uid = res.data?.uid
            filterQuestion.skipped = res.data?.skipped
            filterQuestion.markedForReview = res.data?.markedForReview
          } else {
            setQuestionCount(questionCount + 1)
            dummyNavigationData[typeIndex].examinationTopics[
              topicIndex
            ]?.questions.push({
              uid: res.data?.uid,
              markedForReview: res.data?.markedForReview,
              skipped: res.data?.skipped
            })

            if (question?.remainingQuestion === 0) {
              dispatch(
                questionActions.setIsRemainingQuestion({
                  isRemainingQuestion: false
                })
              )
              setLastQuestionuid(res.data?.uid)
            }
            if (!markForReview && question?.remainingQuestion === 0) {
              setShowSubmitWarningModal(true)
            }
            if (question?.remainingQuestion !== 0) {
              setMultiChoiceChecked([])
              setSingleChoiceChecked('')
              setDescriptiveAns('')
            }
          }
          dispatch(
            navigationActions.addNavigationData({
              navigationData: dummyNavigationData
            })
          )
          setIsMarkLoading(false)
          setIsSaveLoading(false)
        }, 500)
      } else if (isSkip) {
        setIsSkipButtonLoading(true)
        setTimeout(async function () {
          const res = await submitAnswer(payload)
          const dummyNavigationData = JSON.parse(JSON.stringify(navigationData))
          if (question?.uid || lastQuestionuid) {
            const filterQuestion = getQuestionById(
              question?.uid ?? lastQuestionuid,
              dummyNavigationData
            )
            filterQuestion.uid = res.data?.uid
            filterQuestion.skipped = res.data?.skipped
            filterQuestion.markedForReview = res.data?.markedForReview
          } else {
            setQuestionCount(questionCount + 1)
            dummyNavigationData[typeIndex].examinationTopics[
              topicIndex
            ]?.questions.push({
              uid: res.data?.uid,
              markedForReview: res.data?.markedForReview,
              skipped: res.data?.skipped
            })
            setMultiChoiceChecked([])
            setSingleChoiceChecked('')
            setDescriptiveAns('')
            if (!markForReview && question?.remainingQuestion === 0) {
              setShowSubmitWarningModal(true)
            }

            if (question?.remainingQuestion === 0) {
              dispatch(
                questionActions.setIsRemainingQuestion({
                  isRemainingQuestion: false
                })
              )
              setLastQuestionuid(res.data?.uid)
            }
          }
          dispatch(
            navigationActions.addNavigationData({
              navigationData: dummyNavigationData
            })
          )
          setIsSkipButtonLoading(false)
        }, 1000)
      } else {
        dispatch(
          notificationActions.openSnackbar({
            type: 'error',
            message: 'Answer is required'
          })
        )
      }
    } catch (error) {
      setIsSkipButtonLoading(false)
      setIsSaveLoading(false)
      setIsMarkLoading(false)
      dispatch(
        notificationActions.openSnackbar({
          type: 'error',
          message: error
        })
      )
    }
  }

  const submit = async () => {
    try {
      await submitExam({ id: examinationUid })
      document.exitFullscreen()
      if ('keyboard' in navigator) navigator.keyboard.unlock()
      removeWakeLockScreen()

      navigate('/user/submit', {
        replace: true,
        state: { examineeName: examinationDetails?.examineeName }
      })
      localStorage.removeItem('examinationUid')
      localStorage.removeItem('examineeUid')
      localStorage.removeItem('candidate')
      localStorage.removeItem('examCode')
      localStorage.removeItem('questionCount')
      localStorage.removeItem('duration')
      window.location.reload(true)
    } catch (error) {
      dispatch(
        notificationActions.openSnackbar({
          type: 'error',
          message: error
        })
      )
    }
  }

  const handleSkip = () => {
    isSkip = true
    handleSaveNext()
  }

  const handleMarkReview = () => {
    markForReview = true
    handleSaveNext()
  }

  const handleResume = () => {
    dispatch(
      questionActions.setShowResumeButton({
        isShowResumeButton: false
      })
    )
    dispatch(
      questionActions.addQuestion({
        questionData: lastQuestionData
      })
    )
  }

  const handleSubmit = () => {
    submit()
  }

  useEffect(() => {
    if (examTimeLeft <= 0) {
      submit()
    }
  }, [examTimeLeft])

  return (
    <>
      {(isSkipButtonLoading || isSaveLoading || isMarkLoading) && (
        <div
          style={{
            display: 'block',
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            top: '0',
            left: '0',
            zIndex: '9999'
          }}
        ></div>
      )}
      {isExaminationFetching ? (
        <Loader />
      ) : (
        <div>
          <div className="mb-[40px] pt-3">
            <ProgressBar
              value={answeredPercentage}
              numberOfQuestion={examinationDetails?.questions}
              answeredQuestion={questionStatus?.answered}
            />
          </div>
          {Object.keys(question).length !== 0 ? (
            <>
              {/* Multi-Choice Question */}
              {question && question?.type === 'MULTIPLE_CHOICE' && (
                <div className="mb-12">
                  <div className="border border-gray-300 rounded">
                    <div className="bg-grey-200 text-[16px] font-semibold p-3 select-none flex gap-2">
                      <span>{question?.question_label}.</span>
                      <div
                        dangerouslySetInnerHTML={{ __html: question?.question }}
                      ></div>
                    </div>
                    <div className="flex flex-col divide-y divide-gray-300">
                      {question?.questionOptions.map((e) => {
                        const isChecked = multiChoiceChecked?.includes(e.option)
                        return (
                          <div
                            key={e.option}
                            className="flex divide-x divide-gray-300"
                            onClick={() =>
                              handleMultiChoiceOptionClick(e.option)
                            }
                          >
                            <div
                              className={clsx(
                                'flex items-center p-3 border-gray-300 w-15',
                                {}
                              )}
                            >
                              <div>
                                <div
                                  className={`border-2 rounded flex justify-center items-center ${isChecked
                                    ? 'border-success-500'
                                    : 'border-grey-500'
                                    } h-5 aspect-square`}
                                >
                                  {isChecked && (
                                    <span className="bg-success-500 h-3 rounded-sm aspect-square"></span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="flex p-3">
                              <span
                                className={clsx('pr-3', {
                                  'text-success-500': isChecked
                                })}
                              >
                                {e.option}
                              </span>
                              <span
                                className={clsx('pr-3 select-none', {
                                  'text-success-500': isChecked
                                })}
                              >
                                {e.value}
                              </span>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )}
              {/* Single-Choice Question */}
              {question && question?.type === 'SINGLE_CHOICE' && (
                <div className="mb-12">
                  <div className="border border-gray-300 rounded">
                    <div className="bg-grey-200 text-[16px] font-semibold p-3 select-none flex gap-2">
                      <span>{question?.question_label}.</span>
                      <div
                        dangerouslySetInnerHTML={{ __html: question?.question }}
                      ></div>
                    </div>
                    <div className="flex flex-col divide-y divide-gray-300">
                      {question.questionOptions.map((item, index) => {
                        const isChecked = singleChoiceChecked === item?.option
                        return (
                          <div
                            key={index}
                            className="flex divide-x divide-gray-300 "
                            onClick={() => handleSingleChoiceOptionClick(item)}
                          >
                            <div
                              className={clsx(
                                'flex items-center p-3 border-r-gray-300 !w-15 justify-center',
                                {}
                              )}
                            >
                              <div>
                                <FormControlLabel
                                  className="m-0"
                                  control={
                                    <Radio
                                      name="singleChoice"
                                      checked={isChecked}
                                      id={`radio_${item.option}`}
                                      className={
                                        isChecked
                                          ? 'text-success-500'
                                          : 'text-grey-500'
                                      }
                                    />
                                  }
                                />
                              </div>
                            </div>
                            <div className="flex p-3 items-center">
                              <span
                                className={clsx('pr-3', {
                                  'text-success-500': isChecked
                                })}
                              >
                                {item.option}
                              </span>
                              <span
                                className={clsx('pr-3 select-none', {
                                  'text-success-500': isChecked
                                })}
                              >
                                {item.value}
                              </span>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )}
              {/* Descriptive Question */}
              {question && question?.type === 'DESCRIPTIVE' && (
                <div className="border mb-12 border-gray-300 rounded">
                  <div className="bg-grey-200 text-[16px] font-semibold p-3 select-none flex gap-2">
                    <span>{question?.question_label}.</span>
                    <div
                      dangerouslySetInnerHTML={{ __html: question?.question }}
                    ></div>
                  </div>
                  <Tiptap
                    uid={questionUid}
                    value={
                      descriptiveAns ?? question?.questionOptions[0]?.option
                    }
                    setValue={(val) => setDescriptiveAns(val)}
                    flatHeader
                    placeholder="Enter Answer"
                  />
                </div>
              )}
              <div className="flex justify-end gap-4">
                <div>
                  <Button
                    className="mr-4 !bg-extra-50 !text-extra-500"
                    onClick={handleMarkReview}
                  >
                    {isMarkLoading ? (
                      <CircularProgress color="inherit" size={30} />
                    ) : (
                      'Mark For Review'
                    )}
                  </Button>
                  <Button
                    className="mr-4 !bg-danger-50 !text-danger-500"
                    onClick={handleSkip}
                  >
                    {isSkipButtonLoading ? (
                      <CircularProgress color="inherit" size={30} />
                    ) : (
                      'Skip'
                    )}
                  </Button>

                  <Button
                    className="w-[190px] !bg-primary-100 !text-primary-500"
                    onClick={handleSaveNext}
                  >
                    {isSaveLoading ? (
                      <CircularProgress color="inherit" size={30} />
                    ) : (
                      <div className="flex">
                        {isShowResumeButton ? (
                          <span>Save</span>
                        ) : (
                          <span>
                            {question?.remainingQuestion === 0
                              ? 'Save'
                              : 'Save & Next'}
                          </span>
                        )}
                        {!isShowResumeButton &&
                          question?.remainingQuestion !== 0 && (
                            <div className={Style.button_svg}>
                              <ArrowForwardIcon sx={{ height: '18px' }} />
                            </div>
                          )}
                      </div>
                    )}
                  </Button>
                  {isShowResumeButton && isRemainingQuestion && (
                    <Button
                      width="190px"
                      className="ml-4 !bg-primary-100 !text-primary-500"
                      onClick={handleResume}
                    >
                      Continue Exam
                    </Button>
                  )}
                </div>
              </div>
            </>
          ) : (
            <Typography sx={{ fontSize: '30px', textAlign: 'center' }}>
              Question not found
            </Typography>
          )}
        </div>
      )}
      {/* submit Warning modal */}
      <Modal
        customWidth="587px"
        open={showSubmitWarningModal}
        title="Finish Exam"
        isSubmitConfirmationPopup={true}
        setOpen={() => {
          document.body.requestFullscreen()
          setShowSubmitWarningModal(false)
        }}
        onSubmit={() => {
          handleSubmit()
          setShowSubmitWarningModal(false)
        }}
      >
        <Typography component="p" sx={{ fontSize: '18px', fontWeight: '500' }}>
          Are you sure you want to finish exam?
        </Typography>
        <Typography component="div">
          <Typography component="div">
            <Typography sx={{ display: 'flex' }} component="div">
              <Typography
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '16px',
                  width: '50%'
                }}
              >
                <Typography
                  component="div"
                  sx={{
                    height: '42px',
                    width: '42px',
                    marginRight: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#F0FDF4',
                    color: '#22C55E',
                    borderRadius: '50%'
                  }}
                >
                  {questionStatus?.answered}
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    padding: '10px',
                    color: '#18181B'
                  }}
                >
                  Answered
                </Typography>
              </Typography>
              <Typography
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '16px'
                }}
              >
                <Typography
                  component="div"
                  sx={{
                    height: '42px',
                    width: '42px',
                    marginRight: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#FEF2F2',
                    color: '#EF4444',
                    borderRadius: '50%'
                  }}
                >
                  {questionStatus?.skipped}
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    padding: '10px',
                    color: '#18181B'
                  }}
                >
                  Skipped
                </Typography>
              </Typography>
            </Typography>
            <Typography sx={{ display: 'flex' }} component="div">
              <Typography
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '16px',
                  width: '50%'
                }}
              >
                <Typography
                  component="div"
                  sx={{
                    height: '42px',
                    width: '42px',
                    marginRight: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#EDE7F6',
                    color: '#673AB7',
                    borderRadius: '50%'
                  }}
                >
                  {questionStatus?.review}
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    padding: '10px',
                    color: '#18181B'
                  }}
                >
                  Marked For Review
                </Typography>
              </Typography>
              <Typography
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '16px',
                  width: '50%'
                }}
              >
                <Typography
                  component="div"
                  sx={{
                    height: '42px',
                    width: '42px',
                    marginRight: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#FAFAFA',
                    color: '#71717A',
                    borderRadius: '50%'
                  }}
                >
                  {questionStatus?.not_visited}
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    padding: '10px',
                    color: '#18181B'
                  }}
                >
                  Not Visited
                </Typography>
              </Typography>
            </Typography>
          </Typography>
        </Typography>
      </Modal>
    </>
  )
}

export default QuestionPaperMCQ
