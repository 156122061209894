import Style from './card.module.css'
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded'
import totalExamConductedImage from '../../../assets/image/total_exam_conducted.svg'

function DashboardCard(props) {
  return (
    <>
      <div className={Style.cardwrapper}>
        <div className={Style.cardbox}>
          <p className={Style.cardtext}>Total Question</p>
          <div className={Style.cardwrap}>
            <div className={Style.cardvectororange}>
              <QuizOutlinedIcon style={{ fill: '#EA6A47' }} />
            </div>
            <h2 className={Style.cardnumber}>{props.totalQuestion}</h2>
          </div>
        </div>

        <div className={Style.cardbox}>
          <p className={Style.cardtext}>Total Exam</p>
          <div className={Style.cardwrap}>
            <div className={Style.cardvectorblue}>
              <ArticleOutlinedIcon style={{ fill: '#006AFE' }} />
            </div>
            <h2 className={Style.cardnumber}>{props.totalExam}</h2>
          </div>
        </div>

        <div className={Style.cardbox}>
          <p className={Style.cardtext}>Success Rate</p>
          <div className={Style.cardwrap}>
            <div className={Style.cardvectorgreen}>
              <BarChartRoundedIcon style={{ fill: '#22C55E' }} />
            </div>
            <h2 className={Style.cardnumber}>{props.sucessRate}</h2>
          </div>
        </div>

        <div className={Style.cardbox}>
          <p className={Style.cardtext}>Total Exam Conducted</p>
          <div className={Style.cardwrap}>
            <div className={Style.cardvectoryellow}>
              <img
                src={totalExamConductedImage}
                alt="exam-concluded-logo"
              ></img>
            </div>
            <h2 className={Style.cardnumber}>{props.totalExamConducted}</h2>
          </div>
        </div>
      </div>
    </>
  )
}
export default DashboardCard
