import { api } from './api'

const topicEndpoint = 'topic'

const topicApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTopics: build.query({
      query: () => topicEndpoint,
      transformResponse: (response) => response.data,
      providesTags: ['Topic']
    }),
    getTopicsByFilter: build.query({
      query: (body) => ({
        url: `${topicEndpoint}/filter`,
        method: 'POST',
        body: body || { domain: '', page: 0, size: 25 }
      }),
      transformResponse: (response) => ({
        data: response.data,
        totalItems: response.totalItems,
        totalPages: response.totalPages,
        currentPage: response.currentPage
      }),
      transformErrorResponse: (response) => response.data,
      providesTags: ['Topic']
    }),
    getTopicByDomain: build.query({
      query: ({ domainId }) => `${topicEndpoint}/domain/${domainId}`,
      transformResponse: (response) => response.data,
      providesTags: ['Topic']
    }),
    addTopic: build.mutation({
      query: (body) => ({
        url: topicEndpoint,
        method: 'POST',
        body
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['Topic']
    }),
    deleteTopic: build.mutation({
      query: ({ id }) => ({
        url: `${topicEndpoint}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Topic']
    })
  })
})

export const {
  useGetTopicsQuery,
  useLazyGetTopicsQuery,
  useGetTopicsByFilterQuery,
  useLazyGetTopicsByFilterQuery,
  useGetTopicByDomainQuery,
  useLazyGetTopicByDomainQuery,
  useAddTopicMutation,
  useDeleteTopicMutation
} = topicApi
