import React, { useEffect, useState } from 'react'
import { Alert, Snackbar } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Style from './paper.module.css'
import Button from '../../components/core/Button/Button'
import CandidateInfo from '../../components/layout/CandidateInfo/CandidateInfo'
import QuestionGrid from '../../components/layout/QuestionGrid/QuestionGrid'
import ViewAnwser from '../../components/layout/ViewAnswer/ViewAnswer'
import parse from 'html-react-parser'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useParams } from 'react-router'
import EnterMarks from '../../components/layout/EnterMarks/EnterMarks'
import {
  useGetPageCheckerExaminationDetailsQuery,
  useLazyGetExaminationDiscriptiveQuestionQuery,
  useSetExaminationDiscriptiveQuestionMarksMutation
} from '../../app/services/page-checker'

const pendingOptions = [{ value: 'option1', label: 'In-Progress' }]

function PaperChecker() {
  const { examinationUid } = useParams()
  const navigate = useNavigate()

  const [getExaminationDiscriptiveQuestionQuery] =
    useLazyGetExaminationDiscriptiveQuestionQuery()
  const [setExaminationDiscriptiveQuestionMarks] =
    useSetExaminationDiscriptiveQuestionMarksMutation()
  const { data: examinationData } = useGetPageCheckerExaminationDetailsQuery({
    examinationUid: examinationUid
  })

  const [questionData, setQuestionData] = useState({})
  const [obtainedMarks, setObtainedMarks] = useState(null)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [nextButtonText, setNextButtonText] = useState('Next')

  let data = [
    {
      label: 'Name',
      value: examinationData ? examinationData.examineeName : ''
    },
    {
      label: 'Department',
      value: examinationData ? examinationData.department : ''
    },
    {
      label: 'Email Id',
      value: examinationData ? examinationData.email : ''
    },
    {
      label: 'Exam Date',
      value: examinationData ? formateDate(examinationData.startTime) : ''
    },
    {
      label: 'Exam Code',
      value: examinationData ? examinationData.examCode : ''
    }
  ]

  useEffect(() => {
    if (examinationUid) {
      getExaminationDiscriptiveQuestionQuery({
        examinationUid: examinationUid
      }).then(({ data }) => {
        setObtainedMarks('')
        if (data) {
          setQuestionData({
            question: data.question,
            questionAnswer: data.questionAnswer,
            totalMarks: data.totalMarks,
            obtainMarks: null,
            examinationQuestionUid: data.examinationQuestionUid,
            remainingQuestions: data.remaining,
            totalQuestions: data.total
          })
        }
      })
    }
  }, [examinationUid, getExaminationDiscriptiveQuestionQuery])

  function formateDate(inputDate) {
    if (!inputDate) {
      return ''
    }
    const date = new Date(inputDate)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${month}/${day}/${year}`
  }

  const handleNextButtonClick = () => {
    setObtainedMarks('')
    setErrorMessage('Answer Checked Successfully')
    setOpenSnackbar(true)
    if (obtainedMarks === null) {
      setErrorMessage('Need to insert marks')
      setOpenSnackbar(true)
      return
    }

    const body = {
      examinationQuestionUid: questionData.examinationQuestionUid,
      obtainMarks: obtainedMarks
    }
    setExaminationDiscriptiveQuestionMarks(body).then((response) => {
      const { data, error } = response
      if (data?.resultMessage === 'Success') {
        getExaminationDiscriptiveQuestionQuery({
          examinationUid: examinationUid
        }).then(({ data }) => {
          setObtainedMarks('')
          if (data) {
            if (questionData.remainingQuestions === 0) {
              navigate('/paper-checker')
            } else {
              setQuestionData({
                question: data.question,
                questionAnswer: data.questionAnswer,
                totalMarks: data.totalMarks,
                obtainMarks: null,
                examinationQuestionUid: data.examinationQuestionUid,
                remainingQuestions: data.remaining,
                totalQuestions: data.total
              })
              if (data.remaining) setNextButtonText('Finish')
            }
          }
        })
      } else if (error) {
        setErrorMessage(error.data.resultMessage)
        setOpenSnackbar(true)
      }
    })
  }
  return (
    <>
      <div className={Style.paperlisting}>
        <div className="mb-12">
          <CandidateInfo candidateInfo={data} />
        </div>
        {questionData && (
          <>
            <div className="mb-12">
              <QuestionGrid
                totalQuestions={questionData.totalQuestions}
                remainingQuestions={questionData.remainingQuestions}
              />
            </div>
            <div className="mb-6">
              <div className="pl-6 pr-6 pb-4 flex  pt-4 border border-gray-300 bg-gray-100 rounded">
                <span className="px-2">
                  {questionData.totalQuestions -
                    questionData.remainingQuestions}
                  .
                </span>
                <p>
                  {parse(questionData.question ? questionData.question : '')}
                </p>
              </div>
            </div>
            <div className="mb-8">
              <ViewAnwser text={questionData.questionAnswer} />
            </div>
            <div className="flex justify-end gap-4">
              <EnterMarks
                totalMarks={questionData.totalMarks}
                onChange={(value) => {
                  setObtainedMarks(value ?? '')
                }}
                value={obtainedMarks}
              />
              <Button width="123px" onClick={handleNextButtonClick}>
                <div className="flex">
                  <span>{nextButtonText}</span>
                  <div className={Style.button_svg}>
                    <ArrowForwardIcon sx={{ height: '20px' }} />
                  </div>
                </div>
              </Button>
            </div>
          </>
        )}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          severity={
            errorMessage === 'Answer Checked Successfully' ? 'success' : 'error'
          }
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

export default PaperChecker
