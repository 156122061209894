import React, { useEffect, useState } from 'react'
import Style from './question.module.css'
import Button from '../../components/core/Button/Button'
import { Typography } from '@mui/material'
import Tiptap from '../../components/core/TipTapTextEditor/TipTap'
import CustomTable from '../../components/feature/Table/Table'
import Modal from '../../components/layout/Modal/Modal'
import Select from '../../components/core/Select/Select'
import TextArea from '../../components/core/TextArea/TextArea'
import { useGetDomainsQuery } from '../../app/services/domain'
import { useLazyGetTopicByDomainQuery } from '../../app/services/topic'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useAddQuestionMutation,
  useDeleteQuestionMutation,
  useEditQuestionMutation,
  useLazyGetQuestionByIdQuery
} from '../../app/services/question'
import removeOptionImage from '../../assets/image/remove-option.svg'
import {
  complexity,
  labels,
  questionType,
  validation
} from '../../constants/constants'
import AlertMessage from '../../components/feature/AlertMessage/AlertMessage'

const questiontype = [
  { value: questionType.MULTIPLE_CHOICE, label: labels.MULTIPLE_CHOICE },
  { value: questionType.SINGLE_CHOICE, label: labels.SINGLE_CHOICE },
  { value: questionType.DESCRIPTIVE, label: labels.DESCRIPTIVE }
]
const complexitylevel = [
  { value: complexity.EASY, label: labels.EASY },
  { value: complexity.MEDIUM, label: labels.MEDIUM },
  { value: complexity.HARD, label: labels.HARD }
]

function QuestionAddEdit() {
  const navigate = useNavigate()
  const onOpenPopup = () => {
    setPopupOpen(true)
  }
  const [options, setOptions] = useState([])
  const [popupOpen, setPopupOpen] = useState(false)
  const { data: domains } = useGetDomainsQuery()
  const [getTopicsByDomain, { currentData: topics }] =
    useLazyGetTopicByDomainQuery()
  const [selectedDepartment, setSelectedDepartment] = useState()
  const [selectedTopic, setSelectedTopic] = useState()
  const [selectedQuestionType, setSelectedQuestionType] = useState({
    value: '',
    label: ''
  })
  const [selectedComplexity, setSelectedComplexity] = useState({
    value: '',
    label: ''
  })
  const [question, setQuestion] = useState()
  const { questionId } = useParams()
  const [getQuestionById] = useLazyGetQuestionByIdQuery()
  const [addQuestion] = useAddQuestionMutation()
  const [editQuestion] = useEditQuestionMutation()
  const [questionData, setQuestionData] = useState()
  const [optionInput, setOptionInput] = useState()
  const [open, setOpen] = useState(false)
  const [deleteQuestion] = useDeleteQuestionMutation()
  const [alertMessage, setAlertMessage] = useState()
  const [alertSeverity, setAlertSeverity] = useState()
  const [marksValue, setMarksValue] = useState('')

  const handleMarkChange = (event) => {
    setMarksValue(event.target.value)
  }

  const handleDepartmentChange = (value) => {
    setSelectedDepartment({ uid: value.value, name: value.label })
    getTopicsByDomain({
      domainId: value.value
    }).then(() => setSelectedTopic(''))
  }
  const handleTopicChange = (value) => {
    setSelectedTopic({ uid: value.value, name: value.label })
  }

  const handleComplexityChange = (value) => {
    setSelectedComplexity(value)
  }

  const handleQuestionTypeChange = (value) => {
    setSelectedQuestionType(value)
    if (options && options.length > 1) {
      setOptions(
        options.map((option) => {
          return { ...option, answer: false }
        })
      )
    }
  }

  useEffect(() => {
    if (questionId) {
      getQuestionById({ id: questionId }).then(({ data }) => {
        setQuestionData(data)
        if (data) {
          setSelectedDepartment(data.domain)
          getTopicsByDomain({
            domainId: data.domain.uid
          }).then(({ isSuccess }) => {
            if (isSuccess) {
              setSelectedTopic(data.topic)
            }
          })
          setSelectedQuestionType({
            value: data.type,
            label: labels[data.type]
          })
          setQuestion(data.question)
          let optionList = []
          data?.questionOptions?.forEach((item) => {
            optionList.push({ option: item.option, answer: item.answer })
          })
          setOptions(optionList)
          setMarksValue(data.marks)
          setSelectedComplexity({
            value: data.complexity,
            label: labels[data.complexity]
          })
          setSelectedTopic(data.topic)
        }
      })
    }
  }, [questionId, getQuestionById, getTopicsByDomain])

  const columns = [
    {
      label: 'No',
      value: 'no',
      width: '5%',
      render: ({ row, rowIndex }) => {
        return (
          <>
            <div
              className={
                selectedQuestionType.value === questionType.SINGLE_CHOICE
                  ? `${Style.form_roup}  ${Style.singleans__checkbox}`
                  : `${Style.form_roup}`
              }
            >
              <input
                type="checkbox"
                id={'checkbox_' + rowIndex + 1}
                onChange={() =>
                  setOptions((prevOptions) =>
                    prevOptions.map((row, index) => {
                      if (index === rowIndex) {
                        return { ...row, answer: !row.answer }
                      } else if (
                        selectedQuestionType.value ===
                        questionType.MULTIPLE_CHOICE
                      ) {
                        return row
                      } else {
                        return { ...row, answer: false }
                      }
                    })
                  )
                }
                checked={row.answer}
              />
              <label htmlFor={'checkbox_' + rowIndex + 1}></label>
            </div>
          </>
        )
      }
    },
    {
      label: 'Option',
      value: 'option',
      width: '95%',
      render: ({ row, rowIndex }) => {
        return (
          <>
            <Typography style={{ color: row.answer ? '#09c906' : '#71717A' }}>
              {row.option}
            </Typography>

            <img
              src={removeOptionImage}
              alt="remove"
              onClick={() =>
                setOptions((prevOptions) =>
                  prevOptions.filter((_, index) => index !== rowIndex)
                )
              }
            />
          </>
        )
      }
    }
  ]

  const handleAddOrEditQuestion = () => {
    // tiptap return empty <p> tag, if it is empty
    if (
      !question ||
      question === '<p></p>' ||
      !selectedDepartment ||
      !selectedTopic ||
      !selectedQuestionType ||
      (selectedComplexity && !selectedComplexity.value)
    ) {
      setAlertSeverity(validation.FAIL)
      setAlertMessage(validation.REQUIRED_FIELDS_MSG)
      if (selectedComplexity && !selectedComplexity.value) {
        setAlertMessage('Complexity is mandatory to select')
      }
      return
    }

    const questionData = {
      question: question,
      type: selectedQuestionType.value,
      topic: selectedTopic,
      domain: selectedDepartment,
      questionOptions: options,
      complexity: selectedComplexity.value,
      marks: marksValue
    }

    if (questionId) {
      editQuestion({ ...questionData, uid: questionId }).then(
        ({ data, error }) => {
          if (data) {
            setAlertSeverity(validation.SUCCESS)
            setAlertMessage('Question Updated Succesfully')
            navigate('/question', { replace: true })
          } else if (error) {
            setAlertSeverity(validation.FAIL)
            setAlertMessage(error.data.resultMessage)
          }
        }
      )
    } else {
      addQuestion(questionData).then(({ data, error }) => {
        if (data) {
          setAlertSeverity(validation.SUCCESS)
          setAlertMessage('Question Saved Succesfully')
          navigate('/question', { replace: true })
        } else if (error) {
          setAlertSeverity(validation.FAIL)
          setAlertMessage(error.data.resultMessage)
        }
      })
    }
  }
  if (
    questionId !== undefined &&
    (questionData === undefined ||
      selectedDepartment === undefined ||
      selectedTopic === undefined)
  ) {
    return
  }

  return (
    <div className={Style.editque}>
      {alertMessage && (
        <div className="flex justify-center items-center">
          <AlertMessage
            severity={alertSeverity}
            message={alertMessage}
            className="absolute"
          />
        </div>
      )}
      <div className={Style.editque__wrapper}>
        <div className={Style.editque__left}>
          <div className={Style.editque__select}>
            <div className={Style.editque__select__input}>
              <div className={Style.select__inputfield}>
                <Select
                  placeholder="Department"
                  options={
                    domains
                      ? domains.map((domain) => ({
                          value: domain.uid,
                          label: domain.name
                        }))
                      : ''
                  }
                  value={
                    selectedDepartment
                      ? {
                          value: selectedDepartment.uid,
                          label: selectedDepartment.name
                        }
                      : ''
                  }
                  onChange={handleDepartmentChange}
                />
              </div>
              <div className={Style.select__inputfield}>
                <Select
                  placeholder="Topic"
                  options={
                    topics !== undefined
                      ? topics.map((topic) => ({
                          value: topic.uid,
                          label: topic.name
                        }))
                      : []
                  }
                  value={
                    selectedTopic
                      ? {
                          value: selectedTopic.uid,
                          label: selectedTopic.name
                        }
                      : ''
                  }
                  onChange={handleTopicChange}
                />
              </div>
            </div>
            <div className={Style.editque__select__input}>
              <div className={Style.select__inputfield}>
                <Select
                  placeholder="Question Type"
                  options={questiontype}
                  value={selectedQuestionType}
                  onChange={handleQuestionTypeChange}
                />
              </div>
              <div className={Style.select__inputfield}>
                <Select
                  placeholder="Complexity Level"
                  options={complexitylevel}
                  value={selectedComplexity}
                  onChange={handleComplexityChange}
                />
              </div>
            </div>
            <div>
              <input
                className="border py-[7px] px-3 w-[48%] rounded-[3px] focus:outline-none border-gray-400"
                type="number"
                placeholder="Mark"
                value={marksValue}
                min={0}
                max={10}
                onChange={handleMarkChange}
              />
            </div>
          </div>
          <div className={Style.editque__texteditor}>
            <Tiptap
              placeholder="Enter Your Question"
              sx={{
                with: '192px'
              }}
              value={question}
              setValue={setQuestion}
            />
          </div>
          <div className={Style.editque__action}>
            {questionId ? (
              <Button color="danger" onClick={() => setOpen(true)}>
                Delete
              </Button>
            ) : (
              ''
            )}
            <Button
              onClick={handleAddOrEditQuestion}
              disabled={
                selectedQuestionType.value !== questionType.DESCRIPTIVE
                  ? !options.some((opt) => opt.answer)
                  : false
              }
            >
              Save
            </Button>
          </div>
        </div>
        {selectedQuestionType.value !== questionType.DESCRIPTIVE && (
          <div className={Style.editque__right}>
            <div className={Style.editque__rightaction}>
              <Button onClick={onOpenPopup}>Add Option </Button>
            </div>
            <div className={Style.opctiontable}>
              <CustomTable columns={columns} data={options} />
            </div>

            <Typography component="p" className={Style.editque__note}>
              NOTE : You must need to select correct answer from above option
            </Typography>
          </div>
        )}
      </div>
      <Modal
        open={popupOpen}
        title="Add Option"
        setOpen={setPopupOpen}
        onSubmit={() => {
          setOptions((prevOptions) => [
            ...prevOptions,
            { option: optionInput, answer: false }
          ])
          setPopupOpen(false)
        }}
      >
        <Typography component="div">
          <TextArea
            border="1px solid #D4D4D8"
            minWidth="100%"
            borderRadius="4px"
            padding="16px"
            onChange={(value) => setOptionInput(value)}
          />
        </Typography>
      </Modal>

      <Modal
        open={open}
        title="Delete Question"
        isDeleteConfirmationPopup={true}
        setOpen={setOpen}
        onSubmit={() => {
          if (questionId) {
            deleteQuestion({ id: questionId }).then()
          }
          setOpen(false)
          navigate('/question', { replace: true })
        }}
      >
        <Typography className={Style.delete__text}>
          Are you sure you want to delete Question?
        </Typography>
      </Modal>
    </div>
  )
}

export default QuestionAddEdit
