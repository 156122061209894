import * as React from 'react'
import Style from './department.module.css'
import Typography from '@mui/material/Typography'
import { useAddDomainMutation } from '../../app/services/domain'
import Modal from '../../components/layout/Modal/Modal'
import TextField from '../../components/core/TextField/TextField'
import { Snackbar } from '@mui/base'
import { Alert } from '@mui/material'
import { DOMAIN_RGEX } from '../../constants/constants'

function AddDeparment(props) {
  const { open, setOpen } = props
  const [addDomain] = useAddDomainMutation()
  const [department, setDepartment] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState()
  const [openSnackbar, setOpenSnackbar] = React.useState(false)
  const handleClose = () => {
    setErrorMessage()
    setOpen(false)
  }

  const handleChange = (value) => {
    const pattern = DOMAIN_RGEX
    if (pattern.test(value)) {
      setDepartment(value)
      setErrorMessage()
    } else {
      setDepartment(value)
      setErrorMessage('Special characters and numbers are not allowed.')
    }
  }
  const addButtonOnAction = () => {
    const pattern = DOMAIN_RGEX
    if (!pattern.test(department)) {
      setErrorMessage('Special characters and numbers are not allowed.')
      return
    }
    if (!department) {
      setErrorMessage('Please enter department')
      return
    }

    addDomain({ name: department }).then(({ data, error }) => {
      if (data) {
        handleClose()
      } else if (error) {
        setOpenSnackbar(true)
      }
    })
  }

  const handleOnKeyUp = (event) => {
    if (event.key === 'Enter') {
      addButtonOnAction()
    }
  }

  return (
    <>
      <Modal
        open={open}
        title="Add Department"
        setOpen={setOpen}
        onClose={handleClose}
        onSubmit={addButtonOnAction}
      >
        <Typography variant="p" className="w-1/2">
          <TextField
            className="error_text"
            inputClassName={errorMessage ? Style.alert_border : ''}
            placeholder="Enter Department Name"
            onChange={handleChange}
            isError={errorMessage ? true : false}
            onKeyUp={handleOnKeyUp}
          />
          {errorMessage && <p className={Style.error_text}>{errorMessage}</p>}
        </Typography>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert severity="error">Department already exist.</Alert>
        </Snackbar>
      </Modal>
    </>
  )
}

export default AddDeparment
