import { createSlice } from '@reduxjs/toolkit'

const initialquestionState = {
  questionData: {},
  lastQuestionData: {},
  isShowResumeButton: false,
  questionStatus: {},
  isRemainingQuestion: true,
  examStarted: false
}

const questionSlice = createSlice({
  name: 'question',
  initialState: initialquestionState,
  reducers: {
    addQuestion: (state, action) => ({
      ...state,
      ...action.payload
    }),
    addLastQuestionData: (state, action) => ({
      ...state,
      ...action.payload
    }),
    setShowResumeButton: (state, action) => ({
      ...state,
      ...action.payload
    }),
    addQuestionStatus: (state, action) => ({
      ...state,
      ...action.payload
    }),
    setIsRemainingQuestion: (state, action) => ({
      ...state,
      ...action.payload
    }),
    setExamStarted: (state, action) => ({
      ...state,
      ...action.payload
    })
  }
})

export const questionActions = questionSlice.actions

export default questionSlice
