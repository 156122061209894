import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { IconButton, Typography } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  useLazyGetExaminationDetailsQuery,
  useLazyStartExamQuery,
  useSubmitExamByExaminationUidMutation
} from '../../app/services/exam-question'
import { notificationActions } from '../../app/store/notification'
import Button from '../../components/core/DynamicButton/DynamicButton'
import Timer from '../../components/core/timer/Timer'
import ExamInformation from '../../components/feature/QuestionPaper/ExamInformation'
import QuestionPaperSidebar from '../../components/feature/QuestionPaper/QuestionsPaperSidebar'
import QuestionPaperMCQ from '../../components/feature/QuestionPaper/QuestionPaperMCQ'
import Loader from '../../components/layout/Loader/Loader'
import Modal from '../../components/layout/Modal/Modal'
import Style from './examlayout.module.css'

let Once = false

const QuestionPaperLayout = () => {
  const dispatch = useDispatch()
  const [startExam, { isFetching }] = useLazyStartExamQuery()
  const [submitExam] = useSubmitExamByExaminationUidMutation()
  const [getExaminationInfo, { isFetching: isExaminationFetching }] =
    useLazyGetExaminationDetailsQuery()
  const [examinationUid, setExaminationUid] = useState(
    localStorage.getItem('examinationUid')
  )
  const navigate = useNavigate()
  const navigationData = useSelector((state) => state.navigation.navigationData)
  const questionStatus = useSelector((state) => state.question.questionStatus)
  const examStarted = useSelector((state) => state.question.examStarted)
  const [examTimeLeft, setExamTimeLeft] = useState()
  const [examinationDetails, setExaminationDetails] = useState(null)
  const [showSubmitWarningModal, setShowSubmitWarningModal] = useState(false)

  const [isPopoverVisible, setIsPopoverVisible] = useState(false)

  const handlePopoverToggle = () => {
    setIsPopoverVisible(!isPopoverVisible)
  }
  const examInfo = [
    {
      label: 'Name',
      value: examinationDetails?.examineeName
    },
    {
      label: 'Department',
      value: examinationDetails?.department
    },
    {
      label: 'Duration',
      value: `${examinationDetails?.duration} Minutes`
    },
    {
      label: 'Total Marks',
      value: examinationDetails?.marks
    },
    {
      label: 'Total Questions',
      value: examinationDetails?.questions
    }
  ]

  function handleStartExam() {
    startExam({
      examCode: localStorage.getItem('examCode'),
      examineeUid: localStorage.getItem('examineeUid')
    })
      .then(({ data, error }) => {
        if (data) {
          localStorage.setItem('examinationUid', data.uid)
          setExaminationUid(data.uid)
          getExaminationDetails(data.uid)
        } else if (error) {
          navigate('/user/registration', { replace: true })
        }
      })
      .catch((error) => {
        navigate('/user/registration', { replace: true })
        dispatch(
          notificationActions.openSnackbar({
            type: 'error',
            message: error
          })
        )
      })
  }

  const getExaminationDetails = async (examId) => {
    try {
      const res = await getExaminationInfo({ id: examId })
      setExaminationDetails(res?.data)
    } catch (error) {
      dispatch(
        notificationActions.openSnackbar({
          type: 'error',
          message: error
        })
      )
    }
  }

  async function handleSubmit() {
    try {
      await submitExam({ id: examinationUid })
      document.exitFullscreen()
      if ('keyboard' in navigator) navigator.keyboard.unlock()
      navigate('/user/submit', {
        replace: true,
        state: { examineeName: examinationDetails?.examineeName }
      })
      localStorage.removeItem('examinationUid')
      localStorage.removeItem('examineeUid')
      localStorage.removeItem('candidate')
      localStorage.removeItem('examCode')
      localStorage.removeItem('questionCount')
      localStorage.removeItem('duration')
      window.location.reload(true)
    } catch (error) {
      dispatch(
        notificationActions.openSnackbar({
          type: 'error',
          message: error
        })
      )
    }
  }

  useEffect(() => {
    if (!examStarted) {
      if (!Once) {
        handleSubmit()
      }
      Once = true
    } else {
      handleStartExam()
    }
  }, [])

  return (
    <>
      {!examStarted ? (
        <div></div>
      ) : (
        <>
          {isFetching ? (
            <Loader />
          ) : (
            <div style={{ display: 'flex' }}>
              <CssBaseline />
              {examinationUid && (
                <div
                  style={{
                    flexShrink: 0,
                    height: '100vh',
                    overflowY: 'auto',
                    borderRight: '1px solid #D4D4D8'
                  }}
                >
                  <QuestionPaperSidebar />
                </div>
              )}

              <div
                className="w-full bg-[#FAFAFA]"
                style={{
                  flex: '1',
                  height: '100vh',
                  overflowY: 'auto'
                }}
              >
                <div className="h-[80px] flex p-6 w-full justify-end items-center border-b border-solid border-gray-300 sticky z-50 top-0 bg-[#FAFAFA]">
                  {!isExaminationFetching && examinationDetails && (
                    <div className="flex gap-4">
                      <Timer
                        duration={examinationDetails?.duration}
                        setExamTimeLeft={setExamTimeLeft}
                      />
                      <IconButton
                        className="!bg-primary-50 p-[10px] rounded-[4px]"
                        variant="contained"
                        onClick={handlePopoverToggle}
                      >
                        <InfoOutlinedIcon className="text-primary-500 w-5 h-5" />
                      </IconButton>
                      <Button
                        className="text-[14px] font-medium "
                        onClick={() => setShowSubmitWarningModal(true)}
                      >
                        Finish Exam
                      </Button>
                    </div>
                  )}
                </div>
                <Typography component="main" sx={{ flexGrow: 1, p: 3 }}>
                  {examinationUid && navigationData && (
                    <QuestionPaperMCQ
                      examinationDetails={examinationDetails}
                      isExaminationFetching={isExaminationFetching}
                      examTimeLeft={examTimeLeft}
                    />
                  )}
                </Typography>
              </div>
            </div>
          )}
          {/* // submit Warning modal */}
          <Modal
            customWidth="587px"
            open={showSubmitWarningModal}
            title="Finish Exam"
            isSubmitConfirmationPopup={true}
            setOpen={() => {
              setShowSubmitWarningModal(false)
            }}
            onSubmit={() => {
              handleSubmit()
              setShowSubmitWarningModal(false)
            }}
          >
            <Typography
              component="p"
              sx={{ fontSize: '18px', fontWeight: '500' }}
            >
              Are you sure you want to finish exam?
            </Typography>
            <Typography component="div">
              <Typography component="div">
                <Typography sx={{ display: 'flex' }} component="div">
                  <Typography
                    component="div"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '16px',
                      width: '50%'
                    }}
                  >
                    <Typography
                      component="div"
                      sx={{
                        height: '42px',
                        width: '42px',
                        marginRight: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#F0FDF4',
                        color: '#22C55E',
                        borderRadius: '50%'
                      }}
                    >
                      {questionStatus?.answered || 0}
                    </Typography>
                    <Typography
                      component="div"
                      sx={{
                        fontSize: '16px',
                        fontWeight: '500',
                        padding: '10px'
                      }}
                    >
                      Answered
                    </Typography>
                  </Typography>
                  <Typography
                    component="div"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '16px'
                    }}
                  >
                    <Typography
                      component="div"
                      sx={{
                        height: '42px',
                        width: '42px',
                        marginRight: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#FEF2F2',
                        color: '#EF4444',
                        borderRadius: '50%'
                      }}
                    >
                      {questionStatus?.skipped || 0}
                    </Typography>
                    <Typography
                      component="div"
                      sx={{
                        fontSize: '16px',
                        fontWeight: '500',
                        padding: '10px'
                      }}
                    >
                      Skipped
                    </Typography>
                  </Typography>
                </Typography>
                <Typography sx={{ display: 'flex' }} component="div">
                  <Typography
                    component="div"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '16px',
                      width: '50%'
                    }}
                  >
                    <Typography
                      component="div"
                      sx={{
                        height: '42px',
                        width: '42px',
                        marginRight: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#EDE7F6',
                        color: '#673AB7',
                        borderRadius: '50%'
                      }}
                    >
                      {questionStatus?.review || 0}
                    </Typography>
                    <Typography
                      component="div"
                      sx={{
                        fontSize: '16px',
                        fontWeight: '500'
                      }}
                    >
                      Marked For Review
                    </Typography>
                  </Typography>
                  <Typography
                    component="div"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '16px',
                      width: '50%'
                    }}
                  >
                    <Typography
                      component="div"
                      sx={{
                        height: '42px',
                        width: '42px',
                        marginRight: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#FAFAFA',
                        color: '#71717A',
                        borderRadius: '50%'
                      }}
                    >
                      {questionStatus?.not_visited || 0}
                    </Typography>
                    <Typography
                      component="div"
                      sx={{
                        fontSize: '16px',
                        fontWeight: '500',
                        padding: '10px'
                      }}
                    >
                      Not Visited
                    </Typography>
                  </Typography>
                </Typography>
              </Typography>
            </Typography>
          </Modal>
          <div className="">
            {isPopoverVisible && (
              <div className={Style.infobar}>
                <ExamInformation examInfo={examInfo} />
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default memo(QuestionPaperLayout)
