import { RichTextEditor, Link } from '@mantine/tiptap'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import { useEffect } from 'react'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import Highlight from '@tiptap/extension-highlight'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'

const Tiptap = (props) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      TextAlign,
      Highlight,
      Subscript,
      Superscript,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Placeholder.configure({
        placeholder: props.placeholder
          ? props.placeholder
          : 'Write Your Answer..'
      })
    ],
    content: props.value,
    onUpdate({ editor }) {
      props.setValue(editor.getHTML())
    }
  })

  useEffect(() => {
    if (props?.uid && editor?.view?.dom)
      editor.view.dom.innerHTML = props?.value
  }, [props?.uid])

  return (
    <RichTextEditor
      editor={editor}
      sx={{
        backgroundColor: '#FAFAFA',
        height: '100%',
        border: 'none',
        display: 'flex',
        flexDirection: 'column'
      }}
      styles={{
        toolbar: {
          position: 'static',
          justifyContent: 'right',
          backgroundColor: '#71717A',
          top: '0',
          borderTopRightRadius: '3px',
          borderTopLeftRadius: '3px',
          border: 'none'
        },
        control: {
          border: 0,
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: 'transparent !important'
          }
        },
        typographyStylesProvider: {
          overflow: 'auto',
          height: '100%',
          borderStyle: 'solid',
          borderColor: '#a1a1aa',
          borderWidth: 1,
          borderTopWidth: 0,
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4
        },
        content: {
          background: '#f9fafb',
          ul: {
            listStyle: 'disc'
          },
          ol: {
            listStyle: 'decimal'
          }
        }
      }}
    >
      <RichTextEditor.Toolbar sticky stickyOffset={60}>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.H1 />
          <RichTextEditor.H2 />
          <RichTextEditor.H3 />
          <RichTextEditor.H4 />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Bold />
          <RichTextEditor.Italic />
          <RichTextEditor.Underline />
          <RichTextEditor.Strikethrough />
          <RichTextEditor.ClearFormatting />
          <RichTextEditor.Highlight />
          <RichTextEditor.Code />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Blockquote />
          <RichTextEditor.Hr />
          <RichTextEditor.BulletList />
          <RichTextEditor.OrderedList />
          <RichTextEditor.Subscript />
          <RichTextEditor.Superscript />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Link />
          <RichTextEditor.Unlink />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.AlignLeft />
          <RichTextEditor.AlignCenter />
          <RichTextEditor.AlignJustify />
          <RichTextEditor.AlignRight />
        </RichTextEditor.ControlsGroup>
      </RichTextEditor.Toolbar>

      <RichTextEditor.Content />
    </RichTextEditor>
  )
}

export default Tiptap
