import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  shape: {
    borderRadius: 3
  },
  components: {
    // Base Components
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    }
  }
})

export default theme
