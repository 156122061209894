import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import CustomTable from '../../components/feature/Table/Table'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import Style from './result.module.css'
import { useLazyGetAnswerSheetQuery } from '../../app/services/exam-result'
import Loader from '../../components/layout/Loader/Loader'
import CandidateInfo from '../../components/core/CandidateInfo/Candidateinfo'
import { useNavigate } from 'react-router-dom'
import { useGetExaminationDetailsQuery } from '../../app/services/exam-question'
import { Chip } from '@mui/material'

const alpha = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i']

const mcqColumns = [
  {
    label: 'No',
    value: 'no',
    width: '5%',
    render: ({ rowIndex }) => (
      <Typography className="text-left">{rowIndex + 1}</Typography>
    )
  },
  {
    label: 'Question',
    value: 'question',
    width: '67%',
    render: ({ row }) => (
      <div dangerouslySetInnerHTML={{ __html: row.question }}></div>
    )
  },
  {
    label: 'Candidate  Answer',
    value: 'candidateanswer',
    width: '7%',
    render: ({ row }) => (
      <div className="text-center m-auto flex gap-2">
        {!row.skipped ? (
          row.candidateAnswer.map((item, index) =>
            item.selected ? (
              <div
                key={item.sequence}
                className={`flex items-center justify-center h-[24px] ${
                  !item.correct
                    ? 'bg-[#EF4444] text-black'
                    : 'bg-[#22C55E] text-white'
                }  rounded-full w-[24px]`}
              >
                {alpha[item.sequence - 1]}
              </div>
            ) : null
          )
        ) : (
          <div className="text-center m-auto flex">
            <Chip label="Skip" />
          </div>
        )}
      </div>
    )
  },
  {
    label: 'Correct Answer',
    value: 'options',
    width: '15%',
    render: ({ row }) => {
      return (
        <div aria-label="options">
          <div className="flex gap-3">
            {row.candidateAnswer.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`flex items-center justify-center h-[24px] ${
                    !item.correct
                      ? 'bg-[#D9D9D9] text-black'
                      : 'bg-[#22C55E] text-white'
                  }  rounded-full w-[24px]`}
                >
                  {alpha[item.sequence - 1]}
                </div>
              )
            })}
          </div>
        </div>
      )
    }
  }
]

const Item = styled(Paper)(({ theme }) => ({
  margin: '15px 0',
  boxShadow: 'none'
}))

const AnswerSheet = () => {
  const { examinationUid } = useParams()
  const navigate = useNavigate()
  const { data: examinationDetails } = useGetExaminationDetailsQuery({
    id: examinationUid
  })
  const [mcqList, setMcqList] = useState([])
  const [descriptiveQuestionsList, setDescriptiveQuestionsList] = useState([])
  const [isFirstSectionVisible, setIsFirstSectionVisible] = useState(false)
  const [isSecondSectionVisible, setIsSecondSectionVisible] = useState(false)

  const [getResultData, { isFetching }] = useLazyGetAnswerSheetQuery()

  const initialInfo = [
    {
      label: 'Name',
      value: examinationDetails?.examineeName
    },
    {
      label: 'Department',
      value: examinationDetails?.department
    },
    {
      label: 'Enrollment No.',
      value: examinationDetails?.enrollmentNumber
    },
    {
      label: 'Email Id',
      value: examinationDetails?.email
    },
    {
      label: 'Exam Date',
      value: formatDate(examinationDetails?.startTime)
    },
    {
      label: 'Exam Code',
      value: examinationDetails?.examCode
    },
    {
      label: 'Result',
      value: examinationDetails?.result
    },
    {
      label: 'MCQ & SCQ Marks',
      value:
        examinationDetails?.obtainedNonDescriptiveMarks.toFixed(2) +
        '/' +
        examinationDetails?.totalNonDescriptiveMarks
    },
    {
      label: 'Descriptive Marks',
      value:
        examinationDetails?.obtainedDescriptiveMarks +
        '/' +
        examinationDetails?.totalDescriptiveMarks
    },
    {
      label: 'Total Marks',
      value:
        examinationDetails?.obtainMarks.toFixed(2) +
        '/' +
        examinationDetails?.marks
    }
  ]

  const descriptiveQuestionsColumns = [
    {
      label: 'No',
      value: 'no',
      width: '5%',
      render: ({ rowIndex }) => <Typography>{rowIndex + 1}</Typography>
    },
    {
      label: 'Question',
      value: 'question',
      width: '83%',
      render: ({ row }) => (
        <div dangerouslySetInnerHTML={{ __html: row.question }}></div>
      )
    },
    {
      label: 'View Answer',
      value: 'viewanswer',
      width: '12%',
      render: ({ row }) => (
        <button onClick={() => handleViewAnswer(row)}>
          <RemoveRedEyeOutlinedIcon color="error" />
        </button>
      )
    }
  ]

  function formatDate(inputDate) {
    if (!inputDate) {
      return ''
    }
    const date = new Date(inputDate)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${month}/${day}/${year}`
  }

  const getResult = async () => {
    try {
      const { data } = await getResultData({ id: examinationUid })

      setMcqList(data?.nonDescriptive)
      setDescriptiveQuestionsList(data?.descriptive)
      setSectionVisibility(data?.nonDescriptive, data?.descriptive)
    } catch (error) {
      console.log('error', error)
    }
  }

  const setSectionVisibility = (mcqListResp, descriptiveListResp) => {
    for (const item of mcqListResp) {
      if (item.questionDetails.length !== 0) {
        setIsFirstSectionVisible(true)
        break
      }
    }

    for (const item of descriptiveListResp) {
      if (item.questionDetails.length !== 0) {
        setIsSecondSectionVisible(true)
        break
      }
    }
  }

  const handleViewAnswer = (rowData) => {
    navigate(`/result/view-answer/${examinationUid}/${rowData.uid}`)
  }

  useEffect(() => {
    getResult()
  }, [])

  return (
    <div>
      {isFetching ? (
        <Loader />
      ) : (
        <>
          <Typography component="div" className="viewans__box">
            <CandidateInfo candidateInfo={initialInfo} />
          </Typography>
          {/* Accordian-1 MCQ */}
          {isFirstSectionVisible && (
            <Accordion className={Style.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={Style.accordion_summary}
                sx={{ backgroundColor: '#E4E4E7' }}
              >
                <Typography className="p-0 m-0 text-2xl font-medium text-black">
                  Part A - Single/Multiple choice questions
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={Style.accordion_details}>
                {/* table-1 start */}
                {mcqList?.map((item, index) => {
                  return (
                    <div key={index}>
                      <Box className={Style.accordion_box}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={3}>
                            <div className="w-[700px]">
                              <Item className="text-left shadow-none bg-none text-xl font-medium px-[10px] py-0 text-black">
                                {' '}
                                Topic Name :{' '}
                                <span className="text-lg text-grey-500">
                                  {item.topic}
                                </span>
                              </Item>
                            </div>
                          </Grid>
                          <Grid item xs={6} md={8} sx={{ marginLeft: '60px' }}>
                            <Item className="text-left shadow-none bg-none text-xl font-medium px-[10px] py-0 text-black">
                              Marks :{' '}
                              <span className="text-lg text-grey-500">
                                {item.obtainedMarks}/{item.marks}
                              </span>
                            </Item>
                          </Grid>
                        </Grid>
                      </Box>
                      <CustomTable
                        columns={mcqColumns}
                        data={item.questionDetails}
                      />
                    </div>
                  )
                })}
              </AccordionDetails>
            </Accordion>
          )}

          {/* Accordion-2 DescriptiveQuestionsList */}
          {isSecondSectionVisible && (
            <Accordion className={Style.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={Style.accordion_summary}
                sx={{ backgroundColor: '#E4E4E7' }}
              >
                <Typography className="p-0 m-0 text-2xl font-medium text-black">
                  {(isFirstSectionVisible && 'Part B') ||
                    (!isFirstSectionVisible && 'Part A')}{' '}
                  - Descriptive Question
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={Style.accordion_details}>
                {/* table-1 start */}
                {descriptiveQuestionsList?.map((item, index) => {
                  return (
                    Boolean(item.questionDetails.length) && (
                      <div key={index}>
                        <Box className={Style.accordion_box}>
                          <Grid container spacing={2}>
                            <Grid item xs={6} md={3}>
                              <Item className="text-left shadow-none bg-none text-xl font-medium px-[10px] py-0 text-black">
                                {' '}
                                Topic Name :{' '}
                                <span className="text-lg text-grey-500">
                                  {item.topic}
                                </span>
                              </Item>
                            </Grid>
                            <Grid item xs={6} md={8}>
                              <Item className="text-left shadow-none bg-none text-xl font-medium px-[10px] py-0 text-black">
                                Marks :{' '}
                                <span className="text-lg text-grey-500">
                                  {item.obtainedMarks}/{item.marks}
                                </span>
                              </Item>
                            </Grid>
                          </Grid>
                        </Box>
                        <CustomTable
                          columns={descriptiveQuestionsColumns}
                          data={item.questionDetails}
                        />
                      </div>
                    )
                  )
                })}
              </AccordionDetails>
            </Accordion>
          )}
        </>
      )}
    </div>
  )
}

export default AnswerSheet
