import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import NodataFound from '../../layout/NoDataFoundPage/NodataFound'
import Style from './table.module.css'
import { memo } from 'react'

const CustomTable = ({ columns, data, onRowClick }) => {
  return (
    <div className={Style.table_wrap}>
      <TableContainer className={Style.table_container}>
        <Table className={Style.table_style}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  style={{ width: column.width || 'auto' }}
                  key={index}
                  align={column.align || 'inherit'}
                >
                  {column.label}
                  {column.rightIcon}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length > 0 ? (
              data.map((row, rowIndex) => {
                return (
                  <TableRow
                    key={rowIndex}
                    onClick={() => {
                      onRowClick !== undefined && onRowClick(row)
                    }}
                    hover
                    sx={{
                      '&:hover': {
                        backgroundColor: '#F4F4F5',
                        cursor: onRowClick !== undefined ? 'pointer' : 'default'
                      }
                    }}
                  >
                    {columns.map((col, ind) => {
                      return (
                        <CustomCell
                          key={ind}
                          column={col}
                          row={row}
                          rowIndex={rowIndex}
                        />
                      )
                    })}
                  </TableRow>
                )
              })
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        {!data || data.length === 0 ? <NodataFound /> : ''}
      </TableContainer>
    </div>
  )
}

const CustomCell = memo(({ column, row, rowIndex }) => {
  const isImageOrSvg =
    typeof row[column.value] === 'string' ||
    typeof row[column.value] === 'number'
  const textAlign = isImageOrSvg ? 'left' : 'center'

  return (
    <TableCell className="p-0 px-3" style={{ textAlign }}>
      {column.render ? (
        <column.render row={row} rowIndex={rowIndex} />
      ) : (
        row[column.value]
      )}
    </TableCell>
  )
})

export default CustomTable
