import { Outlet } from 'react-router-dom'
import { Box, Container, CssBaseline } from '@mui/material'
import Header from './header/Header'
import Sidebar from './sidebar/Sidebar'
import { useState } from 'react'
import styled from '@emotion/styled'

const Layout = () => {
  const [open, setOpen] = useState(true)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const toggleDrawer = () => {
    setOpen(!open)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header open={open} handleDrawerOpen={handleDrawerOpen} />
      <Sidebar open={open} toggleDrawer={toggleDrawer} />
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, marginTop: '77px', minHeight: '1024px' }}
      >
        <Outlet />
      </Box>
    </Box>
  )
}

export default Layout
