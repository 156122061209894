import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import { validation } from '../../../constants/constants'

export default function AlertMessage(props) {
  const { severity, message, className } = props

  return (
    <Stack
      className={className}
      sx={{
        color: 'blue',
        width: '270px',
        margin: '0 auto',
        cursor: 'pointer',
        border: '1px solid',
        borderColor: severity === validation.SUCCESS ? '#22C55E' : '#EF4444',
        borderRadius: '4px'
      }}
      spacing={2}
    >
      <Alert
        sx={{
          color: severity === validation.SUCCESS ? '#22C55E' : '#EF4444',
          background: severity === validation.SUCCESS ? '#DCFCE7' : '#FEE2E2',
          borderColor: 'black',
          fontSize: '14px',
          fontWeight: '500',
          display: 'flex',
          justifyContent: 'center'
        }}
        severity="success"
        icon={false}
      >
        {message}
      </Alert>
    </Stack>
  )
}
