import { useRef } from 'react'
import { file, validation } from '../../../../src/constants/constants'
import { useLazyImportQuestionsQuery } from '../../../app/services/file'
import FileUploadLogo from '../../../assets/image/fileUpload-logo.svg'
import Style from './fileUpload.module.css'

function FileUpload(props) {
  const { handleFileUploadProcess } = props

  const fileInputRef = useRef(null)
  const [importQuestionsQuery] = useLazyImportQuestionsQuery()

  const handleFileChange = () => {
    const files = Array.from(fileInputRef.current.files)
    const singleFile = files[0]

    // File size validation
    const maxSizeInBytes = file.IMPORT_QUESTION_FILE_SIZE_IN_MB * 1024 * 1024 // 5 MB
    if (singleFile.size > maxSizeInBytes) {
      fileInputRef.current.value = ''
      handleFileUploadProcess('Max file size should be 5 MB', validation.FAIL)
      return
    }

    // File extension validation
    const allowedExtensions = file.IMPORT_QUESTION_FILE_ALLOW_EXTENSIONS
    const fileExtension = singleFile.name.slice(
      ((singleFile.name.lastIndexOf('.') - 1) >>> 0) + 2
    )
    if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
      fileInputRef.current.value = ''
      handleFileUploadProcess(
        'File must be either .xlsx or .csv',
        validation.FAIL
      )
      return
    }

    handleFileUploadProcess(
      'Your file is currently in process',
      validation.SUCCESS
    )

    const formData = new FormData()
    formData.append('file', singleFile)

    importQuestionsQuery(formData)
      .then(() => {
        handleFileUploadProcess(
          'File processed successfully',
          validation.SUCCESS
        )
      })
      .catch((error) => {
        handleFileUploadProcess('Something went wrong', validation.FAIL)
        console.error('Error while importing the file ::: ', error)
      })

    fileInputRef.current.value = ''
  }

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  return (
    <div>
      <div className={Style.fileUploadbox}>
        <p className={Style.fileUploadtext}>
          Upload files and wait for the process to complete. Once processed,
          you’ll be able to see the report of inserted items.
        </p>

        <button
          className={Style.fileUploadvectorblue}
          onClick={handleButtonClick}
        >
          Upload <img src={FileUploadLogo} alt="fileUpload" />
        </button>
      </div>
      <input
        type="file"
        accept=".xlsx, .csv"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </div>
  )
}

export default FileUpload
