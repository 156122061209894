import { useState, useEffect } from 'react'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import './result.module.css'
import {
  useGetResultByFilterQuery,
  useLazyGetResultByFilterQuery,
  useGetExamNamesQuery,
  useLazyExportResultQuery
} from '../../app/services/exam-result'
import { dateFilter, pagination } from '../../constants/constants'
import { accessorKey, header } from '../../constants/columns'
import CustomTable from '../../components/feature/Table/Table'
import { IconButton, Typography } from '@mui/material'
import Select from '../../components/core/Select/Select'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/layout/Loader/Loader'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import PageNumber from '../../components/feature/PageNumber/PageNum'
import Button from '../../components/core/Button/Button'

const months = [
  dateFilter.LAST_YEAR,
  dateFilter.LAST_6_MONTHS,
  dateFilter.LAST_3_MONTHS,
  dateFilter.LAST_MONTH,
  dateFilter.LAST_WEEK,
  dateFilter.TODAY
]

function ResultPage() {
  const navigate = useNavigate()
  const [results, setResults] = useState([])
  const [getResultByFilterQuery] = useLazyGetResultByFilterQuery()
  const { data: examNames } = useGetExamNamesQuery()
  const [exportResultByFilterQuery, , { isLoading: isExporting }] =
    useLazyExportResultQuery()
  const [page, setPage] = useState(0)
  const size = pagination.DEFAULT_PAGE_SIZE
  const [examName, setExamName] = useState(null)
  const [date, setDate] = useState({
    startDate: '',
    endDate: getJavaTime(Date())
  })
  const sort = 'descending'
  const [sortByMarks, setSortByMarks] = useState('')
  const [sortByResultStatus, setSortByResultStatus] = useState('')
  const [totalPages, setTotalPages] = useState(0)

  const handleSortMarks = () => {
    setSortByMarks(sortByMarks === 'ascending' ? 'descending' : 'ascending')
  }
  const handleSortResult = () => {
    setSortByResultStatus(
      sortByResultStatus === 'ascending' ? 'descending' : 'ascending'
    )
  }

  const columns = [
    {
      value: accessorKey.NO,
      label: header.NO,
      width: '5%'
    },
    {
      value: accessorKey.NAME,
      label: header.NAME,
      width: '30%'
    },
    {
      value: accessorKey.ENROLLMENT_NUMBER,
      label: header.ENROLLMENT_NUMBER,
      size: '30%'
    },
    {
      value: accessorKey.EMAIL,
      label: header.EMAIL,
      width: '20%'
    },

    {
      value: accessorKey.EXAM_NAME,
      label: header.EXAM_NAME,
      width: '15%'
    },
    {
      value: accessorKey.EXAM_DATE_STRING,
      label: header.EXAM_DATE,
      width: '8%',
      render: ({ row }) => (
        <>
          <Typography>{formatDate(row.examDate)}</Typography>
        </>
      )
    },

    {
      value: accessorKey.PASSED,
      label: header.RESULT,
      rightIcon: (
        <IconButton className="p-0" onClick={handleSortResult}>
          <UnfoldMoreIcon className="text-black" />
        </IconButton>
      ),
      width: '11%',
      render: ({ row }) => (
        <Typography
          style={{ textAlign: 'left', color: row.passed ? '#09c906' : 'red' }}
        >
          {row.passed ? 'Passed' : 'Failed'}
        </Typography>
      )
    },
    {
      value: accessorKey.MARKS,
      label: header.MARKS,
      width: '11%',
      rightIcon: (
        <IconButton className="p-0" onClick={handleSortMarks}>
          <UnfoldMoreIcon className="text-black" />
        </IconButton>
      ),
      render: ({ row }) => (
        <Typography style={{ textAlign: 'left' }}>
          {row.marks.toFixed(2)}
        </Typography>
      )
    }
  ]

  const { data: resultList, isFetching } = useGetResultByFilterQuery({
    domain: null,
    examCode: null,
    candidateName: null,
    page: 0,
    size: size
  })

  useEffect(() => {
    if (resultList !== undefined) {
      setResults(resultList.data)
      setTotalPages(resultList.totalPages)
    }
  }, [resultList])

  useEffect(() => {
    const body = {
      startDate: date.startDate,
      endDate: date.endDate,
      order: sort.toLowerCase(),
      sortByMarks: sortByMarks,
      sortByStatus: sortByResultStatus,
      examName: examName,
      page: 0,
      size: size
    }
    getResultByFilterQuery(body).then(({ data: resultListByFilter }) => {
      if (resultListByFilter !== undefined) {
        window.scrollTo({ top: 0 })
        setResults(resultListByFilter.data)
        setTotalPages(resultListByFilter.totalPages)
      } else setResults([])
    })
  }, [date, examName])

  useEffect(() => {
    const body = {
      startDate: date.startDate,
      endDate: date.endDate,
      order: sort.toLowerCase(),
      examName: examName,
      sortByMarks: sortByMarks,
      sortByStatus: sortByResultStatus,
      page: page,
      size: size
    }
    getResultByFilterQuery(body).then(({ data: resultListByFilter }) => {
      if (resultListByFilter !== undefined) {
        window.scrollTo({ top: 0 })
        setResults(resultListByFilter.data)
        setTotalPages(resultListByFilter.totalPages)
      } else setResults([])
    })
  }, [sort, sortByMarks, sortByResultStatus, page])

  const handleDateChange = ({ value }) => {
    const startDate = new Date()
    startDate.setHours(0, 0, 0, 0)
    const endDate = new Date()
    switch (value) {
      case dateFilter.LAST_YEAR:
        startDate.setFullYear(startDate.getFullYear() - 1)
        break
      case dateFilter.LAST_6_MONTHS:
        startDate.setMonth(startDate.getMonth() - 6)
        break
      case dateFilter.LAST_3_MONTHS:
        startDate.setMonth(startDate.getMonth() - 3)
        break
      case dateFilter.LAST_MONTH:
        startDate.setMonth(startDate.getMonth() - 1)
        break
      case dateFilter.LAST_WEEK:
        startDate.setDate(startDate.getDate() - 7)
        break
      case dateFilter.TODAY:
        startDate.setDate(startDate.getDate())
        break
      default:
        break
    }
    setDate({
      startDate: getJavaTime(startDate),
      endDate: getJavaTime(endDate)
    })
  }

  const handleExportResult = () => {
    const exportBody = {
      startDate: date.startDate,
      endDate: date.endDate,
      order: sort.toLowerCase(),
      sortByMarks,
      examName: examName,
      sortByStatus: sortByResultStatus
    }

    exportResultByFilterQuery(exportBody)
      .then((response) => {
        const excelData = response.data

        const binaryString = atob(excelData)

        const uint8Array = new Uint8Array(binaryString.length)
        for (let i = 0; i < binaryString.length; i++) {
          uint8Array[i] = binaryString.charCodeAt(i)
        }

        const blob = new Blob([uint8Array], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        const currentDate = new Date()
          .toLocaleDateString('en-GB')
          .split('/')
          .reverse()
          .join('-') // Format: DD-MM-YY
        const currentTime = new Date()
          .toLocaleTimeString('en-US', { hour12: false })
          .replace(/:/g, '-') // Format: HH-mm
        const timestamp = `${currentDate}_${currentTime}`

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)

        link.download = `result-${timestamp}.xlsx`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch((error) => {
        console.error('Export failed!', error)
      })
  }

  function formatDate(inputDate) {
    if (!inputDate) {
      return ''
    }
    const date = new Date(inputDate)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${month}/${day}/${year}`
  }

  function getJavaTime(inputDate) {
    if (!inputDate) {
      return ''
    }
    const date = new Date(inputDate)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()

    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')
    const milli_seconds = date.getMilliseconds().toString().padStart(3, '0')
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milli_seconds}Z`
  }

  const finalResultList = results?.map((resultObj, index) => {
    return { ...resultObj, no: index + 1 }
  })

  const examNamesSelectionList = examNames?.map((examName) => {
    return {
      label: examName,
      value: examName
    }
  })

  const monthSelectionList = months?.map((month) => {
    return {
      label: month,
      value: month
    }
  })

  const onRowClick = (rowData) => {
    navigate(`/result/${rowData.examinationUid}`)
  }

  const handlePageChange = (body) => {
    setPage(body.page)
  }

  return (
    <>
      <Box sx={{ textAlign: 'right', mb: 4 }}>
        <FormControl sx={{ width: 198, justifyContent: 'end' }}>
          <Select
            options={[
              { value: '', label: 'All' },
              ...(examNamesSelectionList ?? [])
            ]}
            onChange={({ value }) => setExamName(value)}
            placeholder="Select Exam Name"
          />
        </FormControl>
        <FormControl sx={{ ml: 2, width: 162, justifyContent: 'end' }}>
          <Select
            options={monthSelectionList}
            onChange={(value) => handleDateChange(value)}
            placeholder="Select Date"
          />
        </FormControl>
        <FormControl sx={{ ml: 2, justifyContent: 'end' }}>
          <div>
            <Button onClick={handleExportResult} disabled={isExporting}>
              {isExporting ? 'Exporting...' : 'Export Result'}
            </Button>
          </div>
        </FormControl>
      </Box>
      <div className="results">
        {isFetching ? (
          <Loader />
        ) : (
          <>
            <CustomTable
              columns={columns}
              data={finalResultList}
              onRowClick={onRowClick}
            />
            <PageNumber count={totalPages} onChange={handlePageChange} />
          </>
        )}
      </div>
    </>
  )
}

export default ResultPage
