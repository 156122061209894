import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Typography } from '@mui/material'
import clsx from 'clsx'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAcceptInstructionsMutation } from '../../app/services/exam-result'
import { questionActions } from '../../app/store/question'
import hand from '../../assets/icon/hand.svg'
import InstructionImg from '../../assets/image/Question paper.jpg'
import examinstruction from '../../assets/image/examinstructionimg-1.jpg'
import logo from '../../assets/image/sidebar-logo.svg'
import DynamicButton from '../../components/core/DynamicButton/DynamicButton'
import { labels } from '../../constants/constants'
import Style from './examinstruction.module.css'

function Examinstruction() {
  const location = useLocation()
  const [checked, setChecked] = useState(false)
  const [disabled, setdisabled] = useState(true)
  const [message, setMessage] = useState('')
  const [instruction] = useAcceptInstructionsMutation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleChange = (event) => {
    setChecked(event.target.checked)
    if (event.target.checked === true) {
      setdisabled(false)
    } else {
      setdisabled(true)
    }
  }

  function handleStart() {
    const body = setBody()
    instruction(body).then(({ data }) => {
      if (data) {
        setMessage(data.resultMessage)
        dispatch(
          questionActions.setExamStarted({
            examStarted: true
          })
        )
        navigate('/user/exam-start', { replace: true })
        document.body.requestFullscreen()
      } else {
        setMessage(data?.resultMessage)
      }
    })
  }

  function setBody() {
    return {
      uid: localStorage.getItem('examineeUid'),
      agree: labels.AGREED
    }
  }
  return (
    <div className={Style.examinstruction}>
      <div className={Style.examinstruction__wrapper}>
        <div className={Style.examinstruction__left}>
          <div className={Style.examinstruction__logo}>
            <img src={logo} alt="logo" />
          </div>
          <div className={Style.instruction__wrapper}>
            <div className={Style.examinstruction__head}>
              <Typography
                component="h3"
                className={Style.examinstruction__title}
              >
                Hi, {location.state?.examineeName}{' '}
                <img src={hand} alt="hand"></img>
              </Typography>
              <p className={Style.examinstruction__text}>
                We're thrilled to have you here! Please carefully read the below
                instruction.
              </p>
            </div>
            <div className={Style.examinstruction__details}>
              <Typography component="h6" className={Style.details__title}>
                {location.state?.duration}
                {' Minutes ('}
                {location.state?.questionCount}
                {' Questions) :'}
              </Typography>
              <Typography component="p" className={Style.details__text}>
                You will need to complete it in one sitting. Once you start, you
                will not be able to pause/restart.
              </Typography>
            </div>
            {location.state?.negativeScoringEnabled && (
              <div className={Style.examinstruction__details}>
                <Typography component="h6" className={Style.details__title}>
                  Negative Marking :
                </Typography>
                <Typography component="p" className={Style.details__text}>
                  There will be negative marking of 0.5 marks for MCQ and Single
                  choice questions. There won’t be negative marking for
                  descriptive questions.
                </Typography>
              </div>
            )}
            <div className={Style.examinstruction__details}>
              <Typography component="h6" className={Style.details__title}>
                Use Chrome on a desktop :
              </Typography>
              <Typography component="p" className={Style.details__text}>
                For a comfortable and distraction-free experience, we recommend
                using a desktop computer/laptop. Please do not use a mobile
                phone to complete this task.
              </Typography>
            </div>
            <div className={Style.examinstruction__details}>
              <Typography component="h6" className={Style.details__title}>
                Keep pen and paper handy :
              </Typography>
              <Typography component="p" className={Style.details__text}>
                You might want to use them for scratch work.
              </Typography>
            </div>
            <div className={Style.examinstruction__details}>
              <Typography component="h6" className={Style.details__title}>
                Don’t leave the window :
              </Typography>
              <Typography component="p" className={Style.details__text}>
                Close all other tabs before you begin and keep your mouse focus
                on the exam screen for the entire duration of the task.
              </Typography>
              <div className="mt-3">
                <img src={InstructionImg} alt="instruction"></img>
              </div>
            </div>
          </div>
          <div className={Style.examinstruction__privacy}>
            <div className={Style.form_roup}>
              <input
                type="checkbox"
                id={'checkbox_'}
                onChange={handleChange}
                checked={checked}
              />
              <label htmlFor={'checkbox_'}></label>
            </div>
            <p className={clsx('opacity-50', checked ? '!opacity-100' : '')}>
              I have read all the exam instructions carefully.
            </p>
          </div>
          <div className={Style.examinstruction__action}>
            <DynamicButton
              className={Style.examinstruction__btn}
              width="500px"
              disabled={disabled}
              onClick={handleStart}
            >
              Get Started
              <ArrowForwardIcon sx={{ height: '20px', Margin: '0 0 0 8px' }} />
            </DynamicButton>
          </div>
          <Typography>{message}</Typography>
        </div>
        <div className={Style.examinstruction__right}>
          <div className={Style.examinstruction__image}>
            <img src={examinstruction} alt="examinstruction" />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Examinstruction
