import * as React from 'react'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { Alert, IconButton, Snackbar, Typography } from '@mui/material'
import {
  useDeleteDomainMutation,
  useGetDomainsByFilterQuery,
  useLazyGetDomainsByFilterQuery
} from '../../app/services/domain'
import { accessorKey, header } from '../../constants/columns'
import CustomTable from '../../components/feature/Table/Table'
import Modal from '../../components/layout/Modal/Modal'
import Loader from '../../components/layout/Loader/Loader'
import StyleFile from './department.module.css'
import PageNumber from '../../components/feature/PageNumber/PageNum'
import { pagination } from '../../constants/constants'

function DepartmentListing() {
  const [deleteDomain] = useDeleteDomainMutation()
  const [getDomainsByFilter] = useLazyGetDomainsByFilterQuery()
  const { data: deptResponse, isFetching } = useGetDomainsByFilterQuery()
  const [open, setOpen] = React.useState(false)
  const [id, setId] = React.useState()
  const [deptList, setDeptList] = React.useState()
  const [totalPages, setTotalPages] = React.useState(0)
  const [openSnackbar, setOpenSnackbar] = React.useState(false)
  const [snackbarMessage, setSnackbarMessage] = React.useState('')

  let defaultPageSize = pagination.DEFAULT_PAGE_SIZE

  React.useEffect(() => {
    if (deptResponse !== undefined) {
      createAndSetDepartments(deptResponse.data)
      setTotalPages(deptResponse.totalPages)
    }
  }, [deptResponse])

  const columns = [
    {
      value: accessorKey.NO,
      label: header.NO,
      width: '5%'
    },
    {
      value: accessorKey.NAME,
      label: header.DEPARTMENT
    },
    {
      label: header.ACTION,
      value: accessorKey.ACTION,
      align: 'center',
      width: '5%',
      render: ({ row }) => (
        <>
          <IconButton
            aria-label="delete"
            onClick={() => {
              setId(row.uid)
              setOpen(true)
            }}
          >
            <DeleteOutlineOutlinedIcon color="error" />
          </IconButton>
        </>
      )
    }
  ]

  const createAndSetDepartments = (listData) => {
    let data = listData.map((department, index) => {
      return {
        no: index + 1,
        name: department.name,
        uid: department.uid
      }
    })
    window.scrollTo({ top: 0 })
    setDeptList(data)
  }

  const handlePageChange = (body) => {
    let payload = { ...body, size: defaultPageSize }

    getDomainsByFilter(payload).then(({ data: departmentListByFilter }) => {
      if (departmentListByFilter === undefined) setDeptList([])
      else {
        setTotalPages(departmentListByFilter.totalPages)
        createAndSetDepartments(departmentListByFilter.data)
      }
    })
  }

  const handleDelete = () => {
    deleteDomain({ id: id }).then(({ data, error }) => {
      if (data) setOpen(false)
      else if (error) {
        setSnackbarMessage(
          'The department cannot be deleted as it has associated topics.'
        )
        setOpenSnackbar(true)
      }
    })
  }

  return (
    <div className="departmentpage">
      {isFetching ? (
        <Loader />
      ) : (
        <CustomTable columns={columns} data={deptList} />
      )}

      <Modal
        open={open}
        title="Delete Department"
        isDeleteConfirmationPopup={true}
        setOpen={setOpen}
        onSubmit={handleDelete}
      >
        <Typography className={StyleFile.delete__text}>
          Are you sure you want to delete Department?
        </Typography>
      </Modal>
      <PageNumber count={totalPages} onChange={handlePageChange} />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert severity="error">{snackbarMessage}</Alert>
      </Snackbar>
    </div>
  )
}

export default DepartmentListing
