import * as React from 'react'
import Style from './modal.module.css'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ModalMaterial from '@mui/material/Modal'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Button from '../../core/Button/Button'
import Backdrop from '@mui/material/Backdrop'

const Modal = (props) => {
  const {
    open,
    setOpen,
    title,
    children,
    isDeleteConfirmationPopup,
    isSubmitConfirmationPopup,
    onSubmit,
    onClose,
    customWidth
  } = props

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: customWidth ? customWidth : 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24
  }

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    setOpen(false)
  }

  return (
    <>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <ModalMaterial
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-modal-description"
          hideBackdrop
        >
          <Box sx={style} className={Style.modal__box}>
            <Typography component="div" className={Style.box__top}>
              <Typography
                id="modal-title"
                className={Style.box__title}
                variant="h6"
                component="h2"
              >
                {title}
              </Typography>
              <IconButton onClick={handleClose} aria-label="close">
                <CloseIcon fontSize="small" />
              </IconButton>
            </Typography>
            <Typography component="div" className={Style.box__bottom}>
              <Typography
                component="div"
                sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}
              >
                {children}
              </Typography>
              <Typography component="div" className={Style.modal__action}>
                <Button color="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  color={isDeleteConfirmationPopup ? 'error' : 'primary'}
                  onClick={onSubmit}
                >
                  {isDeleteConfirmationPopup
                    ? 'Delete'
                    : isSubmitConfirmationPopup
                    ? 'submit'
                    : 'Add'}
                </Button>
              </Typography>
            </Typography>
          </Box>
        </ModalMaterial>
      </Backdrop>
    </>
  )
}
export default Modal
