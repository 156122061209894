import ChipMaterial from '@mui/material/Chip'
import Style from './chip.module.css'

const colors = {
  warning: '#FDECDA',
  primary: '#CBE9FE'
}

const textColor = {
  warning: '#EA6A47',
  primary: '#006AFE'
}

export default function Chip(props) {
  const { label, color = 'primary' } = props

  const chipStyle = {
    backgroundColor: colors[color],
    color: textColor[color]
  }

  return (
    <ChipMaterial
      className={Style.chip__design}
      style={chipStyle}
      label={label}
    />
  )
}
