import React from 'react'
import { useNavigate } from 'react-router'
import { useViewExamQuery } from '../../app/services/exam'
import Button from '../../components/core/Button/Button'
import CustomTable from '../../components/feature/Table/Table'
import CandidateInfo from '../../components/layout/CandidateInfo/CandidateInfo'
import { accessorKey, header } from '../../constants/columns'
import { labels } from '../../constants/constants'

const columns = [
  {
    label: header.NO,
    value: accessorKey.NO,
    width: '5%',
    render: ({ rowIndex }) => {
      return rowIndex + 1
    }
  },
  {
    label: header.QUESTION,
    value: accessorKey.QUESTION,
    width: '80%',
    render: ({ row }) => {
      return <div dangerouslySetInnerHTML={{ __html: row.question }}></div>
    }
  },
  {
    label: header.QUESTION_TYPE,
    value: accessorKey.TYPE,
    width: '15%',
    render: ({ row }) => {
      return labels[row.type]
    }
  }
]

function ExamPreviewPage(props) {
  const { examId } = props
  const navigate = useNavigate()
  const { data: examData } = useViewExamQuery({
    id: examId,
    fetchPolicy: 'network-only' // Bypass the cache
  })
  if (!examData) {
    return <></>
  }

  const candidateData = [
    {
      label: header.NAME,
      value: examData.name
    },
    {
      label: header.DEPARTMENT,
      value: examData.domainName
    },
    {
      label: header.DURATION,
      value: examData.duration + ' Minutes'
    },
    {
      label: header.TOTAL_MARKS,
      value: examData.totalMarks
    },
    {
      label: header.PASS_MARKS,
      value: examData.passMarks
    },
    {
      label: header.TOTAL_QUESTION,
      value: examData.question.length
    }
  ]

  return (
    <div>
      <div className="mb-10">
        <CandidateInfo candidateInfo={candidateData} />
      </div>
      <div>
        <CustomTable columns={columns} data={examData.question} />
      </div>
      <div className="flex justify-end">
        <Button
          className="pl-8 pr-8 mt-10"
          onClick={() => navigate('/exam', { replace: true })}
        >
          Finish
        </Button>
      </div>
    </div>
  )
}

export default ExamPreviewPage
