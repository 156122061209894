import * as React from 'react'
import Style from './table.module.css'
import Typography from '@mui/material/Typography'
import { useGetDomainsQuery } from '../../app/services/domain'
import { useAddTopicMutation } from '../../app/services/topic'
import Modal from '../../components/layout/Modal/Modal'
import Select from '../../components/core/Select/Select'
import TextField from '../../components/core/TextField/TextField'
import { Snackbar } from '@mui/base'
import { Alert } from '@mui/material'

function AddTopic(props) {
  const { open, setOpen } = props
  const { data: departmentList } = useGetDomainsQuery()
  const [department, setDepartment] = React.useState('')
  const [topicName, setTopicName] = React.useState('')
  const [addTopicMutation] = useAddTopicMutation()
  const [deptErrorMessage, setDeptErrorMessage] = React.useState()
  const [topicErrorMessage, setTopicErrorMessage] = React.useState()

  const [openSnackbar, setOpenSnackbar] = React.useState(false)
  const [errorMessage, setErroMessage] = React.useState('')
  const handleTopicChange = (value) => {
    setTopicName(value)
    setTopicErrorMessage()
  }

  const handleDepartmentChange = (value) => {
    setDepartment(value.value)
    setDeptErrorMessage()
  }

  const addButtonOnAction = () => {
    if (!department || !topicName) {
      if (!department) setDeptErrorMessage('Please select department')
      if (!topicName) setTopicErrorMessage('Please enter topic')

      return
    }

    var domain = departmentList.find((item) => item.uid === department)

    addTopicMutation({ domain, name: topicName }).then(({ data, error }) => {
      if (data) {
        setOpen(false)
      } else if (error) {
        setOpenSnackbar(true)
        setErroMessage(error.data.resultMessage)
      }
    })
  }

  const handleClose = () => {
    setDeptErrorMessage()
    setTopicErrorMessage()
    setDepartment()
    setTopicName()
    setOpen(false)
  }

  const departments = departmentList?.map((department) => {
    return {
      label: department.name,
      value: department.uid
    }
  })

  const handleOnKeyUp = (event) => {
    if (event.key === 'Enter') {
      addButtonOnAction()
    }
  }

  return (
    <>
      <Modal
        open={open}
        title="Add Topic"
        setOpen={setOpen}
        onClose={handleClose}
        onSubmit={addButtonOnAction}
      >
        <div className="w-full">
          <Select
            options={departments}
            placeholder="Select Department"
            onChange={handleDepartmentChange}
            isError={deptErrorMessage ? true : false}
          />
        </div>
        <Typography variant="h6" className="w-1/2">
          <TextField
            placeholder="Enter Topic Name"
            onChange={handleTopicChange}
            isError={topicErrorMessage ? true : false}
            onKeyUp={handleOnKeyUp}
          />
          {topicErrorMessage && (
            <p className={Style.error_text}>{topicErrorMessage}</p>
          )}
        </Typography>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
      </Modal>
    </>
  )
}
export default AddTopic
