import { api } from './api'

const examEndpoint = 'exam'

const examApi = api.injectEndpoints({
  endpoints: (build) => ({
    getExamByFilter: build.query({
      query: (body) => ({
        url: `${examEndpoint}/filter`,
        method: 'POST',
        body: body || { domain: '', page: 0, size: 25 }
      }),
      transformResponse: (response) => ({
        data: response.data,
        totalItems: response.totalItems,
        totalPages: response.totalPages,
        currentPage: response.currentPage
      }),
      transformErrorResponse: (response) => response.data,
      providesTags: ['Exam']
    }),
    deleteExam: build.mutation({
      query: ({ id }) => ({
        url: `${examEndpoint}/${id}`,
        method: 'PATCH'
      }),
      invalidatesTags: ['Exam', 'Exam-Summery']
    }),
    addExam: build.mutation({
      query: (body) => ({
        url: examEndpoint,
        method: 'POST',
        body
      }),
      transformResponse: (response) => response,
      invalidatesTags: ['Exam', 'Exam-Summery']
    }),
    editExam: build.mutation({
      query: (body) => ({
        url: examEndpoint,
        method: 'PUT',
        body
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: ['Exam']
    }),
    getExamById: build.query({
      query: ({ id }) => `${examEndpoint}/${id}`,
      transformResponse: (response) => response.data,
      providesTags: ['Exam']
    }),
    viewExam: build.query({
      query: ({ id }) => `${examEndpoint}/view/${id}`,
      transformResponse: (response) => response.data,
      providesTags: ['Exam']
    }),
    getExamSummary: build.query({
      query: () => `dashboard/exam-summary`,
      transformResponse: (response) => response.data,
      providesTags: ['Exam-Summary']
    }),
    getExamComplexityLevelOptions: build.query({
      query: () => `exam-complexity`,
      transformResponse: (response) => response.data,
      providesTags: ['Exam-Complexity']
    })
  })
})

export const {
  useGetExamByFilterQuery,
  useLazyGetExamByFilterQuery,
  useDeleteExamMutation,
  useGetExamByIdQuery,
  useLazyGetExamByIdQuery,
  useAddExamMutation,
  useEditExamMutation,
  useLazyViewExamQuery,
  useViewExamQuery,
  useGetExamSummaryQuery,
  useGetExamComplexityLevelOptionsQuery
} = examApi
