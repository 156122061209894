import { api } from './api'

const resultEndpoint = 'result'

const examResultApi = api.injectEndpoints({
  endpoints: (build) => ({
    addExamUser: build.mutation({
      query: (body) => ({
        url: `examinee/register`,
        method: 'POST',
        body
      }),
      transformResponse: (response) => response.data
    }),
    acceptInstructions: build.mutation({
      query: (body) => ({
        url: `examinee/agree?uid=${body.uid}&agree=${body.agree}`,
        method: 'PUT'
      }),
      transformResponse: (response) => response
    }),
    saveAnswer: build.mutation({
      query: (body) => ({
        url: resultEndpoint,
        method: 'PUT',
        body
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['Exam-concluded', 'Month-Wise-Data']
    }),
    getResult: build.query({
      query: ({ id }) => `${resultEndpoint}/${id}`,
      transformResponse: (response) => response.data,
      providesTags: ['Result']
    }),
    getResultByFilter: build.query({
      query: (body) => ({
        url: `${resultEndpoint}/filter`,
        method: 'POST',
        body: body || { page: 0, size: 25 }
      }),
      transformResponse: (response) => ({
        data: response.data,
        totalItems: response.totalItems,
        totalPages: response.totalPages,
        currentPage: response.currentPage
      }),
      transformErrorResponse: (response) => response.data,
      providesTags: ['Result']
    }),
    getSuccessRatioSummary: build.query({
      query: () => `dashboard/success-ratio`,
      transformResponse: (response) => response.data,
      providesTags: ['SuccessRatio-Summery']
    }),
    getMonthWiseDetails: build.query({
      query: () => `dashboard/monthly-wise-details`,
      transformResponse: (response) => response.data,
      providesTags: ['Month-Wise-Data']
    }),
    getExamConcluded: build.query({
      query: () => `dashboard/exam-concluded`,
      transformResponse: (response) => response.data,
      providesTags: ['Exam-concluded']
    }),
    getAnswerSheet: build.query({
      query: ({ id }) => `/examination/answer-sheet/${id}`,
      transformResponse: (response) => response.data,
      providesTags: ['Answer-Sheet']
    }),
    getViewAnswer: build.query({
      // query: () => `${resultEndpoint}/answer-sheet`,
      query: ({ id }) => `/examination/question/descriptive/${id}`,
      transformResponse: (response) => response.data,
      providesTags: ['view-answer']
    }),
    getExamNames: build.query({
      query: () => '/examination/names',
      transformResponse: (response) => response.data,
      providesTags: ['Exam-Names']
    }),
    exportResult: build.query({
      query: (body) => ({
        url: `${resultEndpoint}/export`,
        method: 'POST',
        body
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
      providesTags: ['Export-Result']
    })
  })
})

export const {
  useAddExamUserMutation,
  useAcceptInstructionsMutation,
  useSaveAnswerMutation,
  useLazyGetResultQuery,
  useGetResultByFilterQuery,
  useLazyGetResultByFilterQuery,
  useGetSuccessRatioSummaryQuery,
  useGetMonthWiseDetailsQuery,
  useGetExamConcludedQuery,
  useLazyGetAnswerSheetQuery,
  useLazyGetViewAnswerQuery,
  useGetViewAnswerQuery,
  useGetExamNamesQuery,
  useLazyExportResultQuery
} = examResultApi
