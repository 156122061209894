export const labels = {
  DOMAIN_NAME: 'Domain',
  TOPIC_NAME: 'Topic',
  ADD_DOMAIN: 'Add Domain',
  ADD_TOPIC: 'Add Topic',
  QUESTION: 'Question',
  QUESTION_TYPE: 'Question Type',
  EXAM_NAME: 'Name',
  EXAM_DURATION: 'Duration',
  TOTAL_MARKS: 'TotalMarks',
  PASS_MARKS: 'PassMarks',
  DESCRIPTION: 'Description',
  FILTER: 'filter',
  MARKS: 'Marks',
  OPTION: 'Option',
  COMPLEXITY: 'Complexity',
  TYPE: 'Type',
  AGREED: true,
  AGREE: 'agree',
  IN_MINUTES: 'In Minutes',
  EXAM_CODE: 'Exam-Code',
  CANDIDATE_NAME: 'Candidate Name',
  SINGLE_CHOICE: 'Single Selection',
  MULTIPLE_CHOICE: 'Multiple Selection',
  DESCRIPTIVE: 'Descriptive',
  ENALBE_NEGATIVE_MARKS: 'Enable Negative Mark',
  NEGATIVE_MARKS: 'Negative Mark',
  EASY: 'Easy',
  MEDIUM: 'Medium',
  HARD: 'Hard'
}

export const complexity = {
  EASY: 'EASY',
  MEDIUM: 'MEDIUM',
  HARD: 'HARD'
}

export const DOMAIN_RGEX = /^[a-zA-Z\s]*$/

export const questionType = {
  SINGLE_CHOICE: 'SINGLE_CHOICE',
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  DESCRIPTIVE: 'DESCRIPTIVE'
}

export const dateFilter = {
  LAST_YEAR: 'Last Year',
  LAST_6_MONTHS: 'Last 6 Months',
  LAST_3_MONTHS: 'Last 3 Months',
  LAST_MONTH: 'Last Month',
  LAST_WEEK: 'Last Week',
  TODAY: 'Today'
}

export const statusCode = { API_SUCCESS_CODE: 1001, NO_EXAM_DATA_FOUND: 6002 }

export const validation = {
  SUCCESS: 'success',
  FAIL: 'fail',
  REQUIRED_FIELDS_MSG: 'Please fill the mandatory fields'
}

export const pagination = { DEFAULT_PAGE_SIZE: 25 }

export const file = {
  IMPORT_QUESTION_FILE_SIZE_IN_MB: 5,
  IMPORT_QUESTION_FILE_ALLOW_EXTENSIONS: ['xlsx', 'csv']
}
