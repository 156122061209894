import { Navigate, Outlet, useRoutes } from 'react-router-dom'
import Login from '../../pages/Login/Login'
import PageNotFound from '../../pages/Error/PageNotFound'
import Dashboard from '../../pages/Dashboard/Dashboard'
import Invitation from '../../pages/UserExam/Invitation'
import Instruction from '../../pages/UserExam/Instruction'
import UserLayout from '../../pages/UserExam/UserExamLayout'
import ExamCompleted from '../../pages/UserExam/ExamCompleted'
import ExamAddEdit from '../../pages/Exam/ExamAddEdit'
import Topicpage from '../../pages/Topic/TopicPage'
import DepartmentPage from '../../pages/Department/DepartmentPage'
import ResultPage from '../../pages/Result/ResultPage'
import Layout from '../layout/Layout'
import ExamList from '../../pages/Exam/ExamPage'
import QuestionList from '../../pages/Question/QuestionListing'
import QuestionAddEdit from '../../pages/Question/QuestionAddEdit'
import AnswerSheet from '../../pages/Result/AnswerSheet'
import ViewAnswer from '../../pages/Result/ViewAnswer'
import PaperCheckerListing from '../../pages/PaperChecker/PageCheckerListing'
import PaperChecker from '../../pages/PaperChecker/PaperChecker'

const AppRouter = () => {
  const isLoggedIn = localStorage.getItem('accessToken')

  const routes = [
    {
      path: '/',
      element: isLoggedIn ? <Layout /> : <Navigate to="/login" />,
      children: [
        { path: '', element: <Dashboard /> },
        { path: 'department', element: <DepartmentPage /> },
        { path: 'topic', element: <Topicpage /> },
        {
          path: 'question',
          element: <Outlet />,
          children: [
            { path: '', element: <QuestionList /> },
            { path: 'add', element: <QuestionAddEdit /> },
            { path: ':questionId', element: <QuestionAddEdit /> }
          ]
        },
        {
          path: 'exam',
          element: <Outlet />,
          children: [
            { path: '', element: <ExamList /> },
            { path: 'add', element: <ExamAddEdit /> },
            { path: ':examId', element: <ExamAddEdit /> }
          ]
        },
        {
          path: 'result',
          element: <Outlet />,
          children: [
            { path: '', element: <ResultPage /> },
            { path: ':examinationUid', element: <AnswerSheet /> },
            {
              path: 'view-answer/:examinationUid/:questionUid',
              element: <ViewAnswer />
            }
          ]
        },
        {
          path: 'paper-checker',
          element: <Outlet />,
          children: [
            { path: '', element: <PaperCheckerListing /> },
            { path: ':examinationUid', element: <PaperChecker /> }
          ]
        }
      ]
    },
    {
      path: '/login',
      element: isLoggedIn ? <Navigate to="/" /> : <Login />
    },
    {
      path: '/*',
      element: <PageNotFound />
    },
    {
      path: 'user',
      element: <Outlet />,
      children: [
        { path: '', element: <Navigate to="/user/registration" /> },
        { path: 'registration', element: <Invitation /> },
        { path: 'exam-instruction', element: <Instruction /> },
        { path: 'submit', element: <ExamCompleted /> },
        { path: 'exam-start', element: <UserLayout /> }
      ]
    }
  ]
  const router = useRoutes(routes)

  return <>{router}</>
}

export default AppRouter
