import Button from '@mui/material/Button'

const colors = {
  error: '#EF4444',
  primary: '#006AFE',
  secondary: '#F4F4F5'
}
const DynamicButton = (props) => {
  const {
    children,
    color = 'primary',
    onClick,
    className,
    type,
    disabled = false
  } = props

  const buttonStyle = {
    backgroundColor: colors[color],
    color: color === 'secondary' ? 'gray' : 'white',
    textTransform: 'capitalize',
    height: '40px',
    boxShadow: 'none',
    opacity: disabled ? 0.5 : 1
  }

  return (
    <Button
      className={className}
      style={buttonStyle}
      onClick={onClick}
      variant="contained"
      type={type}
      disabled={disabled}
    >
      {children}
    </Button>
  )
}

export default DynamicButton
