import { configureStore } from '@reduxjs/toolkit'
import { api } from '../services/api'
import notificationSlice from './notification'
import questionSlice from './question'
import navigationSlice from './navigation'

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [notificationSlice.name]: notificationSlice.reducer,
    [questionSlice.name]: questionSlice.reducer,
    [navigationSlice.name]: navigationSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware)
})

export default store
