import { createSlice } from '@reduxjs/toolkit'

const initialNotificationState = {
  open: false,
  type: 'info',
  message: '',
  timeout: 3000,
  anchor: {
    vertical: 'top',
    horizontal: 'right'
  }
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState: initialNotificationState,
  reducers: {
    openSnackbar: (state, action) => ({
      ...initialNotificationState,
      ...action.payload,
      open: true
    }),
    closeSnackbar: () => ({
      ...initialNotificationState
    })
  }
})

export const notificationActions = notificationSlice.actions

export default notificationSlice
