import FormGroup from '@mui/material/FormGroup'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useGetDomainsQuery } from '../../app/services/domain'
import {
  useAddExamMutation,
  useEditExamMutation,
  useGetExamComplexityLevelOptionsQuery,
  useLazyGetExamByIdQuery
} from '../../app/services/exam'
import Button from '../../components/core/Button/Button'
import Select from '../../components/core/Select/Select'
import TextArea from '../../components/core/TextArea/TextArea'
import TextField from '../../components/core/TextField/TextField'
import { validation } from '../../constants/constants'
import Style from './exams.module.css'

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 43,
  height: 23.5,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 17
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#006afe',
        borderRadius: 32 / 2
      }
    }
  },
  '& .MuiSwitch-track': {
    borderRadius: 11,
    height: 22.5,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 18.5,
    height: 18.5,
    borderRadius: 9.25,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  }
}))

const initialExamData = {
  name: '',
  description: '',
  duration: '',
  totalMarks: '',
  passMarks: '',
  negativeScoringEnabled: false,
  deductionMarks: ''
}

function ExamDetailsPage(props) {
  const { examId } = useParams()
  const { data: domains } = useGetDomainsQuery()
  const [getExamById] = useLazyGetExamByIdQuery()
  const { data: complexityLevelOptionsData } =
    useGetExamComplexityLevelOptionsQuery()
  const [complexityLevelOptions, setComplexityLevelOptions] = useState([])
  const [addExam] = useAddExamMutation()
  const [editExam] = useEditExamMutation()
  const [examData, setExamData] = useState(initialExamData)

  const { setExamId, setActiveStep, selectedDomain, setSelectedDomain } = props

  useEffect(() => {
    if (examId) {
      getExamById({ id: examId }).then(({ data }) => {
        if (data) {
          setExamData({
            name: data.name,
            description: data.description,
            duration: data.duration,
            totalMarks: data.totalMarks,
            passMarks: data.passMarks,
            negativeScoringEnabled: data.negativeScoringEnabled,
            deductionMarks: data.deductionMarks,
            complexityLevel: data.complexityLevel,
            active: data.active
          })
          setSelectedDomain(data.domain)
        }
      })
    }
  }, [examId, getExamById])

  useEffect(
    function () {
      setComplexityLevelOptions(
        complexityLevelOptionsData?.map((level) => ({
          value: level.id,
          label: level.name
        })) || []
      )
    },
    [complexityLevelOptionsData]
  )

  const handleNext = () => {
    if (
      !examData.name ||
      !selectedDomain ||
      !examData.duration ||
      !examData.totalMarks ||
      !examData.passMarks
    ) {
      props.setAlertSeverity(validation.FAIL)
      props.setAlertMessage(validation.REQUIRED_FIELDS_MSG)
      return
    }

    if (parseInt(examData.passMarks) > parseInt(examData.totalMarks)) {
      props.setAlertSeverity(validation.FAIL)
      props.setAlertMessage('Passing Marks cannot be greater than Total Marks')
      return
    }

    const body = {
      ...examData,
      domain: { uid: selectedDomain.uid }
    }

    if (examId) {
      editExam({ ...body, uid: examId }).then(({ data, error }) => {
        if (data) {
          successAction(data)
        } else if (error) {
          failureAction(error)
        }
      })
    } else {
      addExam(body).then(({ data, error }) => {
        if (data) {
          successAction(data)
        } else if (error) {
          failureAction(error)
        }
      })
    }

    const successAction = (data) => {
      props.setAlertMessage()
      setExamId(data.data.uid)
      setActiveStep((prevStep) => prevStep + 1)
    }

    const failureAction = (error) => {
      props.setAlertSeverity(validation.FAIL)
      props.setAlertMessage(error.data.resultMessage)
    }
  }


  return (
    <>
      {(examId !== undefined && examData.name === '') ? "" : (<div className={Style.main_div}>
        <div className="text-grey-400 mb-[18px]">
          <TextField
            inputClassName="border border-gray-400"
            label="Name*"
            value={examData.name}
            onChange={(value) => setExamData({ ...examData, name: value })}
          />
        </div>
        <div className="flex gap-4 mb-[18px]">
          <Select
            className="bg-gray-50 border !border-gray-400"
            options={
              domains
                ? domains.map((domain) => ({
                  value: domain.uid,
                  label: domain.name
                }))
                : ''
            }
            value={
              selectedDomain
                ? {
                  value: selectedDomain.uid,
                  label: selectedDomain.name
                }
                : ''
            }
            label={'Department*'}
            onChange={(value) =>
              setSelectedDomain({ uid: value.value, name: value.label })
            }
          />
          <Select
            className="bg-gray-50 border !border-gray-400"
            options={complexityLevelOptions}
            label="Complexity Level"
            onChange={({ value }) =>
              setExamData({ ...examData, complexityLevel: value })
            }
            value={complexityLevelOptions.find(
              (option) => option.value === examData.complexityLevel
            )}
          />
        </div>
        <div className="mb-[18px]">
          <div className="flex gap-4 text-grey-400">
            <div className="grow">
              <TextField
                inputClassName="border border-gray-400"
                label="Duration*"
                minValue={1}
                type="number"
                value={examData.duration}
                onChange={(value) =>
                  setExamData({ ...examData, duration: value })
                }
              />
            </div>
            <div className="grow">
              <TextField
                inputClassName="border border-gray-400"
                label="Total Marks*"
                minValue={1}
                type="number"
                value={examData.totalMarks}
                onChange={(value) =>
                  setExamData({ ...examData, totalMarks: value })
                }
              />
            </div>
            <div className="grow">
              <TextField
                inputClassName="border border-gray-400"
                label="Pass Marks*"
                minValue={1}
                type="number"
                value={examData.passMarks}
                onChange={(value) =>
                  setExamData({ ...examData, passMarks: value })
                }
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-[18px] text-grey-400">
          <span>Negative Marking</span>
          <div className="flex items-center">
            <FormGroup>
              <Stack direction="row" spacing={1} alignItems="center">
                <AntSwitch
                  checked={examData.negativeScoringEnabled}
                  onChange={(event) =>
                    setExamData({
                      ...examData,
                      negativeScoringEnabled: event.target.checked
                    })
                  }
                  inputProps={{ 'aria-label': 'ant design' }}
                />
              </Stack>
            </FormGroup>
          </div>
        </div>
        <div className="mb-[18px]">
          <TextField
            inputClassName="border border-gray-400"
            placeholder="Enter your negative mark here....."
            disabled={!examData.negativeScoringEnabled}
            value={examData.deductionMarks}
            onChange={(value) =>
              setExamData({ ...examData, deductionMarks: value })
            }
          />
        </div>
        {examId && (
          <div className="flex justify-between mb-[18px] text-grey-400">
            <span>Exam Status</span>
            <div className="flex items-center">
              <FormGroup>
                <Stack direction="row" spacing={1} alignItems="center">
                  <AntSwitch
                    checked={examData.active}
                    onChange={(event) =>
                      setExamData({
                        ...examData,
                        active: event.target.checked
                      })
                    }
                    inputProps={{ 'aria-label': 'ant design' }}
                  />
                </Stack>
              </FormGroup>
            </div>
          </div>
        )}
        <div className="mb-[18px] text-grey-400">
          <TextArea
            label="Description"
            color="black"
            border="#a1a1aa"
            value={examData.description}
            onChange={(value) => setExamData({ ...examData, description: value })}
          />
        </div>
        <div className="flex justify-end">
          <Button onClick={handleNext}>Next</Button>
        </div>
      </div>)}

    </>
  )
}

export default ExamDetailsPage
