import { api } from './api'

const domainEndpoint = 'domain'

const domainApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDomains: build.query({
      query: () => domainEndpoint,
      transformResponse: (response) => response.data,
      providesTags: ['Domain']
    }),
    getDomainsByFilter: build.query({
      query: (body) => ({
        url: `${domainEndpoint}/filter`,
        method: 'POST',
        body: body || { page: 0, size: 25 }
      }),
      transformResponse: (response) => ({
        data: response.data,
        totalItems: response.totalItems,
        totalPages: response.totalPages,
        currentPage: response.currentPage
      }),
      transformErrorResponse: (response) => response.data,
      providesTags: ['Domain']
    }),
    addDomain: build.mutation({
      query: (body) => ({
        url: domainEndpoint,
        method: 'POST',
        body
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['Domain']
    }),
    deleteDomain: build.mutation({
      query: ({ id }) => ({
        url: `${domainEndpoint}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Domain']
    })
  })
})

export const {
  useGetDomainsQuery,
  useLazyGetDomainsQuery,
  useGetDomainsByFilterQuery,
  useLazyGetDomainsByFilterQuery,
  useAddDomainMutation,
  useDeleteDomainMutation
} = domainApi
