import { Alert, Snackbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  useAddExamQuestionsMutation,
  useEditExamQuestionsMutation,
  useLazyGetQuestionsByExamIdQuery
} from '../../app/services/exam-question'
import { useLazyGetQuestionByFilterQuery } from '../../app/services/question'
import { useGetTopicByDomainQuery } from '../../app/services/topic'
import Button from '../../components/core/Button/Button'
import Select from '../../components/core/Select/Select'
import CustomTable from '../../components/feature/Table/Table'
import { accessorKey, header } from '../../constants/columns'
import { labels, questionType, validation } from '../../constants/constants'
import { useViewExamQuery } from '../../app/services/exam'

function ExamSelectQuestion(props) {
  const { examId, setActiveStep, selectedDomain } = props

  const { data: topicList } = useGetTopicByDomainQuery({
    domainId: selectedDomain.uid
  })
  const [getQuestionsByExamId] = useLazyGetQuestionsByExamIdQuery()
  const [getQuestionsByFilter] = useLazyGetQuestionByFilterQuery()
  const [addExamQuestion] = useAddExamQuestionsMutation()
  const [editExamQuestion] = useEditExamQuestionsMutation()

  const [selectedTopic, setSelectedTopic] = useState()
  const [questionList, setQuestionList] = useState([])
  const [selectedQuestionList, setSelectedQuestionList] = useState([])
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [totalSelectedMarks, setTotalSelectedMarks] = useState(0)
  const { data: examData } = useViewExamQuery({ id: examId })

  const searchQuestionByFilter = (selectedQuestionList, selectedTopic) => {
    getQuestionsByFilter({
      domain: selectedDomain.uid,
      topic: selectedTopic ? selectedTopic.uid : null,
      page: 0,
      size: 200
    }).then(({ data, error }) => {
      if (data) {
        if (selectedQuestionList.length > 0) {
          setQuestionList(
            data.data.filter(
              (question) =>
                !selectedQuestionList.find(
                  (selected) => selected.uid === question.uid
                )
            )
          )
        } else {
          setQuestionList(data.data)
        }
      } else if (error) {
        setQuestionList([])
      }
    })
  }
  useEffect(() => {
    if (examId) {
      getQuestionsByExamId({ id: examId }).then(({ data }) => {
        const selectedList = data.map((row) =>
          row.question === null ? null : row.question
        )
        setSelectedQuestionList(selectedList[0] === null ? [] : selectedList)
        searchQuestionByFilter(
          selectedList[0] === null ? [] : selectedList,
          selectedTopic
        )
      })
    } else {
      searchQuestionByFilter()
    }
  }, [examId, getQuestionsByExamId])

  useEffect(() => {
    const newTotalMarks = selectedQuestionList.reduce(
      (total, question) => total + parseInt(question.marks),
      0
    )

    setTotalSelectedMarks(newTotalMarks)
  }, [selectedQuestionList])

  const saveQuestion = () => {
    if (selectedQuestionList.length < 1) {
      setOpenSnackbar(true)
      setSnackbarMessage('Please select questions')
      return
    }

    if (totalSelectedMarks > examData.totalMarks) {
      setOpenSnackbar(true)
      setSnackbarMessage(
        'Selected question marks exceed the total exam marks. Please remove questions accordingly.'
      )
      return
    }

    const body = {
      examUid: examId,
      questionUids: selectedQuestionList.map((item) => item.uid)
    }
    if (examId) {
      editExamQuestion(body).then(({ data, error }) => {
        if (data) {
          setActiveStep((prevStep) => prevStep + 1)
        } else if (error) {
          props.setAlertSeverity(validation.FAIL)
          props.setAlertMessage(error.data.resultMessage)
        }
      })
    } else {
      addExamQuestion(body).then(({ data }) => {
        if (data) {
          setActiveStep((prevStep) => prevStep + 1)
        }
      })
    }
  }

  const handleRemoveQuestions = (row) => {
    setSelectedQuestionList(
      selectedQuestionList.filter((item) => item.uid !== row.uid)
    )
    if (selectedTopic === undefined || selectedTopic.uid === row.topic.uid) {
      setQuestionList([...questionList, row])
    }
  }

  const columnTable1 = [
    {
      label: header.SELECT,
      value: accessorKey.SELECT,
      render: ({ row }) => {
        return (
          <>
            <div className="text-center relative top-[3px] ">
              <input
                type="checkbox"
                id={'checkbox_' + row.no}
                onChange={() => {
                  setQuestionList(
                    questionList.filter((item) => item.uid !== row.uid)
                  )
                  setSelectedQuestionList([...selectedQuestionList, row])
                }}
              />
              <label htmlFor={'checkbox_' + row.no}></label>
            </div>
          </>
        )
      },
      width: '5%'
    },
    {
      label: header.QUESTION,
      value: accessorKey.QUESTION,
      width: '75%',
      render: ({ row }) => {
        return <div dangerouslySetInnerHTML={{ __html: row.question }}></div>
      }
    },
    {
      label: header.QUESTION_TYPE,
      value: accessorKey.TYPE,
      width: '15%',
      render: ({ row }) => {
        switch (row.type) {
          case questionType.MULTIPLE_CHOICE:
            return labels.MULTIPLE_CHOICE
          case questionType.SINGLE_CHOICE:
            return labels.SINGLE_CHOICE
          default:
            return labels.DESCRIPTION
        }
      }
    },
    { label: header.MARKS, value: accessorKey.MARKS, width: '5%' }
  ]

  const columnTable2 = [
    {
      label: header.NO,
      value: accessorKey.NO,
      width: '5%',
      render: ({ row, rowIndex }) => (
        <>
          <Typography className="text-left">{rowIndex + 1}</Typography>
        </>
      )
    },
    {
      label: header.QUESTION,
      value: accessorKey.QUESTION,
      width: '60%',
      render: ({ row }) => {
        return <div dangerouslySetInnerHTML={{ __html: row.question }}></div>
      }
    },
    {
      label: header.QUESTION_TYPE,
      value: accessorKey.TYPE,
      width: '15%',
      render: ({ row }) => {
        switch (row.type) {
          case questionType.MULTIPLE_CHOICE:
            return labels.MULTIPLE_CHOICE
          case questionType.SINGLE_CHOICE:
            return labels.SINGLE_CHOICE
          default:
            return labels.DESCRIPTION
        }
      }
    },
    {
      label: 'Topic',
      value: 'topic',
      width: '12%',
      render: ({ row }) => <div className="text-left">{row.topic.name}</div>
    },
    { label: header.MARKS, value: accessorKey.MARKS, width: '5%' },
    {
      label: header.ACTION,
      value: accessorKey.ACTION,
      width: '8%',
      render: ({ row }) => (
        <div
          className="text-left cursor-pointer text-primary-500 font-medium font-Figtree"
          onClick={() => handleRemoveQuestions(row)}
        >
          Remove
        </div>
      )
    }
  ]
  return (
    <div>
      <div className="flex justify-end">
        <div className="w-[180px]  mb-6">
          <Select
            options={[
              { value: '', label: 'All' },
              ...(topicList
                ? topicList.map((topic) => ({
                    value: topic.uid,
                    label: topic.name
                  }))
                : '')
            ]}
            onChange={(value) => {
              setSelectedTopic({ uid: value.value, name: value.label })
              searchQuestionByFilter(selectedQuestionList, {
                uid: value.value,
                name: value.label
              })
            }}
          />
        </div>
      </div>
      <div className="mb-[40px]">
        <CustomTable columns={columnTable1} data={questionList} />
      </div>
      <div className="mb-8">
        <h4 className="p-3 text-[20px]">List of selcted question</h4>
        <CustomTable columns={columnTable2} data={selectedQuestionList} />
      </div>
      <div className="flex justify-end">
        <Button className="pl-8 pr-8" onClick={() => saveQuestion()}>
          Next
        </Button>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert severity="error">{snackbarMessage}</Alert>
      </Snackbar>
    </div>
  )
}

export default ExamSelectQuestion
