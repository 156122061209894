import React from 'react'
import clsx from 'clsx'
import { Typography } from '@mui/material'

let initialInfo = [
  {
    label: 'Exam Date',
    value: '19/07/2023'
  },
  {
    label: 'Name',
    value: 'Krishna Patel'
  },
  {
    label: 'Email Id',
    value: 'abc@gmail.com'
  },
  {
    label: 'Department',
    value: 'design'
  },
  {
    label: 'Result',
    value: 'Pass'
  },
  {
    label: 'MCQ & SCQ Marks',
    value: '40/60'
  },
  {
    label: 'Descriptive Marks',
    value: '35/40'
  },
  {
    label: 'Total Marks',
    value: '100'
  },
  {
    label: 'Exam Code',
    value: '357890321'
  },
  {
    label: 'Total Questions',
    value: '120'
  },
  {
    label: 'Pass Marks',
    value: '100'
  },
  {
    label: 'Duration',
    value: '45 Minutes'
  }
]
const Answersheet = (props) => {
  const { examInfo = initialInfo } = props
  return (
    <>
      <Typography
        className="w-full grid grid-cols-3 p-5 gap-5 border border-[#D4D4D8] rounded"
        component="div"
      >
        {examInfo.map((info) => {
          return (
            <>
              <Typography className="w-full text-lg font-medium">
                <span className="text-lg font-medium text-black">
                  {info.label + ': '}
                </span>
                <span
                  className={clsx('text-gray-500', {
                    '!text-primary-500': info.label === 'Total Marks',
                    '!text-red-500':
                      info.label === 'Result' && info.value === 'Fail',
                    '!text-success-500':
                      info.label === 'Result' && info.value === 'Pass'
                  })}
                >
                  {info.value}
                </span>
              </Typography>
            </>
          )
        })}
      </Typography>
    </>
  )
}

export default Answersheet
