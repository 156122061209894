import { api } from './api'

const questionEndpoint = 'question'

const topicApi = api.injectEndpoints({
  endpoints: (build) => ({
    getQuestions: build.query({
      query: () => questionEndpoint,
      transformResponse: (response) => response.data,
      providesTags: ['Question']
    }),
    getQuestionByFilter: build.query({
      query: (body) => ({
        url: `${questionEndpoint}/filter`,
        method: 'POST',
        body: body || { domain: '', page: 0, size: 25 }
      }),
      transformResponse: (response) => ({
        data: response.data,
        totalItems: response.totalItems,
        totalPages: response.totalPages,
        currentPage: response.currentPage
      }),
      transformErrorResponse: (response) => response.data,
      providesTags: ['Question']
    }),
    getQuestionById: build.query({
      query: ({ id }) => `${questionEndpoint}/${id}`,
      transformResponse: (response) => response.data,
      providesTags: ['Question']
    }),
    addQuestion: build.mutation({
      query: (body) => ({
        url: questionEndpoint,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Question', 'Question-Summary']
    }),
    editQuestion: build.mutation({
      query: (body) => ({
        url: questionEndpoint,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Question']
    }),
    deleteQuestion: build.mutation({
      query: ({ id }) => ({
        url: `${questionEndpoint}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Question', 'Question-Summary']
    }),
    getQuestionSummary: build.query({
      query: () => `dashboard/question-summary`,
      transformResponse: (response) => response.data,
      providesTags: ['Question-Summary']
    })
  })
})

export const {
  useGetQuestionsQuery,
  useLazyGetQuestionsQuery,
  useGetQuestionByFilterQuery,
  useLazyGetQuestionByFilterQuery,
  useGetQuestionByIdQuery,
  useLazyGetQuestionByIdQuery,
  useAddQuestionMutation,
  useEditQuestionMutation,
  useDeleteQuestionMutation,
  useGetQuestionSummaryQuery
} = topicApi
