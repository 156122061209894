import MatirialButton from '@mui/material/Button'

const colors = {
  error: '#EF4444',
  primary: '#006AFE',
  secondary: '#F4F4F5',
  danger: '#FEE2E2'
}
const Button = (props) => {
  const {
    className,
    children,
    color = 'primary',
    onClick,
    width,
    disabled
  } = props

  const buttonStyle = {
    backgroundColor: colors[color],
    color:
      color === 'secondary'
        ? 'gray'
        : color === 'danger'
        ? colors.error
        : 'white',
    textTransform: 'capitalize',
    height: '40px',
    boxShadow: 'none',
    width: width,
    fontSize: '14px',
    padding: '0 32px',
    opacity: disabled ? 0.5 : 1
  }

  return (
    <MatirialButton
      className={className}
      style={buttonStyle}
      onClick={onClick}
      variant="contained"
      disabled={disabled}
    >
      {children}
    </MatirialButton>
  )
}

export default Button
