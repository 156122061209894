import * as React from 'react'
import { styled, alpha } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import { useLocation, useNavigate } from 'react-router-dom'
import AddTopic from '../../../pages/Topic/AddTopic'
import AddDeparment from '../../../pages/Department/AddDeparment'

import MuiAppBar from '@mui/material/AppBar'
import Button from '../../core/Button/Button'
import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  right: 0
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  border: '1px solid #D4D4D8',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 1),
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '28ch'
    }
  }
}))

function AppTitle(props) {
  const [title, setTitle] = React.useState('')
  React.useEffect(() => {
    switch (props.pathname) {
      case '/department':
        setTitle('Department')
        break
      case '/topic':
        setTitle('Topic')
        break
      case '/exam':
        setTitle('Exam')
        break
      case '/result':
        setTitle('Result')
        break
      case '/paper-checker':
        setTitle('All Paper')
        break

      case `/result/answer-sheet`:
        setTitle('Answer Sheet')
        break
      case '/question':
        setTitle('Question')
        break
      case '/question/add':
        setTitle('Add New Question')
        break
      case '/exam/add':
        setTitle('Add New Exam')
        break

      default:
        if (props.pathname.startsWith('/question/')) {
          setTitle('Edit Question')
        } else if (props.pathname.startsWith('/exam/')) {
          setTitle('Edit Exam')
        } else if (props.pathname.startsWith('/result/')) {
          setTitle('Answer Sheet')
        } else if (props.pathname.startsWith('/paper-checker/')) {
          setTitle('Paper Checker')
        } else {
          setTitle('Dashboard')
        }
    }
  }, [props.pathname])

  return (
    <Typography
      variant="h6"
      noWrap
      component="div"
      style={{
        flexGrow: 1,
        display: 'block',
        fontFamily: 'Figtree, sans-serif',
        fontSize: '24px'
      }}
    >
      {title}
    </Typography>
  )
}

function AddModel(props) {
  if (props.pathname === '/department') {
    return <AddDeparment open={props.open} setOpen={props.setOpen} />
  } else if (props.pathname === '/topic') {
    return <AddTopic open={props.open} setOpen={props.setOpen} />
  }
  return <></>
}
const drawerWidth = 226

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: 'white',
  color: 'black',
  boxShadow: 'none',
  height: '77px',
  padding: '0',
  justifyContent: 'center',
  display: 'flex',
  borderBottom: '1px solid #D4D4D8',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  width: 'calc(100% - 65px)',
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

function Header(props) {
  const [open, setOpen] = React.useState(false)
  const subpathRE = /\/\w+/
  const firstpathRE = /^\/[\w, -]+$/
  const navigate = useNavigate()
  const handleOpen = () => {
    if (location.pathname === '/question') {
      navigate('/question/add')
      return
    } else if (location.pathname === '/exam') {
      navigate('/exam/add')
      return
    }
    setOpen(true)
  }
  const location = useLocation()

  return (
    <>
      <AppBar position="fixed" open={props.open}>
        <Toolbar>
          {location.pathname && subpathRE.test(location.pathname) && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                if (firstpathRE.test(location.pathname))
                  navigate('/', { replace: true })
                else window.history.back()
              }}
              edge="start"
              sx={{
                marginRight: 1
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          )}

          <AppTitle pathname={location.pathname} />
          {/* {location.pathname.startsWith('/exam/') ||
          location.pathname.startsWith('/question/') ||
          location.pathname === '/' ||
          location.pathname.startsWith('/result') ? null : (
            <Search sx={{ marginRight: '24px' }}>
              <SearchIconWrapper sx={{ color: '#71717A' }}>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                sx={{ borderRadius: '4px' }}
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          )} */}
          {location.pathname === '/department' ||
            location.pathname === '/topic' ||
            location.pathname === '/question' ||
            location.pathname === '/exam' ? (
            <Button onClick={handleOpen}>Add</Button>
          ) : null}
        </Toolbar>
      </AppBar>
      <AddModel open={open} setOpen={setOpen} pathname={location.pathname} />
    </>
  )
}

export default Header
