import React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Style from './progressbar.module.css'

const ProgressBar = ({ value, numberOfQuestion, answeredQuestion }) => {
  return (
    <div className="flex items-center justify-between gap-4">
      <LinearProgress
        variant="determinate"
        value={value}
        className={Style.progressbar__line}
      />
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        className="text-xs text-black font-semibold"
      >
        {`${answeredQuestion}/${numberOfQuestion}`}
      </Typography>
    </div>
  )
}

export default ProgressBar
