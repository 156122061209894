import { Box, FormControl, Typography } from '@mui/material'
import Select from '../../components/core/Select/Select'
import { useGetDomainsQuery } from '../../app/services/domain'
import { useEffect, useState } from 'react'
import CustomTable from '../../components/feature/Table/Table'
import { accessorKey, header } from '../../constants/columns'
import { useLazyGetPageCheckerResultByFilterQuery } from '../../app/services/page-checker'
import { useNavigate } from 'react-router-dom'

export default function PaperCheckerListing() {
  const { data: domains } = useGetDomainsQuery()
  const domainSelectionList = domains?.map((domain) => {
    return {
      label: domain.name,
      value: domain.uid
    }
  })

  const [results, setResults] = useState([])
  const navigate = useNavigate()

  const [getLazyPageCheckerResultByFilterQuery] =
    useLazyGetPageCheckerResultByFilterQuery()
  const columns = [
    { value: accessorKey.NO, label: header.NO, width: '5%' },
    { value: accessorKey.NAME, label: header.NAME, width: '35%' },
    { value: accessorKey.EMAIL, label: header.EMAIL, width: '20%' },

    {
      value: accessorKey.EXAM_CODE,
      label: header.EXAM_CODE,
      width: '15%',
      render: ({ row }) => (
        <Typography className="text-[#1f5aff] hover:underline">
          {row.examCode}
        </Typography>
      )
    },
    {
      value: accessorKey.EXAM_DATE_STRING,
      label: header.EXAM_DATE,
      width: '8%',
      render: ({ row }) => <Typography>{row.examDate}</Typography>
    },
    { value: accessorKey.DOMAIN_NAME, label: header.DOMAIN, width: '10%' },
    { value: accessorKey.STATUS, label: header.STATUS, width: '7%' }
  ]

  function handleDeparmentChange({ value }) {
    handleResultSearch(domains.find((item) => item.uid === value).uid)
  }

  function handleResultSearch(selectedDomain) {
    const body = {}
    body.domainUid = selectedDomain
    getLazyPageCheckerResultByFilterQuery(body).then(
      ({ data: resultListByFilter }) => {
        setResults(resultListByFilter)
      }
    )
  }

  useEffect(() => {
    handleResultSearch('')
  }, [])

  return (
    <>
      <Box sx={{ textAlign: 'right', mr: 2, mb: 4 }}>
        <FormControl sx={{ width: 198, justifyContent: 'end' }}>
          <Select
            options={domainSelectionList}
            onChange={(value) => handleDeparmentChange(value)}
            placeholder="Select Department"
          />
        </FormControl>
      </Box>

      <div className="paper-checker">
        <CustomTable
          columns={columns}
          data={results?.map((l, i) => ({ no: i + 1, ...l }))}
          onRowClick={(rowData) => {
            navigate(rowData.examinationUid)
          }}
        />
      </div>
    </>
  )
}
