import { Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { api } from '../../app/services/api'
import { navigationActions } from '../../app/store/navigation'
import { questionActions } from '../../app/store/question'
import brandinglogo from '../../assets/icon/branding-logo-1.svg'
import hand from '../../assets/icon/hand.svg'
import completeimage from '../../assets/image/completeimage.svg'
import logo from '../../assets/image/sidebar-logo.svg'
import Style from './complete.module.css'

function ExamCompleted() {
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(api.util.resetApiState())
    dispatch(
      questionActions.addQuestion({
        questionData: {}
      })
    )
    dispatch(
      questionActions.addLastQuestionData({
        lastQuestionData: {}
      })
    )
    dispatch(
      questionActions.setShowResumeButton({
        isShowResumeButton: false
      })
    )
    dispatch(
      questionActions.setIsRemainingQuestion({
        isRemainingQuestion: true
      })
    )
    dispatch(
      questionActions.addQuestionStatus({
        questionStatus: {}
      })
    )
    dispatch(
      navigationActions.addNavigationData({
        navigationData: null
      })
    )
  }, [dispatch])

  return (
    <div className={Style.examcompletes}>
      <div className={Style.examcompletes__logo}>
        <img src={logo} alt="logo" />
      </div>
      <div className={Style.examcompletes__wrapper}>
        <div className={Style.examcompletes__left}>
          <div className={Style.examcompletes__content}>
            <Typography
              className={Style.examcompletes__title}
              id="modal-modal-title"
              variant="h3"
            >
              Hi, {location.state?.examineeName} <img src={hand} alt="hand" />
            </Typography>
            <p className={Style.examcompletes__text}>
              "Congratulations! Your exam has been successfully completed. Your
              paper will now undergo evaluation, and we will notify you of your
              marks soon."
            </p>
          </div>
          <div className={Style.poweredby}>
            <p className={Style.poweredby__text}>Powered by</p>
            <div className={Style.poweredby__logo}>
              <img src={brandinglogo} alt="brandinglogo" />
            </div>
          </div>
        </div>
        <div className={Style.examcompletes__right}>
          <div className={Style.examcompletes__image}>
            <img src={completeimage} alt="completeimage" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExamCompleted
