import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useGetExamSummaryQuery } from '../../app/services/exam'
import { useGetQuestionSummaryQuery } from '../../app/services/question'
import {
  useGetExamConcludedQuery,
  useGetMonthWiseDetailsQuery,
  useGetSuccessRatioSummaryQuery
} from '../../app/services/exam-result'
import DashboardCard from './card/DashboardCard'
import Chart from 'chart.js/auto'
import { Bar } from 'react-chartjs-2'
import clsx from 'clsx'

const chartArray = [
  {
    id: 1,
    label: 'Total Question'
  },
  {
    id: 2,
    label: 'Total Exam'
  },
  {
    id: 3,
    label: 'Success Rate'
  },
  {
    id: 4,
    label: 'Total Exam Conducted'
  }
]

const gradient = (context) => {
  const chart = context.chart
  const ctx = chart.ctx
  const x = 0
  const y = 0
  const gradient = ctx.createLinearGradient(x, y, 0, chart.height)
  gradient.addColorStop(0, '#98D0FE')
  gradient.addColorStop(1, '#E0F5FE')
  return gradient
}

const roundedBar = (context) => {
  const chart = context.chart
  const ctx = chart.ctx
  const left = context.p0.x
  const right = context.p1.x
  const top = context.p0.y
  const bottom = context.p2.y

  const radius = 4

  ctx.beginPath()
  ctx.moveTo(left + radius, top)
  ctx.lineTo(right - radius, top)
  ctx.quadraticCurveTo(right, top, right, top + radius)
  ctx.lineTo(right, bottom)
  ctx.lineTo(left, bottom)
  ctx.lineTo(left, top + radius)
  ctx.quadraticCurveTo(left, top, left + radius, top)
  ctx.closePath()
  ctx.fillStyle = gradient(context)
  ctx.fill()
}

const totalExamChartOptions = {
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        padding: 15,
        callback: function (value, index, values) {
          return value
        },
        color: '#27272A',
        font: {
          size: 12,
          weight: 400
        }
      },
      grid: {
        drawBorder: false,
        drawTicks: false,
        color: (context) =>
          context.tick.value === 0 ? 'black' : 'rgba(0, 0, 0, 0.1)'
      },
      title: {
        display: true,
        text: 'Total Count',
        font: {
          size: 20,
          weight: 600
        },
        color: 'black',
        padding: 24
      }
    },
    x: {
      grid: {
        drawBorder: false,
        drawTicks: false,
        color: (context) =>
          context?.tick?.value === 0 ? 'black' : 'transparent'
      },
      ticks: {
        color: '#27272A',
        padding: 15,
        font: {
          size: 12,
          weight: 400
        }
      },
      title: {
        display: true,
        text: 'Department',
        font: {
          size: 20,
          weight: 600
        },
        color: 'black',
        padding: 24
      }
    }
  },
  plugins: {
    legend: {
      display: false // Hide the legend
    }
  }
}

const examAppearanceChartOptions = {
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        padding: 10,
        callback: function (value, index, values) {
          return value
        },
        color: '#27272A',
        font: {
          size: 14,
          weight: 500
        }
      },
      grid: {
        drawBorder: false,
        drawTicks: false,
        color: (context) =>
          context.tick.value === 0 ? 'black' : 'rgba(0, 0, 0, 0.1)'
      },
      title: {
        display: true,
        text: 'Total Count',
        font: {
          size: 20,
          weight: 600
        },
        color: 'black',
        padding: 24
      }
    },
    x: {
      grid: {
        drawBorder: false,
        drawTicks: false,
        color: (context) =>
          context?.tick?.value === 0 ? 'black' : 'transparent'
      },
      ticks: {
        color: '#27272A',
        padding: 15,
        font: {
          size: 12,
          weight: 500
        }
      },
      title: {
        display: true,
        text: 'Topic',
        font: {
          size: 20,
          weight: 600
        },
        color: 'black',
        padding: 24
      }
    }
  },
  plugins: {
    legend: {
      display: false // Hide the legend
    }
  }
}

const Dashboard = () => {
  const { data: examSummaryData } = useGetExamSummaryQuery()
  const { data: questionSummaryData } = useGetQuestionSummaryQuery()
  const { data: successRatioSummaryData } = useGetSuccessRatioSummaryQuery()
  const { data: monthWiseData } = useGetMonthWiseDetailsQuery()
  const { data: examConcludedData } = useGetExamConcludedQuery()

  const [totalExamData, setTotalExamData] = useState(0)
  const [totalQuestionData, setTotalQuestionData] = useState(0)
  const [totalSuccessRateData, setTotalSuccessRateData] = useState(0)
  const [totalExamConcludedData, setTotalExamConcludedData] = useState(0)
  const [selectedTab, setSelectedTab] = useState(chartArray[0])
  const [totalExamChartData, setTotalExamChartData] = useState(null)

  useEffect(() => {
    const filterData =
      selectedTab.label === 'Total Question'
        ? questionSummaryData?.summary
        : selectedTab.label === 'Total Exam'
        ? examSummaryData?.summary
        : selectedTab.label === 'Success Rate'
        ? successRatioSummaryData?.domain?.map((item) => ({
            domain: item.name,
            ...item
          }))
        : selectedTab.label === 'Total Exam Conducted'
        ? examConcludedData?.summary
        : {}

    setTotalExamChartData({
      labels: filterData?.map((item) => item.domain),
      datasets: [
        {
          label: selectedTab?.label,
          data: filterData?.map((item) => item.total),
          barThickness: 34,
          borderRadius: 4,
          parsed: true,
          custom: roundedBar,
          backgroundColor: gradient
        }
      ]
    })
  }, [selectedTab, questionSummaryData])

  const examAppearanceChartData = {
    labels: examConcludedData?.summary?.map((item) => item.domain),
    datasets: [
      {
        label: 'Total Appearance',
        data: examConcludedData?.summary?.map((item) => item.total),
        barThickness: 34,
        borderRadius: 4,
        parsed: true,
        custom: roundedBar,
        backgroundColor: gradient
      }
    ]
  }

  const getBarChartData = () => {
    if (monthWiseData) {
      let barSeriesData = []
      let barOptionData = []

      monthWiseData?.forEach((barData) => {
        barOptionData.push([barData.domain])
        barSeriesData.push(barData.total)
      })
    }
  }

  useEffect(() => {
    if (questionSummaryData) {
      setTotalQuestionData(questionSummaryData.question)
    }
    if (examSummaryData) {
      setTotalExamData(examSummaryData.total)
    }
    if (successRatioSummaryData) {
      setTotalSuccessRateData(
        isNaN(successRatioSummaryData.total)
          ? '0'
          : successRatioSummaryData.total.toFixed(2)
      )
    }
    if (examConcludedData) {
      setTotalExamConcludedData(
        examConcludedData.total === 0 ? '0' : examConcludedData.total
      )
    }
  }, [
    examSummaryData,
    questionSummaryData,
    successRatioSummaryData,
    examConcludedData
  ])

  useEffect(() => {
    getBarChartData()
  }, [monthWiseData])

  return (
    <>
      <Grid container spacing={2} className="m-0 w-full">
        <Grid container>
          <DashboardCard
            totalQuestion={totalQuestionData}
            totalExam={totalExamData}
            sucessRate={totalSuccessRateData}
            totalExamConducted={totalExamConcludedData}
          />
          <div className="w-full m-0">
            <div className="border border-grey-200 rounded-2xl my-6 pr-4">
              <div className="flex justify-between p-6">
                <h2 className="text-black text-[20px] font-semibold">
                  {selectedTab.label}
                </h2>
              </div>
              <div className="flex justify-center">
                <div className="flex border border-grey-300 rounded divide-x text-[14px] cursor-pointer text-grey-700 divide-gray-300 ">
                  {chartArray.map((e, index) => {
                    return (
                      <div
                        onClick={() => setSelectedTab(e)}
                        className={clsx('py-1 px-2 font-medium', {
                          'text-primary-500': e.id === selectedTab.id
                        })}
                        key={index}
                      >
                        {e.label}
                      </div>
                    )
                  })}
                </div>
              </div>
              <Grid item xs={11} md={11}>
                {totalExamChartData && totalExamChartData?.labels ? (
                  <Bar
                    data={totalExamChartData}
                    options={totalExamChartOptions}
                  />
                ) : (
                  <Typography className="text-center my-4">
                    No Record Found
                  </Typography>
                )}
              </Grid>
            </div>
            <div className="border border-grey-200 rounded-2xl pr-4">
              <h2 className="p-6 text-black text-[20px] font-semibold">
                Exam Appearance Details
              </h2>
              <Grid item xs={11} md={11}>
                {examAppearanceChartData && examAppearanceChartData?.labels ? (
                  <Bar
                    data={examAppearanceChartData}
                    options={examAppearanceChartOptions}
                  />
                ) : (
                  <Typography className="text-center my-4">
                    No Record Found
                  </Typography>
                )}
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default Dashboard
