import { HashRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { Provider } from 'react-redux'
import store from '../src/app/store/store'
import AppRouter from './components/router/AppRouter'
import Notification from './components/feature/Notification'
import theme from './lib/MuiTheme'
import './App.css'
import ScrollToTop from './components/feature/ScrollToTop'
import DisableDevtool from 'disable-devtool'

DisableDevtool({
  ignore: () => !window.location.hash.startsWith('#/user/')
})

function App() {
  return (
    <HashRouter>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <ScrollToTop />
          <AppRouter />
          <Notification />
        </Provider>
      </ThemeProvider>
    </HashRouter>
  )
}

export default App
