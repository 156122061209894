import { Alert, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import logo from '../../assets/image/sidebar-logo.svg'

import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { useAddExamUserMutation } from '../../app/services/exam-result'
import brandinglogo from '../../assets/icon/branding-logo-1.svg'
import hand from '../../assets/icon/hand.svg'
import examloginimg from '../../assets/image/examloginimg.jpg'
import DynamicButton from '../../components/core/DynamicButton/DynamicButton'
import Input from '../../components/core/TextField/TextField'
import Style from './examlogin.module.css'

function Examlogin() {
  const navigate = useNavigate()

  const [addExamUser] = useAddExamUserMutation()

  const [errorMessage, setErrorMessage] = useState('')
  const [agreeCheckbox, setAgreeCheckbox] = useState(false)

  const onSubmit = (e) => {
    addExamUser({
      email: e.email.trim(),
      name: e.name.trim(),
      enrollmentNumber: e.enrollmentNumber.trim(),
      exam: {
        examCode: e.examCode.trim()
      }
    }).then(({ data, error }) => {
      if (data) {
        localStorage.setItem('examineeUid', data.uid)
        localStorage.setItem('candidate', data.candidate)
        localStorage.setItem('enrollmentNumber', data.enrollmentNumber)
        localStorage.setItem('examCode', data.examCode)
        localStorage.setItem('duration', data.duration)
        localStorage.setItem('questionCount', data.questionCount)
        localStorage.setItem(
          'negativeScoringEnabled',
          data.negativeScoringEnabled
        )
        navigate('/user/exam-instruction', {
          replace: true,
          state: {
            examineeName: e.name,
            duration: data.duration,
            questionCount: data.questionCount,
            negativeScoringEnabled: data.negativeScoringEnabled
          }
        })
      } else if (error) {
        setErrorMessage(error.data.resultMessage)
      }
    })
  }

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      enrollmentNumber: '',
      examCode: ''
    }
  })

  return (
    <div className={Style.examlogin}>
      <div className={Style.examlogin__wrapper}>
        <div className={Style.examlogin__left}>
          <div className={Style.examlogin__logo}>
            <img src={logo} alt="logo" />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={Style.login__form}>
              <Typography
                className={Style.examlogin__title}
                id="modal-modal-title"
                variant="h3"
              >
                Welcome! <img src={hand} alt="hand"></img>
              </Typography>
              <p className={Style.examlogin__text}>Get Started With TestiQo</p>
              <div className={Style.input__form}>
                <Input
                  label="Full Name"
                  placeholder="Enter your Full Name "
                  className={Style.input__fied}
                  validate={register('name', {
                    required: 'Name is required',
                    maxLength: {
                      value: 30,
                      message: 'You can enter maximum 30 characters only.'
                    }
                  })}
                />
                {errors.name && (
                  <p className="text-[#b91c1c]">{errors.name?.message}</p>
                )}
                <Input
                  label="Enrollment no."
                  placeholder="Enrollment no."
                  className={Style.input__fied}
                  validate={register('enrollmentNumber')}
                />
                <Input
                  label="Email"
                  placeholder="Enter your e-mail "
                  className={Style.input__fied}
                  validate={register('email', {
                    required: 'Email is required',
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: 'Please enter a valid email'
                    }
                  })}
                />
                {errors.email && (
                  <p className="text-[#b91c1c]">{errors.email?.message}</p>
                )}
                <Input
                  label="Exam Code"
                  placeholder="Enter your exam code"
                  className={Style.input__fied}
                  validate={register('examCode', {
                    required: 'Exam code is required'
                  })}
                />
                {errors.examCode && (
                  <p className="text-[#b91c1c]">{errors.examCode?.message}</p>
                )}
                {errorMessage && errorMessage.length > 0 && (
                  <Grid item xs={12} sm={12}>
                    <Alert severity="error" className="mt-2">
                      {errorMessage}
                    </Alert>
                  </Grid>
                )}
              </div>
              <div className={Style.examlogin__privacy}>
                <div className={Style.form_roup}>
                  <input
                    type="checkbox"
                    id={'checkbox_'}
                    onChange={({ target }) => setAgreeCheckbox(target.checked)}
                  />
                  <label htmlFor={'checkbox_'}></label>
                </div>
                <p className={Style.privacy__text}>
                  <span>I agree to the</span> Privacy Policy <span>and </span>
                  Terms & Conditions
                </p>
              </div>
              <div className={Style.examlogin__action}>
                <DynamicButton
                  type="submit"
                  className={Style.examlogin__btn}
                  width="500px"
                  disabled={!agreeCheckbox}
                >
                  Register
                </DynamicButton>
              </div>
            </div>
          </form>
          <div className={Style.poweredby}>
            <p className={Style.poweredby__text}>Powered by</p>
            <div className={Style.poweredby__logo}>
              <img src={brandinglogo} alt="brandinglogo" />
            </div>
          </div>
        </div>
        <div className={Style.examlogin__right}>
          <div className={Style.examlogin__image}>
            <img src={examloginimg} alt="examloginimg" />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Examlogin
