import { api } from './api'

const fileApi = api.injectEndpoints({
  endpoints: (build) => ({
    getQuestionTemplate: build.query({
      query: () => `/question/template`,
      transformResponse: (response) => response.data
    }),
    importQuestions: build.query({
      query: (body) => ({
        url: `/upload/questions`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Question']
    })
  })
})

export const {
  useGetQuestionTemplateQuery,
  useLazyGetQuestionTemplateQuery,
  useImportQuestionsQuery,
  useLazyImportQuestionsQuery
} = fileApi
