import { Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useGetDomainsQuery } from '../../app/services/domain'
import {
  useGetExamByFilterQuery,
  useGetExamComplexityLevelOptionsQuery,
  useLazyGetExamByFilterQuery
} from '../../app/services/exam'
import Select from '../../components/core/Select/Select'
import Alert from '../../components/feature/AlertMessage/AlertMessage'
import PageNumber from '../../components/feature/PageNumber/PageNum'
import CustomTable from '../../components/feature/Table/Table'
import Loader from '../../components/layout/Loader/Loader'
import { accessorKey, header } from '../../constants/columns'
import { dateFilter, pagination, validation } from '../../constants/constants'

const months = [
  { value: '', label: 'All' },
  { value: dateFilter.LAST_YEAR, label: dateFilter.LAST_YEAR },
  { value: dateFilter.LAST_6_MONTHS, label: dateFilter.LAST_6_MONTHS },
  { value: dateFilter.LAST_3_MONTHS, label: dateFilter.LAST_3_MONTHS },
  { value: dateFilter.LAST_MONTH, label: dateFilter.LAST_MONTH },
  { value: dateFilter.LAST_WEEK, label: dateFilter.LAST_WEEK },
  { value: dateFilter.TODAY, label: dateFilter.TODAY }
]

const ExamList = () => {
  const { data: exams, isFetching } = useGetExamByFilterQuery()
  const [getExamByFilter] = useLazyGetExamByFilterQuery()
  const navigate = useNavigate()
  const { data: domains } = useGetDomainsQuery()
  const [examList, setExamList] = useState([])

  const [filterDepartment, setFilterDepartment] = useState()
  const [selectedDateRange, setSelectedDateRange] = useState('')
  const { data: complexityLevelOptionsData } =
    useGetExamComplexityLevelOptionsQuery()

  const [examCodeCopiedMsg, setExamCodeCopiedMsg] = useState()
  const [totalPages, setTotalPages] = useState(0)

  // set start and end date
  let startDate = new Date()
  let endDate = new Date()
  startDate.setHours(0, 0, 0, 0)
  endDate.setHours(23, 59, 59, 999)
  let defaultPageSize = pagination.DEFAULT_PAGE_SIZE

  useEffect(() => {
    if (exams !== undefined) {
      setExamList(exams.data)
      setTotalPages(exams.totalPages)
    }
  }, [exams])

  const handleExamSearch = (body) => {
    getExamByFilter(body).then(({ data: examListByFilter }) => {
      if (examListByFilter === undefined) setExamList([])
      else {
        window.scrollTo({ top: 0 })
        setTotalPages(examListByFilter.totalPages)
        setExamList(examListByFilter.data)
      }
    })
  }

  const initializeStartDate = (value) => {
    const today = new Date()

    switch (value) {
      case dateFilter.LAST_YEAR:
        startDate.setFullYear(today.getFullYear() - 1)
        break
      case dateFilter.LAST_6_MONTHS:
        startDate.setMonth(today.getMonth() - 6)
        break
      case dateFilter.LAST_3_MONTHS:
        startDate.setMonth(today.getMonth() - 3)
        break
      case dateFilter.LAST_MONTH:
        startDate.setMonth(today.getMonth() - 1)
        break
      case dateFilter.LAST_WEEK:
        startDate.setDate(today.getDate() - 7)
        break
      default:
        break
    }
  }

  const handleDateChange = ({ value }) => {
    setSelectedDateRange(value)

    if (value === undefined || value === '') {
      handleExamSearch({
        domain: filterDepartment,
        startDate: null,
        endDate: null,
        page: 0,
        size: defaultPageSize
      })
      return
    }

    initializeStartDate(value)

    handleExamSearch({
      domain: filterDepartment,
      startDate: startDate,
      endDate: endDate,
      page: 0,
      size: defaultPageSize
    })
  }

  const columns = [
    {
      value: accessorKey.NO,
      label: header.NO,
      width: '5%',
      render: ({ row, rowIndex }) => (
        <>
          <Typography className="text-left">{rowIndex + 1}</Typography>
        </>
      )
    },
    {
      value: accessorKey.NAME,
      label: header.EXAM_NAME,
      width: '13%'
    },
    {
      value: accessorKey.DESCRIPTION,
      label: header.DESCRIPTION,
      width: '32%'
    },
    {
      value: accessorKey.COMPLEXITY,
      label: header.COMPLEXITY,
      width: '14%',
      render: ({ row }) => (
        <>
          <Typography className="text-left">
            {complexityLevelOptionsData &&
              complexityLevelOptionsData.find(
                (option) => option.id === row.complexityLevel
              ).name}
          </Typography>
        </>
      )
    },
    {
      value: accessorKey.DURATION,
      label: header.DURATION,
      width: '7%'
    },
    {
      value: accessorKey.TOTAL_MARKS,
      label: header.TOTAL_MARKS,
      width: '7%'
    },
    {
      value: accessorKey.PASS_MARKS,
      label: header.PASS_MARKS,
      width: '7%'
    },
    {
      value: accessorKey.EXAM_CODE,
      label: header.EXAM_CODE,
      width: '15%',
      render: ({ row }) => (
        <>
          <Typography
            className="text-[#1f5aff] hover:underline"
            onClick={onExamCodeClick}
          >
            {' '}
            {row[accessorKey.EXAM_CODE]}
          </Typography>
        </>
      )
    }
  ]

  const handleDepartmentChange = ({ value }) => {
    setFilterDepartment(value)

    if (selectedDateRange === undefined || selectedDateRange === '') {
      startDate = null
      endDate = null
    } else {
      initializeStartDate(selectedDateRange)
    }
    handleExamSearch({
      domain: value,
      startDate: startDate,
      endDate: endDate,
      page: 0,
      size: defaultPageSize
    })
  }

  const onRowClick = (row) => {
    navigate(row.uid)
  }

  let disappearMsgTimout
  const onExamCodeClick = (event) => {
    // prevent calling onRowClick() method
    event.stopPropagation()
    navigator.clipboard.writeText(event.target.outerText)
    setExamCodeCopiedMsg('Exam Code Copied')
    disappearMsgTimout = setTimeout(disappearMsgHandler, 3000)
  }

  const disappearMsgHandler = () => {
    setExamCodeCopiedMsg()
    clearTimeout(disappearMsgTimout)
  }

  const handlePageChange = (body) => {
    let payload = { ...body, size: defaultPageSize }

    if (
      filterDepartment !== null &&
      filterDepartment !== undefined &&
      filterDepartment !== ''
    )
      payload = { ...payload, domain: filterDepartment }

    if (selectedDateRange === undefined || selectedDateRange === '') {
      startDate = null
      endDate = null
    } else {
      initializeStartDate(selectedDateRange)
    }
    handleExamSearch({ ...payload, startDate: startDate, endDate: endDate })
  }

  return (
    <>
      {examCodeCopiedMsg && (
        <div className="flex items-center fixed">
          <Alert
            severity={validation.SUCCESS}
            message={examCodeCopiedMsg}
            className="absolute top-[-23.5px] left-[508px] z-[10000]"
          />
        </div>
      )}
      <div className="flex mb-6 gap-4 justify-end">
        <div className="w-[198px]">
          <Select
            placeholder="Select Department"
            options={[
              { value: '', label: 'All' },
              ...(domains !== undefined && domains.length > 0
                ? domains.map((domain) => ({
                    value: domain.uid,
                    label: domain.name
                  }))
                : '')
            ]}
            onChange={handleDepartmentChange}
          />
        </div>
        <div className="w-[166px]">
          <Select
            placeholder="Select Date"
            value={{ value: selectedDateRange, label: selectedDateRange }}
            options={months}
            onChange={handleDateChange}
          />
        </div>
      </div>
      {isFetching ? (
        <Loader />
      ) : (
        <>
          <CustomTable
            columns={columns}
            data={examList}
            onRowClick={onRowClick}
          />
          <PageNumber count={totalPages} onChange={handlePageChange} />
        </>
      )}
    </>
  )
}
export default ExamList
