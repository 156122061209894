export const accessorKey = {
  NAME: 'name',
  NO: 'no',
  UID: 'uid',
  DOMAIN: 'domain',
  QUESTION: 'question',
  DESCRIPTION: 'description',
  DURATION: 'duration',
  TOTAL_MARKS: 'totalMarks',
  PASS_MARKS: 'passMarks',
  EXAM_CODE: 'examCode',
  TOPIC: 'topic',
  TYPE: 'type',
  COMPLEXITY: 'complexity',
  OPTION: 'option',
  ANSWER: 'answer',
  QESTION_OPTION: 'questionMcq',
  CANDIDATE_NAME: 'candidate',
  EXAM_DATE: 'startTime',
  EXAM_DATE_STRING: 'examDate',
  PASSED: 'passed',
  CORRECT_ANSWER: 'correct',
  INCORRECT_ANSWER: 'incorrect',
  EMAIL: 'email',
  NEGATIVE_MARKS: 'negativeMarks',
  ENALBE_NEGATIVE_MARKS: 'enableNegativeMarks',
  ACTION: 'action',
  VIEW: 'view',
  DEPARTMENT: 'department',
  STATUS: 'status',
  SELECT: 'Select',
  MARKS: 'marks',
  DOMAIN_NAME: 'domainName',
  ENROLLMENT_NUMBER: 'enrollmentNumber',
  EXAM_NAME: 'examName'
}

export const header = {
  TOPIC: 'Topic',
  NO: 'No',
  UID: 'uid',
  NAME: 'Name',
  QUESTION: 'Question',
  QUESTION_TYPE: 'Question Type',
  DOMAIN: 'Domain',
  DESCRIPTION: 'Description',
  DURATION: 'Duration',
  TOTAL_MARKS: 'Total Marks',
  PASS_MARKS: 'Pass Marks',
  EXAM_CODE: 'Exam Code',
  TYPE: 'Type',
  COMPLEXITY: 'Complexity',
  OPTION: 'Option',
  ANSWER: 'Answer',
  EXAM: 'Exam',
  CANDIDATE_NAME: 'Candidate Name',
  EXAM_DATE: 'Exam Date',
  PASSED: 'Passed',
  CORRECT_ANSWER: 'Correct Answer',
  INCORRECT_ANSWER: 'Incorrect Answer',
  EMAIL: 'Email',
  ACTION: 'Action',
  VIEW: 'View',
  DEPARTMENT: 'Department',
  EXAM_NAME: 'Exam Name',
  RESULT: 'Result',
  STATUS: 'Status',
  SELECT: 'Select',
  MARKS: 'Marks',
  TOTAL_QUESTION: 'Total Question',
  ENROLLMENT_NUMBER: 'Enroll Num. '
}
