import { api } from './api'

const examQuestionEndpoint = 'exam-question'

const examQuestionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getQuestionsByExamId: build.query({
      query: ({ id }) => `${examQuestionEndpoint}/${id}`,
      transformResponse: (response) => response.data
    }),
    addExamQuestions: build.mutation({
      query: (body) => ({
        url: examQuestionEndpoint,
        method: 'POST',
        body
      })
    }),
    editExamQuestions: build.mutation({
      query: (body) => ({
        url: examQuestionEndpoint,
        method: 'PUT',
        body
      })
    }),
    startExam: build.query({
      query: (body) => ({
        url: `examination/start`,
        method: 'POST',
        body
      }),
      transformResponse: (response) => response.data
    }),
    getExaminationDetails: build.query({
      query: ({ id }) => `examination/details/${id}`,
      transformResponse: (response) => response.data
    }),
    getQuestoin: build.query({
      query: ({ params }) =>
        `examination/${params.examCode}?examinationUid=${params.examinationUid}&topicId=${params.topicId}&questionType=${params.questionType}`,
      transformResponse: (response) => response.data
    }),
    submitAnswer: build.mutation({
      query: (body) => ({
        url: `examination`,
        method: 'PUT',
        body
      }),
      transformResponse: (response) => response.data
    }),
    getNavigation: build.query({
      query: ({ id }) => `examination/navigation/${id}`,
      transformResponse: (response) => response.data
    }),
    getExaminationQuestionByUid: build.query({
      query: ({ id }) => `examination/question/${id}`,
      transformResponse: (response) => response.data
    }),
    submitExamByExaminationUid: build.mutation({
      query: ({ id }) => ({
        url: `examination/submit/${id}`,
        method: 'PUT'
      }),
      transformResponse: (response) => response.data
    })
  })
})

export const {
  useGetQuestionsByExamIdQuery,
  useLazyGetQuestionsByExamIdQuery,
  useAddExamQuestionsMutation,
  useEditExamQuestionsMutation,
  useStartExamQuery,
  useLazyStartExamQuery,
  useLazyGetExaminationDetailsQuery,
  useGetExaminationDetailsQuery,
  useLazyGetQuestoinQuery,
  useSubmitAnswerMutation,
  useLazyGetNavigationQuery,
  useLazyGetExaminationQuestionByUidQuery,
  useSubmitExamByExaminationUidMutation
} = examQuestionApi
