import loaderGif from '../../../assets/image/loader.gif'
import styled from './loader.module.css'

const Loader = (props) => {
  return (
    <div className={styled.loader}>
      <img className={styled.loader__image} src={loaderGif} alt="nodata" />
    </div>
  )
}

export default Loader
