import { Box, Step, StepLabel, Stepper } from '@mui/material'
import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import AlertMessage from '../../components/feature/AlertMessage/AlertMessage'
import ExamDetailsPage from './ExamDetailsPage'
import ExamPreviewPage from './ExamPreviewPage'
import ExamSelectQuestion from './ExamSelectQuestion'
import Style from './exams.module.css'

const steps = ['Enter Exam Details', 'Select Questions', 'Exam Preview']

const ExamAddEdit = () => {
  const { examId: examIdParam } = useParams()

  const [examId, setExamId] = useState(examIdParam ?? '')
  const [activeStep, setActiveStep] = useState(0)
  const [domain, setDomain] = useState('')
  const [alertMessage, setAlertMessage] = useState()
  const [alertSeverity, setAlertSeverity] = useState()

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#006AFE'
      }
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#006AFE'
      }
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 2,
      borderRadius: 1,
      top: -1,
      position: 'relative'
    },
    '&.MuiStepConnector-root': {
      left: 'calc(-50% - 45px)',
      right: 'auto',
      width: '194px'
    }
  }))

  const customStep = (props) => {
    const { active, completed, icon } = props
    return (
      <>
        <Typography
          className={clsx(
            'w-6 aspect-square rounded-full text-white text-center text-sm leading-6 relative z-10',
            active || completed ? 'bg-primary-500' : 'bg-gray-300'
          )}
          component="span"
        >
          {icon}
        </Typography>
      </>
    )
  }

  const renderStep = (param) => {
    switch (param) {
      case 0:
        return (
          <ExamDetailsPage
            setExamId={setExamId}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            selectedDomain={domain}
            setSelectedDomain={setDomain}
            setAlertMessage={setAlertMessage}
            setAlertSeverity={setAlertSeverity}
          />
        )
      case 1:
        return (
          <ExamSelectQuestion
            examId={examId}
            setExamId={setExamId}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            selectedDomain={domain}
            setAlertSeverity={setAlertSeverity}
          />
        )
      case 2:
        return (
          <ExamPreviewPage
            examId={examId}
            setExamId={setExamId}
            selectedDomain={domain}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <>
      {alertMessage && (
        <div className="flex items-center fixed">
          <AlertMessage
            severity={alertSeverity}
            message={alertMessage}
            className="absolute top-[-23.5px]  left-[508px] z-[10000]"
          />
        </div>
      )}
      <div className={Style.main_div}>
        <div className="mb-6 w-[630px]">
          <Box sx={{ width: '100%' }}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              connector={<QontoConnector />}
            >
              {steps.map((label, index) => {
                return (
                  <Step key={label} sx={{ padding: '0 !important' }}>
                    <div className="w-[100px]">
                      <StepLabel
                        componentsProps={{
                          label: {
                            className: `text-lg ${
                              index <= activeStep && 'text-primary-500'
                            }`
                          }
                        }}
                        StepIconComponent={customStep}
                      >
                        {label}
                      </StepLabel>
                    </div>
                  </Step>
                )
              })}
            </Stepper>
          </Box>
        </div>
      </div>
      <div> {renderStep(activeStep)}</div>
    </>
  )
}

export default ExamAddEdit
