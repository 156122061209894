import {
  useDeleteTopicMutation,
  useGetTopicsByFilterQuery,
  useLazyGetTopicsByFilterQuery
} from '../../app/services/topic'
import { accessorKey, header } from '../../constants/columns'
import { useGetDomainsQuery } from '../../app/services/domain'
import CustomTable from '../../components/feature/Table/Table'
import { Alert, Snackbar, IconButton, Typography } from '@mui/material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { useState, useEffect } from 'react'
import Modal from '../../components/layout/Modal/Modal'
import Loader from '../../components/layout/Loader/Loader'
import Style from './table.module.css'
import PageNumber from '../../components/feature/PageNumber/PageNum'
import { pagination } from '../../constants/constants'

function TopicListing() {
  const { data: domains } = useGetDomainsQuery()
  const [deleteTopic] = useDeleteTopicMutation()
  const [open, setOpen] = useState(false)
  const [id, setId] = useState()
  const [getTopicsByFilter] = useLazyGetTopicsByFilterQuery()
  const { data: topicsResponse, isFetching } = useGetTopicsByFilterQuery()
  const [topicList, setTopicList] = useState()
  const [totalPages, setTotalPages] = useState(0)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')

  let defaultPageSize = pagination.DEFAULT_PAGE_SIZE

  useEffect(() => {
    if (topicsResponse !== undefined) {
      createAndSetTopics(topicsResponse.data)
      setTotalPages(topicsResponse.totalPages)
    }
  }, [topicsResponse])

  const createAndSetTopics = (topicList) => {
    const data = topicList?.map((topic, index) => {
      return {
        no: index + 1,
        department:
          domains !== undefined &&
          domains.find((domain) => domain.uid === topic.domainUid).name,
        name: topic.name,
        uid: topic.uid
      }
    })
    window.scrollTo({ top: 0 })
    setTopicList(data)
  }

  const columns = [
    {
      value: accessorKey.NO,
      label: header.NO,
      width: '5%'
    },
    {
      value: accessorKey.NAME,
      label: header.TOPIC
    },

    {
      label: header.ACTION,
      value: accessorKey.ACTION,
      align: 'center',
      width: '5%',
      render: ({ row }) => (
        <>
          <IconButton
            aria-label="delete"
            onClick={() => {
              setId(row.uid)
              setOpen(true)
            }}
          >
            <DeleteOutlineOutlinedIcon color="error" />
          </IconButton>
        </>
      )
    }
  ]

  const handlePageChange = (body) => {
    let payload = { ...body, size: defaultPageSize }

    getTopicsByFilter(payload).then(({ data: topicListByFilter }) => {
      if (topicListByFilter === undefined) setTopicList([])
      else {
        setTotalPages(topicListByFilter.totalPages)
        createAndSetTopics(topicListByFilter.data)
      }
    })
  }

  const handleDelete = () => {
    deleteTopic({ id: id }).then(({ data, error }) => {
      if (data) setOpen(false)
      else if (error) {
        setSnackbarMessage(
          'Topic cannot be deleted as It has associated Questions.'
        )
        setOpenSnackbar(true)
      }
    })
  }

  return (
    <>
      {isFetching ? (
        <Loader />
      ) : (
        <CustomTable columns={columns} data={topicList} />
      )}
      <Modal
        open={open}
        title="Delete Topic"
        isDeleteConfirmationPopup={true}
        setOpen={setOpen}
        onSubmit={handleDelete}
      >
        <Typography className={Style.delete__text}>
          Are you sure you want to delete Topic?
        </Typography>
      </Modal>
      <PageNumber count={totalPages} onChange={handlePageChange} />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert severity="error">{snackbarMessage}</Alert>
      </Snackbar>
    </>
  )
}

export default TopicListing
