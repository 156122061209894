import Style from './fileDownload.module.css'
import FileDownloadLogo from '../../../assets/image/download-logo.svg'
import { useLazyGetQuestionTemplateQuery } from '../../../app/services/file'

function FileDownload() {
  const [getQuestionTemplateQuery] = useLazyGetQuestionTemplateQuery()

  const handleDownloadClick = () => {
    getQuestionTemplateQuery()
      .then((response) => {
        const excelData = response.data
        const binaryString = atob(excelData)

        const uint8Array = new Uint8Array(binaryString.length)
        for (let i = 0; i < binaryString.length; i++) {
          uint8Array[i] = binaryString.charCodeAt(i)
        }

        const blob = new Blob([uint8Array], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)

        link.download = `import_question_template.xlsx`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch((error) => {
        console.log('Question Template Error ::: ', error)
      })
  }

  return (
    <div>
      <div className={Style.fileDownloadbox}>
        <div className={Style.fileDownloadtext}>
          <p>
            Download the blank template file (Excel Sheet) with pre- configured
            columns and update it with the valid data without altering the
            structure of the file.
          </p>
          <h6>Supported file types are Excel and CSV</h6>
        </div>
        <div className="">
          <button
            className={Style.fileDownloadvectorblue}
            onClick={handleDownloadClick}
          >
            Download
            <img src={FileDownloadLogo} alt="fileUpload" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default FileDownload
