import noDataFoundImage from '../../../assets/image/nodata.svg'
import styled from './nodata.module.css'

const NodataFound = (props) => {
  return (
    <div className={styled.nodata}>
      <div className={styled.nodata__wrapper}>
        <img
          className={styled.nodata__image}
          src={noDataFoundImage}
          alt="nodata"
        />
        <h5>No data found</h5>
        <p>We Couldn’t find what you searched for. Try searching again.</p>
      </div>
    </div>
  )
}

export default NodataFound
