import MenuItem from '@mui/material/MenuItem'
import SelectMaterial from '@mui/material/Select'
import { Typography } from '@mui/material'
import { useState } from 'react'

const Select = (props) => {
  const {
    multiple = false,
    options = [],
    label,
    onChange,
    className,
    disabled = false,
    placeholder = 'Select',
    value,
    isError = false
  } = props

  const [selectedName, setSelectedName] = useState(value)

  const handleChange = (event) => {
    onChange(event.target.value)
    setSelectedName(
      options.find((option) => option.value === event.target.value.value)
    )
  }
  return (
    <Typography
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        textAlign: 'left',
        gap: '4px'
      }}
    >
      {label && (
        <Typography className="text-grey-400" component="label">
          {label}
        </Typography>
      )}
      <SelectMaterial
        MenuProps={{
          MenuListProps: {
            disablePadding: true
          }
        }}
        value={selectedName ? selectedName.label : ''}
        onChange={handleChange}
        size="small"
        multiple={multiple}
        disabled={disabled}
        className={className}
        displayEmpty
        fullWidth
        renderValue={(selected) => {
          if (multiple && selected.length) {
            return <>{selected.join(', ')}</>
          } else if (selected) {
            return <>{selected}</>
          } else {
            return <>{placeholder}</>
          }
        }}
        error={isError}
      >
        {options
          ? options.map((opt, ind) => {
              return (
                <MenuItem
                  sx={{
                    maxWidth: '200px',
                    whiteSpace: 'normal',
                    fontSize: 14
                  }}
                  key={opt.value}
                  value={opt}
                >
                  {opt.label}
                </MenuItem>
              )
            })
          : ''}
      </SelectMaterial>
    </Typography>
  )
}

export default Select
