import { useState } from 'react'
import { Alert, Snackbar, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useLoginMutation } from '../../app/services/auth'
import Style from './adminlogin.module.css'
import logo from '../../assets/image/sidebar-logo.svg'
import hand from '../../assets/icon/hand.svg'
import DynamicButton from '../../components/core/DynamicButton/DynamicButton'
import TextField from '../../components/core/TextField/TextField'
import { useNavigate } from 'react-router-dom'
import { notificationActions } from '../../app/store/notification'
import { useDispatch } from 'react-redux'
import brandinglogo from '../../assets/icon/branding-logo-1.svg'

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 2000
}

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [login] = useLoginMutation()

  const [errorMessage, setErroMessage] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      username: '',
      password: ''
    }
  })

  const onSubmit = async (data) => {
    try {
      const res = await login(data)
      if (res?.data?.resultCode === 1001 && res?.data.data?.accessToken) {
        localStorage.setItem('accessToken', res?.data?.data?.accessToken)
        navigate('/')
      } else {
        dispatch(
          notificationActions.openSnackbar({
            type: 'error',
            message: 'You have entered an invalid username or password'
          })
        )
      }
    } catch (error) {
      setErroMessage(error)
      setOpenSnackbar(true)
    }
  }

  return (
    <>
      <div className={Style.adminlogin}>
        <div className={Style.adminlogin__wrapper}>
          <div className={Style.adminlogin__left}>
            <div className={Style.adminlogin__logo}>
              <img src={logo} alt="logo" />
            </div>
            <div className={Style.adminlogin__form}>
              <Typography
                className={Style.adminlogin__title}
                id="modal-modal-title"
                variant="h3"
              >
                Welcome! <img src={hand} alt="hand" />
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={Style.adminlogin__input}>
                  <TextField
                    label="Username"
                    placeholder="Enter your user name"
                    validate={register('username', {
                      required: 'User name is required'
                    })}
                    isError={errors.username ? true : false}
                  />
                  {errors.username && (
                    <p className="text-[#b91c1c]">{errors.username?.message}</p>
                  )}
                  <TextField
                    type="password"
                    label="Password"
                    placeholder="**********"
                    validate={register('password', {
                      required: 'Password is required'
                    })}
                    isError={errors.password ? true : false}
                  />
                  {errors.password && (
                    <p className="text-[#b91c1c]">{errors.password?.message}</p>
                  )}
                </div>
                <div className={Style.examlogin__action}>
                  <DynamicButton
                    className={Style.examlogin__btn}
                    width="500px"
                    type="submit"
                  >
                    Login
                  </DynamicButton>
                </div>
              </form>
            </div>
            <div className={Style.poweredby}>
              <p className={Style.poweredby__text}>Powered by</p>
              <div className={Style.poweredby__logo}>
                <img src={brandinglogo} alt="brandinglogo" />
              </div>
            </div>
          </div>
          <div className={Style.adminlogin__right}>
            <div className={Style.adminlogin__image}>
              <div className={Style.adminlogin__slider}>
                <Slider {...settings} className={Style.adminlogin_slidertest}>
                  <div>
                    <h3 className={Style.slider__text}>
                      You can upload your excel file <br /> as well as csv file.
                    </h3>
                  </div>
                  <div>
                    <h3 className={Style.slider__text}>
                      You can upload your excel file <br /> as well as csv file.
                    </h3>
                  </div>
                  <div>
                    <h3 className={Style.slider__text}>
                      You can upload your excel file <br /> as well as csv file.
                    </h3>
                  </div>
                  <div>
                    <h3 className={Style.slider__text}>
                      You can upload your excel file <br /> as well as csv file.
                    </h3>
                  </div>
                  <div>
                    <h3 className={Style.slider__text}>
                      You can upload your excel file <br /> as well as csv file.
                    </h3>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </>
  )
}

export default Login
